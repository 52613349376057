import { smashersAPI } from '@utils/APIInterceptor';

export async function createGiftCardApi(giftCardData: any) {
  const accessToken = localStorage.getItem('accessToken');

  const data: any = await smashersAPI(accessToken).post(
    'api/v1/admin/gift_cards',
    {
      ...giftCardData,
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function fetchGiftCardsApi(payload: any) {
  const accessToken = localStorage.getItem('accessToken');
  const data: any = await smashersAPI(accessToken).get(
    `api/v1/admin/gift_cards?page=${payload.page}&per_page=${payload.per_page}`
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function fetchGiftCardApi(id: any) {
  const accessToken = localStorage.getItem('accessToken');
  const data: any = await smashersAPI(accessToken).get(
    `api/v1/admin/gift_cards/${id}`
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function deleteGiftCardApi(id: any) {
  const accessToken = localStorage.getItem('accessToken');
  const data: any = await smashersAPI(accessToken).delete(
    `api/v1/admin/gift_cards/${id}`
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function updateGiftCardApi(payload: any) {
  const accessToken = localStorage.getItem('accessToken');

  const data: any = await smashersAPI(accessToken).put(
    `api/v1/admin/gift_cards/${payload.id}`,
    {
      ...payload.giftCardData,
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}
