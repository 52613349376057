// A "slice" is a collection of Redux reducer logic and actions for a single feature in your app,
// typically defined together in a single file. The name comes from splitting up the root Redux
// state object into multiple "slices" of state.

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notification } from "antd";
import { toast } from "react-toastify";
import { assignSmashCashApi, fetchPlayersApi, fetchPlayersCSVApi, fetchUserRegisteredEventsApi } from "./API/playersApi";

// You need to create a slice first, under the features folder
// that slice should have a createSlice() function and the intial state provided to it
// The slice name is also required, hence give the name: param a proper string

const initialState = {
  status: "idle",
  data: {},
  userRegisteredEvents: [],
  csv: []
};

//fetching players slice
export const fetchPlayers = createAsyncThunk(
  "players/get",
  async (data: any, thunkAPI) => {
    const response = await fetchPlayersApi(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user he has successfully fetched players
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully fetched the players
      toast.success("Players fetched successfully", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (response.data.status === 401) {
      notification.error({
        message: `${response.data.statusText} Please Login Again`,
      });
    }
    return response.data;
  }
);


export const fetchUserRegisteredEvents = createAsyncThunk(
  'registeredEvents/get',
  async (id: any, thunkAPI) => {
    const response = await fetchUserRegisteredEventsApi(id);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      notification.error({
        message: response.data.data.error,
      });
    }
    return response.data;
  }
);

export const assignSmashCash = createAsyncThunk(
  'smashCash/assign',
  async (data: any, thunkAPI) => {
    const response = await assignSmashCashApi(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the error
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully assigned the smash cash
      toast.success('Smash has been successfully assigned', {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

export const fetchPlayersCSV = createAsyncThunk(
  "playersCSV/get",
  async () => {
    const response = await fetchPlayersCSVApi();
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user he has successfully fetched players
      notification.error({
        message: response.data.data.error,
      });
    }
    return response.data;
  }
);







const playersSlice = createSlice({
  name: "players",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlayers.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUserRegisteredEvents.pending, (state) => {
        state.status = "loading";
      })
      .addCase(assignSmashCash.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchPlayersCSV.pending, (state) => {
        state.status = "loading";
      })


      .addCase(fetchPlayers.fulfilled, (state, action) => {
        state.status = "idle";
        state.data = action.payload.data;
      })
      .addCase(fetchUserRegisteredEvents.fulfilled, (state, action) => {
        state.status = "idle";
        state.userRegisteredEvents = action.payload.data;
      })
      .addCase(assignSmashCash.fulfilled, (state, action) => {
        state.status = "idle";
        state.data = action.payload.data;
      })
      .addCase(fetchPlayersCSV.fulfilled, (state, action) => {
        state.status = "idle";
        state.csv = action.payload.data;
      })


      .addCase(fetchPlayers.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(fetchUserRegisteredEvents.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(assignSmashCash.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(fetchPlayersCSV.rejected, (state) => {
        state.status = "failed";
      });

  },
});

// Here we are exporting the actions that the component/button will need
// eslint-disable-next-line no-empty-pattern
export const { } = playersSlice.actions;

// please look carefully at the below line, it returns a reducer object that's why it gets added in the store.
export default playersSlice.reducer;
