import React from "react";
import type { MenuProps } from 'antd';
import { Menu, Dropdown, Button, Avatar } from "antd";
import "./styles.css";
import { ReactComponent as DropDownOutlined } from "@assets/icons/dropdown-icon.svg";
import { Link, useNavigate } from "react-router-dom";
import { UserOutlined } from "@ant-design/icons";




const HeaderDropdown: React.FC = () => {

  const navigate = useNavigate()

  const alphaClick: MenuProps['onClick'] = ({ key }) => {
    if (key === '2') {
      localStorage.setItem('accessToken', '')
      navigate('/admin/login', { replace: true })
    }
    return null
  };

  const menu = (
    <Menu
      style={{ marginTop: 10 }}
      onClick={alphaClick}
      items={[
        // {
        //   key: "1",
        //   label: <Link to="/admin/forgot-password">Change Password</Link>,
        // },
        {
          key: "2",
          label: <Link to="/admin/login">Logout</Link>,
        },
      ]}
    />
  );
  return (<div className="profile-notification-wrapper">
    {/* <div>
      <NotificationOutlined className="bell-icon" />
    </div> */}
    <Dropdown
      overlay={menu}
      placement="bottomRight"
      className="header-dropdown"
    >
      <Button className="header-dropdown-btn">
        <div className="dropdown-btn-wrapper">
          <div className="dropdown-btn-icon">
            <Avatar className="profile-icon" icon={<UserOutlined />} />
            {/* <span className="profile-letters"> AH</span>
            </Avatar> */}
          </div>
          <div className="dropdown-btn-text">
            {/* <p>Administrator</p>
          <p>agentyup@formik.co</p> */}
            <DropDownOutlined style={{ width: 16 }} />
          </div>
        </div>
      </Button>
    </Dropdown>
  </div>)
};

export default HeaderDropdown;
