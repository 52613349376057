import { smashersAPI } from '@utils/APIInterceptor';

export async function createPostApi(postsData: any) {
  const accessToken = localStorage.getItem('accessToken');
  const data: any = await smashersAPI(accessToken).post(
    'api/v1/admin/posts',
    {
      ...postsData,
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function fetchPostApi(payload: any) {
  const accessToken = localStorage.getItem('accessToken');
  const searchTerm = payload.term || '';
  const data: any = await smashersAPI(accessToken).get(
    `api/v1/admin/posts?page=${payload.page}&per_page=${payload.per_page}&term=${searchTerm}`
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function deletePostApi(id: any) {
  const accessToken = localStorage.getItem('accessToken');
  const data: any = await smashersAPI(accessToken).delete(
    `api/v1/admin/posts/${id}`
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function fetchSinglePostApi(id: any) {
  const accessToken = localStorage.getItem('accessToken');
  const data: any = await smashersAPI(accessToken).get(
    `api/v1/admin/posts/${id}`
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function editPostApi(payload: any) {
  const accessToken = localStorage.getItem('accessToken');
  const data: any = await smashersAPI(accessToken).put(
    `api/v1/admin/posts/${payload.id}`,
    { ...payload.postsData },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function fetchPostsCSVApi() {
  const accessToken = localStorage.getItem('accessToken');
  const data: any = await smashersAPI(accessToken).get(
    'api/v1/admin/posts.csv'
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}
