import { FormikTextField } from '@components/inputFeilds/InputFeilds';
import { MainLayout } from '@components/layouts';
import { Button, Col, Divider, Row, Spin, Typography } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import { useSelector, useDispatch, } from "react-redux";
import { fetchSupportCategory } from '@slices/supportCategorySlice';
import { Link, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Slide } from 'react-toastify';
import { AppDispatch } from '@config/storeConfig';




const { Text } = Typography;

const ViewSupportCategory = () => {
  const params = useParams();
  const liveSupportCategoryState = useSelector((state: any) => state.supportCategory.data.data);
  const reduxStatus = useSelector((state: any) => state.supportCategory.status);
  const dispatch = useDispatch<AppDispatch>();


  const formikMain = useFormik({
    initialValues: {
      name: liveSupportCategoryState?.name ? liveSupportCategoryState.name : '',
      recipients: liveSupportCategoryState?.recipients ? liveSupportCategoryState.recipients : [],
    },
    enableReinitialize: true,
    onSubmit: () => {

    },

  });

  const {

    getFieldProps,
    handleSubmit,

  } = formikMain;


  useEffect(() => {
    dispatch(fetchSupportCategory({ id: params.id }));
    toast.info(
      <>
        <p>View Support Category here!</p>
      </>,
      {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
        transition: Slide,
        style: { minWidth: 400 },
      }
    );
  }, []);



  return (
    <MainLayout>
      <div className='community-container'>
        <Row>
          <Col xl={20} sm={24} >
            <Divider orientation="left" orientationMargin="0" >
              <Text className="community-heading">Edit Support Category</Text>
            </Divider>
          </Col>
        </Row>
        {reduxStatus !== 'loading' ? (
          <FormikProvider value={formikMain}>
            <form onSubmit={handleSubmit} className='create-community-form'>
              <Row>
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <FormikTextField
                    label="TYPE/CATEGORY NAME"
                    className="community-input"

                    autoComplete="off"
                    disabled
                    placeholder="Enter category name"
                    {...getFieldProps('name')}
                  />
                </Col>
                <Col span={8}></Col>
                <Col span={8}></Col>
              </Row>

              <Row>
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <FormikTextField
                    label="EMAILS RECEPIENTS OF THIS CATEGORY"
                    className="community-input"
                    disabled
                    autoComplete="off"

                    placeholder="Enter recepients"
                    {...getFieldProps('recipients')}
                  />
                </Col>
                <Col span={8}></Col>
                <Col span={8}></Col>
              </Row>
              <Row>
                <Col>
                  <Link to={`/admin/edit/support-category/${params.id}`}>
                    <Button
                      type="primary"
                      htmlType="submit"
                      className="primary-btn"
                    >
                      Edit Support category
                    </Button>
                  </Link>
                </Col>
                <Link to="/admin/support-category">
                  <Button
                    type="link"
                    size={'middle'}
                    style={{ color: 'gray', marginTop: 12 }}
                  >
                    Cancel
                  </Button>
                </Link>
              </Row>
            </form>
          </FormikProvider>
        ) : (
          <Row>
            <Col
              span={24}
              style={{
                paddingTop: 100,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Spin />
            </Col>
          </Row>)}
      </div>
    </MainLayout>
  );
};

export default ViewSupportCategory;
