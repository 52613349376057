import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { ReactComponent as DropDownOutlined } from '@assets/icons/dropdown-icon.svg';
import { Space, Image, Spin, Checkbox, Tooltip, Alert, notification } from 'antd';
import MainLayout from '@components/layouts/MainLayout';
import Logo from '@assets/images/Logo.png';
import {
  Col,
  Row,
  Form,
  Button,
  Select,
  Divider,
  Typography,
} from 'antd';
import {
  Field,
  useFormik,
  FieldArray,
  ErrorMessage,
  FormikProvider,
} from 'formik';
import {
  FormikDatePicker,
  FormikRangePicker,
  FormikRadioInput,
  FormikSelect,
  FormikTextField,
  FormikRadioInputThreeFields,
} from '@components/inputFeilds/InputFeilds';

import React, { forwardRef, useEffect, useState } from 'react';
import * as yup from 'yup';

import '../styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEvent, updateEvent } from '@slices/eventsSlice';
import { AppDispatch } from '@config/storeConfig';
import { Slide, toast } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import DraftRTE from '@components/rte/DraftRTE';
import { fetchFlightDistributions, fetchFlightNames } from '@slices/flightsSlice';

import {
  fetchCourseNames,
  fetchTopTracerDetails,
} from '@slices/courseNamesSlice';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import ImageUpload from '@components/imageUpload/ImageUpload';
import { fetchLeaguesDetails } from '@slices/leaguesSlice';
import CounterInputField from '@components/inputFeilds/CounterInputField';
import { RangePickerProps } from 'antd/lib/date-picker';
import moment from 'moment';


const { Item } = Form;
const { Option } = Select;
const { Text } = Typography;


const EditEvent = () => {
  const params = useParams();
  const currentEventId = params?.id;
  const [previewLogo, setPreviewLogo] = useState(true);
  const liveEventState = useSelector((state: any) => state.events.data.data);
  const reduxStatus = useSelector((state: any) => state.events.status);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const [posterBase, setPosterBase] = useState<any>('');

  const flightDistributionsFromRedux = useSelector(
    (state: any) => state.flights.flightDistributions.data
  );
  const flightNamesFromRedux = useSelector(
    (state: any) => state.flights.flightNames.data
  );

  const topTracerDetailsFromRedux = useSelector(
    (state: any) => state.courseNames.data.data
  );
  const courseNamesFromRedux = useSelector(
    (state: any) => state.courseNames.courseNames.data
  );

  const leaguesDetailsFromRedux = useSelector(
    (state: any) => state.leagues.leaguesDetails.data
  );

  const supportedHolesArr = liveEventState?.course?.supported_holes?.map((holes: any) =>
    holes.number
  )
  const holeNum = liveEventState?.holes
  const [selectAllFrontHoles, setSelectAllFrontHoles] = useState(((holeNum === 9 || holeNum === 12 || holeNum === 15) && [1, 2, 3, 4, 5, 6, 7, 8, 9].every((item: any) => supportedHolesArr?.includes(item))) ? true : false)
  const [selectAllBackHoles, setSelectAllBackHoles] = useState(((holeNum === 9 || holeNum === 12 || holeNum === 15) && [10, 11, 12, 13, 14, 15, 16, 17, 18].every((item: any) => supportedHolesArr?.includes(item))) ? true : false)

  const FlightNamesOptions =
    flightNamesFromRedux?.map((flight: any) => ({
      name: flight.name,
      id: flight.id,
      min_hcp: flight.min_hcp === -12345 ? '-' : flight.min_hcp,
      max_hcp: flight.max_hcp === 12345 ? '-' : flight.max_hcp,
      percentage: flight.percentage
    }));

  const courseNamesOptions =
    courseNamesFromRedux &&
    courseNamesFromRedux.map((course: any) => ({
      name: course.name,
      id: course.id,
    }));

  const eventTypeOptions =
    topTracerDetailsFromRedux &&
    topTracerDetailsFromRedux.event_types?.map((x: any) =>
      x === 'scramble'
        ? 'Scramble'
        : x === 'strokeplay'
          ? 'Strokeplay'
          : x === 'longest_drive'
            ? 'Longest Drive'
            : x === 'closest_to_the_pin'
              ? 'Closest To The Pin'
              : null
    );
  const holeOptions =
    topTracerDetailsFromRedux &&
    topTracerDetailsFromRedux.number_of_holes?.map((x: any) =>
      x.number === 3
        ? '3-Holes'
        : x.number === 6
          ? '6-Holes'
          : x.number === 9
            ? '9-Holes'
            : x.number === 12
              ? '12-Holes'
              : x.number === 15
                ? '15-Holes'
                : x.number === 18
                  ? '18-Holes'
                  : null
    );
  const teeBoxOptions =
    topTracerDetailsFromRedux &&
    topTracerDetailsFromRedux.tee_boxes?.map((x: any) => x.box_name);

  const leagueNamesOptions = leaguesDetailsFromRedux?.map((leagues: any) => ({
    name: leagues.name,
    id: leagues.id,
  }))

  const flightDistributionOptions = flightDistributionsFromRedux?.map((flightDistribution: any) => ({
    name: flightDistribution.name,
    id: flightDistribution.id,
  }))


  const holesDataArr = liveEventState?.course?.supported_holes;

  // Writing formik logic right here as it is very specific
  const formikMain: any = useFormik({
    initialValues: {
      name: liveEventState && liveEventState.name ? liveEventState.name : '',
      event_level: liveEventState && liveEventState.league_name === null ? 'non_league' : 'league',
      is_private:
        liveEventState && liveEventState.is_private
          ? liveEventState.is_private
            ? 'Yes'
            : 'No'
          : 'No',
      password: liveEventState && liveEventState.password ? liveEventState.password : '',
      league_id: liveEventState && liveEventState.league_id ? liveEventState.league_id : '',
      flight_distribution_id: liveEventState && liveEventState.flight_distribution_id ? liveEventState.flight_distribution_id : '',
      eventDates:
        liveEventState && liveEventState.start_time && liveEventState.end_time
          ? [liveEventState.start_time, liveEventState.end_time]
          : '',
      scores_upload_cutoff_time:
        liveEventState && liveEventState.scores_upload_cutoff_time
          ? liveEventState.scores_upload_cutoff_time
          : '',
      registration_deadline:
        liveEventState && liveEventState.registration_deadline
          ? liveEventState.registration_deadline
          : '',
      scoring:
        liveEventState && liveEventState.scoring
          ? liveEventState.scoring
          : undefined,
      event_poster: '',
      rules: liveEventState && liveEventState.rules ? liveEventState.rules : '',
      par_value: liveEventState && liveEventState.par ? liveEventState.par : '',
      flights:
        liveEventState && liveEventState.flights
          ? liveEventState.flights
            ? 'Yes'
            : 'No'
          : 'No',
      smash_points:
        liveEventState && liveEventState.smash_points
          ? liveEventState.smash_points
          : '',
      world_points:
        liveEventState && liveEventState.world_points
          ? liveEventState.world_points
          : '',
      total_league_points: liveEventState && liveEventState.total_league_points
        ? liveEventState.total_league_points
        : '',
      rewards:
        liveEventState && liveEventState.rewards
          ? liveEventState.rewards
          : [
            {
              flight_id: undefined,
              position: 1,
              smash_points: '',
              world_points: '',
              gross_smash_cash: '',
              net_smash_cash: '',
              gross_league_points: '',
              net_league_points: '',
              cttp_league_points: '',
              ld_league_points: '',
              gross_world_points: '',
              net_world_points: ''
            },
          ],
      event_entry:
        liveEventState && liveEventState.event_entry
          ? liveEventState.event_entry
          : '',
      event_fees:
        liveEventState && liveEventState.event_fees
          ? liveEventState.event_fees
          : '',
      multiple_entries:
        liveEventState && liveEventState.multiple_entries
          ? liveEventState.multiple_entries
            ? 'Yes'
            : 'No'
          : 'No',
      has_promocodes:
        liveEventState && liveEventState.has_promocodes
          ? liveEventState.has_promocodes
            ? 'Yes'
            : 'No'
          : 'No',
      promocodes:
        liveEventState && liveEventState.promocodes
          ? liveEventState.promocodes
          : [],
      course: {
        course_id: liveEventState?.course?.id
          ? liveEventState.course.id
          : '',
        event_type: liveEventState?.event_type ? liveEventState.event_type : '',
        tee_box: liveEventState?.course?.tee_box
          ? liveEventState.course.tee_box
          : '',
        holes:
          liveEventState?.holes === 3
            ? '3-Holes'
            : liveEventState?.holes === 6
              ? '6-Holes'
              : liveEventState?.holes === 9
                ? '9-Holes'
                : liveEventState?.holes === 12
                  ? '12-Holes'
                  : liveEventState?.holes === 15
                    ? '15-Holes'
                    : liveEventState?.holes === 18
                      ? '18-Holes'
                      : '',
        event_holes: [
          {
            number: 1,
            par: holesDataArr?.filter((holes: any) => holes.number === 1).length
              ? holesDataArr
                ?.filter((holes: any) => holes.number === 1)
                .map((x: any) => x.par)[0]
              : null,
          },
          {
            number: 2,
            par: holesDataArr?.filter((holes: any) => holes.number === 2).length
              ? holesDataArr
                ?.filter((holes: any) => holes.number === 2)
                .map((x: any) => x.par)[0]
              : null,
          },
          {
            number: 3,
            par: holesDataArr?.filter((holes: any) => holes.number === 3).length
              ? holesDataArr
                ?.filter((holes: any) => holes.number === 3)
                .map((x: any) => x.par)[0]
              : null,
          },
          {
            number: 4,
            par: holesDataArr?.filter((holes: any) => holes.number === 4).length
              ? holesDataArr
                ?.filter((holes: any) => holes.number === 4)
                .map((x: any) => x.par)[0]
              : null,
          },
          {
            number: 5,
            par: holesDataArr?.filter((holes: any) => holes.number === 5).length
              ? holesDataArr
                ?.filter((holes: any) => holes.number === 5)
                .map((x: any) => x.par)[0]
              : null,
          },
          {
            number: 6,
            par: holesDataArr?.filter((holes: any) => holes.number === 6).length
              ? holesDataArr
                ?.filter((holes: any) => holes.number === 6)
                .map((x: any) => x.par)[0]
              : null,
          },
          {
            number: 7,
            par: holesDataArr?.filter((holes: any) => holes.number === 7).length
              ? holesDataArr
                ?.filter((holes: any) => holes.number === 7)
                .map((x: any) => x.par)[0]
              : null,
          },
          {
            number: 8,
            par: holesDataArr?.filter((holes: any) => holes.number === 8).length
              ? holesDataArr
                ?.filter((holes: any) => holes.number === 8)
                .map((x: any) => x.par)[0]
              : null,
          },
          {
            number: 9,
            par: holesDataArr?.filter((holes: any) => holes.number === 9).length
              ? holesDataArr
                ?.filter((holes: any) => holes.number === 9)
                .map((x: any) => x.par)[0]
              : null,
          },
          {
            number: 10,
            par: holesDataArr?.filter((holes: any) => holes.number === 10)
              .length
              ? holesDataArr
                ?.filter((holes: any) => holes.number === 10)
                .map((x: any) => x.par)[0]
              : null,
          },
          {
            number: 11,
            par: holesDataArr?.filter((holes: any) => holes.number === 11)
              .length
              ? holesDataArr
                ?.filter((holes: any) => holes.number === 11)
                .map((x: any) => x.par)[0]
              : null,
          },
          {
            number: 12,
            par: holesDataArr?.filter((holes: any) => holes.number === 12)
              .length
              ? holesDataArr
                ?.filter((holes: any) => holes.number === 12)
                .map((x: any) => x.par)[0]
              : null,
          },
          {
            number: 13,
            par: holesDataArr?.filter((holes: any) => holes.number === 13)
              .length
              ? holesDataArr
                ?.filter((holes: any) => holes.number === 13)
                .map((x: any) => x.par)[0]
              : null,
          },
          {
            number: 14,
            par: holesDataArr?.filter((holes: any) => holes.number === 14)
              .length
              ? holesDataArr
                ?.filter((holes: any) => holes.number === 14)
                .map((x: any) => x.par)[0]
              : null,
          },
          {
            number: 15,
            par: holesDataArr?.filter((holes: any) => holes.number === 15)
              .length
              ? holesDataArr
                ?.filter((holes: any) => holes.number === 15)
                .map((x: any) => x.par)[0]
              : null,
          },
          {
            number: 16,
            par: holesDataArr?.filter((holes: any) => holes.number === 16)
              .length
              ? holesDataArr
                ?.filter((holes: any) => holes.number === 16)
                .map((x: any) => x.par)[0]
              : null,
          },
          {
            number: 17,
            par: holesDataArr?.filter((holes: any) => holes.number === 17)
              .length
              ? holesDataArr
                ?.filter((holes: any) => holes.number === 17)
                .map((x: any) => x.par)[0]
              : null,
          },
          {
            number: 18,
            par: holesDataArr?.filter((holes: any) => holes.number === 18)
              .length
              ? holesDataArr
                ?.filter((holes: any) => holes.number === 18)
                .map((x: any) => x.par)[0]
              : null,
          },
        ],
      },
      team_members: liveEventState?.team_members
        ? liveEventState.team_members
        : '',
      shot_clock_enabled:
        liveEventState && liveEventState.shot_clock_enabled
          ? liveEventState.shot_clock_enabled
            ? 'Yes'
            : 'No'
          : 'No',
      grace_time_enabled:
        liveEventState && liveEventState.grace_time_enabled
          ? liveEventState.grace_time_enabled
            ? 'Yes'
            : 'No'
          : 'No',
      shot_clock_time: liveEventState?.shot_clock_time
        ? liveEventState.shot_clock_time
        : 1,
      grace_time: liveEventState?.grace_time
        ? liveEventState.grace_time
        : 1,
      post_scoring_smash_cash:liveEventState?.post_scoring_smash_cash ? liveEventState?.post_scoring_smash_cash : 5,
      uniqueHoleArr: liveEventState?.course ? holesDataArr?.map((hole: any) => hole.number - 1) : [], // Unique hole array to disable holes - No need to pass in the payload.
    },
    enableReinitialize: true,

    onSubmit: async (values) => {
      if (smashCashLimit && wolrdPointsLimit && leaguePointsLimit) {
        setSubmitting(true);
        const eventCreationData = {
          name: values.name,
          is_private: values.is_private === 'Yes' ? true : false,
          password: values.is_private === "Yes" ? values.password : '',
          league_id: values.event_level === "league" ? values.league_id : "",
          start_time: values.eventDates[0],
          end_time: values.eventDates[1],
          scores_upload_cutoff_time: values.scores_upload_cutoff_time,
          registration_deadline: values.registration_deadline,
          rewards_type: 'Manual',
          rewards: values.rewards,
          scoring: values.scoring,
          flights: values.flights === 'Yes' ? true : false,
          flight_distribution_id: values.flight_distribution_id,
          rules: values.rules,
          par: parSum,
          smash_points: values.smash_points,
          world_points: ((values.event_level === 'non_league') && (values.course.event_type === 'Strokeplay' ||
            values.course.event_type === 'Scramble')) ? values.world_points : undefined,
          total_league_points: values.event_level === 'league' ? values.total_league_points : undefined,
          event_entry: values.event_entry,
          event_fees: values.event_fees,
          multiple_entries: values.multiple_entries === 'Yes' ? true : false,
          has_promocodes: values.has_promocodes === 'Yes' ? true : false,
          promocodes: values.promocodes,
          course: {
            course_id: values.course.course_id,
            event_type: values.course.event_type,
            holes: values.course.holes,
            tee_box: values.course.tee_box,
            event_holes: values.course.event_holes.filter(
              (x: any) => x.par !== null
            ),
          },
          team_members: values.team_members,
          shot_clock_enabled: values.shot_clock_enabled === 'Yes' ? true : false,
          grace_time_enabled: values.grace_time_enabled === 'Yes' ? true : false,
          shot_clock_time: values.shot_clock_enabled === 'Yes' ? values.shot_clock_time : undefined,
          grace_time: (values.shot_clock_enabled === 'Yes' && values.grace_time_enabled === 'Yes') ? values.grace_time : undefined,
          post_scoring_smash_cash: values.post_scoring_smash_cash === "" ? 5 : values.post_scoring_smash_cash,
        };


        const posterParam = !!posterBase;
        if (posterParam) {
          const addPoster = { event_poster: posterBase };
          await handleEditEventFormSubmit({ ...eventCreationData, ...addPoster });
        } else {
          await handleEditEventFormSubmit(eventCreationData);
        }
        setSubmitting(false);
        resetForm();
        navigate('/admin/events');
      } else {
        notification.error({
          message: values.event_level === 'league' ? 'Sum of entered smash cash or league points not equal to total smash cash or league points' :
            'Sum of entered smash cash or world points not equal to total smash cash or world points',
          placement: 'top',
          duration: 5
        });
      }
    },
    validationSchema: yup.object({
      name: yup.string().required('*Required Event name'),
      event_level: yup.string().required('*Select Option'),
      is_private: yup.string().when('event_level', {
        is: 'non_league',
        then: yup.string().required('*Select Option'),
      }),
      password: yup.string().when('is_private', {
        is: 'Yes',
        then: yup.string().min(4, '*Minimum 4 characters required').required('*Required Password'),
      }),
      eventDates: yup.array().required('*Required at least event start Date'),
      scores_upload_cutoff_time: yup
        .string()
        .required('*Required Score Upload deadline'),
      registration_deadline: yup
        .string()
        .required('*Required Registration deadline'),
      scoring: yup.string().when(['course.event_type'], {
        is: (event_type: string) => /^(Strokeplay|Scramble)$/.test(event_type),
        then: yup
          .string()
          .required('*Required Scoring'),
      }),
      flights: yup.string().when(['course.event_type', 'scoring'], {
        is: (event_type: string, scoring: string) =>
          event_type === 'Strokeplay' && scoring === 'Net',
        then: yup.string().required('*Select One'),
      }),
      flight_distribution_id: yup.string().when(['course.event_type', 'flights'], {
        is: (event_type: string, flights: string) =>
          event_type === 'Strokeplay' && flights === 'Yes',
        then: yup.string().required('*Required flight setup'),
      }),
      rules: yup.string().required('*Required Rules'),

      smash_points: yup
        .number()
        .min(0, '*Must be >= 0')
        .typeError('*Should be a number')
        .required('*Smash Cash required'),
      world_points: yup.number().when(['course.event_type', 'event_level'], {
        is: (event_type: string, event_level: string) => (event_type === 'Strokeplay' || event_type === "Scramble") && event_level === "non_league",
        then: yup
          .number()
          .min(0, '*Must be >= 0')
          .typeError('*Should be a number')
          .required('*World Points required'),
      }),
      total_league_points: yup.number().when('event_level', {
        is: 'league',
        then: yup
          .number()
          .min(0, '*Must be >= 0')
          .typeError('*Should be a number')
          .required('*League Points required'),
      }),

      // rewards: yup
      //   .array()
      //   .of(
      //     yup.object().shape({
      //       cttp_league_points: yup
      //         .number()
      //         .when(['course.event_type', 'event_level'], {
      //           is: (event_type: string, event_level: string) => (event_type === 'Closest To The Pin' && event_level === 'league'),
      //           then: yup.number().min(0, '*Must be >= 0')
      //             .typeError('*Should be a number')
      //             .required('*Required')
      //             .test(
      //               'rewards-cttp-league-points',
      //               '*Max. cttp league points, Must be <= Total League Points',
      //               (value: any, ctx: any) => {
      //                 const reducer = (accumulator: any, curr: any) =>
      //                   accumulator + curr;
      //                 const accum = values.rewards
      //                   .map((x: any, idx: any) => {
      //                     if (idx !== Number(ctx.parent.position) - 1)
      //                       return Number(x.cttp_league_points);
      //                     return 0;
      //                   })
      //                   .reduce(reducer);
      //                 if (
      //                   ctx.parent.position !== '1' &&
      //                   Number(value) <= Number(values.total_league_points) - accum
      //                 ) {
      //                   return true;
      //                 }
      //                 if (
      //                   ctx.parent.position === '1' &&
      //                   Number(value) <= Number(values.total_league_points)
      //                 )
      //                   return true;
      //                 return false;
      //               }
      //             )
      //         }),
      //       ld_league_points: yup
      //         .number()
      //         .when(['course.event_type', 'event_level'], {
      //           is: (event_type: string, event_level: string) => (event_type === 'Longest Drive' && event_level === "league"),
      //           then: yup.number().min(0, '*Must be >= 0')
      //             .typeError('*Should be a number')
      //             .required('*Required')
      //             .test(
      //               'rewards-ld-league-points',
      //               '*Max. league points, Must be <= Total League Points',
      //               (value: any, ctx: any) => {
      //                 const reducer = (accumulator: any, curr: any) =>
      //                   accumulator + curr;
      //                 const accum = values.rewards
      //                   .map((x: any, idx: any) => {
      //                     if (idx !== Number(ctx.parent.position) - 1)
      //                       return Number(x.ld_league_points);
      //                     return 0;
      //                   })
      //                   .reduce(reducer);
      //                 if (
      //                   ctx.parent.position !== '1' &&
      //                   Number(value) <= Number(values.total_league_points) - accum
      //                 ) {
      //                   return true;
      //                 }
      //                 if (
      //                   ctx.parent.position === '1' &&
      //                   Number(value) <= Number(values.total_league_points)
      //                 )
      //                   return true;
      //                 return false;
      //               }
      //             )
      //         }),


      //       smash_points: yup
      //         .number()
      //         .when(['scoring'], {
      //           is: (scoring: string) => (scoring !== 'Gross_Net'),
      //           then: yup.number().min(0, '*Must be >= 0')
      //             .typeError('*Should be a number')
      //             .required('*Required')
      //             .test(
      //               'rewards-smash-points',
      //               '*Max. smash points, Must be <= Total smash Points',
      //               (value: any, ctx: any) => {
      //                 const reducer = (accumulator: any, curr: any) =>
      //                   accumulator + curr;
      //                 const accum = values.rewards
      //                   .map((x: any, idx: any) => {
      //                     if (idx !== Number(ctx.parent.position) - 1)
      //                       return Number(x.smash_points);
      //                     return 0;
      //                   })
      //                   .reduce(reducer);
      //                 if (
      //                   ctx.parent.position !== '1' &&
      //                   Number(value) <= Number(values.smash_points) - accum
      //                 ) {
      //                   return true;
      //                 }
      //                 if (
      //                   ctx.parent.position === '1' &&
      //                   Number(value) <= Number(values.smash_points)
      //                 )
      //                   return true;
      //                 return false;
      //               }
      //             )
      //         }),


      //       world_points: yup
      //         .number()
      //         .when(['course.event_type', 'event_level'], {
      //           is: (event_type: string, event_level: string) => (event_type === 'Scramble' || event_type === "Strokeplay") && event_level === "non_league",
      //           then: yup.number().min(0, '*Must be >= 0')
      //             .typeError('*Should be a number')
      //             .required('*Required')
      //             .test(
      //               'rewards-world-points',
      //               '*Max. world points, Must be <= Total World Points',
      //               (value: any, ctx: any) => {
      //                 const reducer = (accumulator: any, curr: any) =>
      //                   accumulator + curr;
      //                 const accum = values.rewards
      //                   .map((x: any, idx: any) => {
      //                     if (idx !== Number(ctx.parent.position) - 1)
      //                       return Number(x.world_points);
      //                     return 0;
      //                   })
      //                   .reduce(reducer);
      //                 if (
      //                   ctx.parent.position !== '1' &&
      //                   Number(value) <= Number(values.world_points) - accum
      //                 ) {
      //                   return true;
      //                 }
      //                 if (
      //                   ctx.parent.position === '1' &&
      //                   Number(value) <= Number(values.world_points)
      //                 )
      //                   return true;
      //                 return false;
      //               }
      //             )
      //         }),
      //     })
      //   ),

      event_entry: yup.string().required('*Select Option'),
      event_fees: yup
        .number()
        .min(0, '*Must be >= 0')
        .when('event_entry', {
          is: 'paid',
          then: yup
            .number()
            .typeError('*Should be a number')
            .required('*Required Event Fees'),
        }),
      multiple_entries: yup.string().when('event_entry', {
        is: 'paid',
        then: yup.string().required('*Select Option'),
      }),
      has_promocodes: yup.string().when('event_entry', {
        is: 'paid',
        then: yup.string().required('*Select Option'),
      }),
      promocodes: yup.array().when('event_entry', {
        is: 'paid',
        then: yup
          .array()
          .of(
            yup.object().shape({
              name: yup
                .string()
                .min(4, '*too short')
                .required('*Required Code')
                .test('promoCode', '*promo alredy exists', (val: any) => {
                  const Promoarray = values.promocodes.map((x: any) => {
                    return x.name.toLowerCase();
                  });
                  return !Promoarray.filter((x: any, idx: any) => {
                    return Promoarray.indexOf(x) !== idx;
                  }).includes(val.toLowerCase());
                }),

              value: yup
                .number()
                .min(0, '*Must be >= 0')
                .typeError('*Should be a number')
                .required('*Required Value')
                .test('promoValue', '*Must be <= Event Fees', (value) => {
                  return Number(value) <= Number(values.event_fees);
                }),
            })
          )
          .required('*Promos required'),
      }),
      course: yup.object().shape({
        course_id: yup.string().required('*Required Course'),
        event_type: yup.string().required('*Your event must require type'),
        holes: yup.string().when(['event_type'], {
          is: (event_type: string) => /^(Strokeplay|Scramble)$/.test(event_type),
          then: yup
            .string()
            .required('*No. of Holes required'),
        }),
        tee_box: yup.string().when(['event_type'], {
          is: (event_type: string) => /^(Strokeplay|Scramble)$/.test(event_type),
          then: yup
            .string()
            .required('*Required Tee Box'),
        }),

      }).nullable(),
      league_id: yup.string().when('event_level', {
        is: 'league',
        then: yup.string().required('*Required League Name'),
      }),
      team_members: yup.number().when(['course.event_type'], {
        is: (event_type: string) => /^(Scramble)$/.test(event_type),
        then: yup
          .number()
          .required('*Required Team Members'),
      }),
      post_scoring_smash_cash: yup.number().min(0, '*Must be >= 0').typeError('*Should be a number'),
    }),
  });

  const {
    errors,
    getFieldProps,
    handleSubmit,
    touched,
    values,
    isSubmitting,
    setSubmitting,
    setFieldValue,
    resetForm,
  } = formikMain;

  const smashReducer = (accumulator: any, curr: any) => accumulator + curr;

  const smashCashLimit = (Number(
    values.rewards?.map((x: any) => Number(x.smash_points))
      .reduce(smashReducer)
  ) === Number(values.smash_points) ||
    Number(
      values.rewards?.map((x: any) => Number(x.gross_smash_cash) + Number(x.net_smash_cash))
        .reduce(smashReducer)
    ) === Number(values.smash_points));

  const wolrdPointsLimit = (Number(
    values.rewards?.map((x: any) => Number(x.world_points))
      .reduce(smashReducer)
  ) === Number(values.world_points) ||
    Number(
      values.rewards?.map((x: any) => Number(x.gross_world_points) + Number(x.net_world_points))
        .reduce(smashReducer)
    ) === Number(values.world_points));

  const leaguePointsLimit = (Number(
    values.rewards?.map((x: any) => Number(x.cttp_league_points) + Number(x.ld_league_points))
      .reduce(smashReducer)
  ) === Number(values.total_league_points) ||
    Number(
      values.rewards?.map((x: any) => Number(x.gross_league_points) + Number(x.net_league_points))
        .reduce(smashReducer)
    ) === Number(values.total_league_points));

  const FormikCourseNamesSelect = forwardRef(
    (
      {
        error,
        label,
        name,
        onChange,
        onBlur,
        placeholder,
        value,
        options,
        style,
        setFieldValue,
        ...props
      }: any,
      ref
    ) => (
      <Item
        style={style}
        {...(Boolean(error) && { validateStatus: 'error', help: error })}
      >
        {!!label && <p className="text-field-label">{label}</p>}

        <Select
          className="form-select-field-custom"
          suffixIcon={<DropDownOutlined />}
          placeholder={placeholder}
          ref={ref}
          value={value}
          onChange={async (value) => {
            await setFieldValue('course.event_type', undefined);
            await setFieldValue('course.tee_box', undefined);
            await setFieldValue('course.holes', undefined);
            await setFieldValue('flight_distribution_id', undefined);
            await setFieldValue('course.course_id', value);
            for (
              let i = 0;
              i < 18;
              i++
            ) {
              await setFieldValue(
                `course.event_holes[${i}].par`,
                null
              );
              await setFieldValue(
                `uniqueHoleArr[${i}]`,
                null
              )
            }
            await dispatch(fetchTopTracerDetails(value));
          }}
          {...props}
        >
          {options &&
            options.map((course: any, ind: any) => (
              <Option key={ind} value={course.id}>
                {course.name}
              </Option>
            ))}
        </Select>
      </Item>
    )
  );

  const FormikLeaguNamesSelect = forwardRef(
    (
      {
        error,
        label,
        name,
        onChange,
        onBlur,
        placeholder,
        value,
        options,
        style,
        setFieldValue,
        ...props
      }: any,
      ref
    ) => (
      <Item
        style={style}
        {...(Boolean(error) && { validateStatus: 'error', help: error })}
      >
        {!!label && <p className="text-field-label">{label}</p>}

        <Select
          className="form-select-field-custom"
          suffixIcon={<DropDownOutlined />}
          placeholder={placeholder}
          ref={ref}
          value={value}
          onChange={async (value) => {

            await setFieldValue('league_id', value);

          }}


          {...props}
        >
          {options &&
            options.map((league: any, ind: any) => (
              <Option key={ind} value={league.id}>
                {league.name}
              </Option>
            ))}
        </Select>
      </Item>
    )
  );

  const FormikFlightsSelect = forwardRef(
    (
      {
        error,
        label,
        name,
        onChange,
        onBlur,
        placeholder,
        value,
        options,
        style,
        setFieldValue,
        ...props
      }: any,
      ref
    ) => (
      <Item
        style={style}
        {...(Boolean(error) && { validateStatus: 'error', help: error })}
      >
        {!!label && <p className="text-field-label">{label}</p>}

        <Select
          className="form-select-field-custom"
          suffixIcon={<DropDownOutlined />}
          placeholder={placeholder}
          ref={ref}
          value={value}
          onChange={async (value) => {
            await setFieldValue('flight_distribution_id', value);
            await dispatch(fetchFlightNames(value));
            await setFieldValue('rewards', undefined);
          }}
          {...props}
        >
          {options &&
            options.map((flightDistribution: any, ind: any) => (
              <Option key={ind} value={flightDistribution.id}>
                {flightDistribution.name}
              </Option>
            ))}
        </Select>
      </Item>
    )
  );

  const selectedCourseId = useSelector(
    (state: any) => state.events?.data?.data?.course?.id
  );

  const selectedFlightDistributionId = useSelector(
    (state: any) => state.events?.data?.data?.flight_distribution_id
  );
  //Scrolls to a specific field in a formik form after the submit button 
  //is clicked if the required filed was not filled.
  useEffect(() => {
    if (!formikMain.isSubmitting) return;
    if (Object.keys(formikMain.errors).length > 0) {
      document.getElementsByName(Object.keys(formikMain.errors)[0])[0]?.focus();
    }
  }, [formikMain]);

  useEffect(() => {
    //Fetch the event right here and populate the values
    // use current id currentEventId
    window.scrollTo({ top: 0, behavior: 'smooth' });
    dispatch(fetchEvent({ id: currentEventId }));
  }, [currentEventId, dispatch]);

  useEffect(() => {
    dispatch(fetchFlightDistributions());
    dispatch(fetchCourseNames());
    dispatch(fetchLeaguesDetails());
    toast.info(
      <>
        <p>Edit your event right here</p>
      </>,
      {
        position: 'bottom-right',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
        transition: Slide,
        style: { minWidth: 200 },
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(fetchTopTracerDetails(selectedCourseId));
  }, [dispatch, selectedCourseId]);

  useEffect(() => {
    dispatch(fetchFlightNames(selectedFlightDistributionId));
  }, [dispatch, selectedFlightDistributionId]);

  const handleEditEventFormSubmit = async (argo: any) => {
    await dispatch(updateEvent({ id: params.id, eventData: argo }));
  };




  const handleRTETextChange = (editorValue: any) => {
    setFieldValue('rules', editorValue);
  };

  // const disabledDate: RangePickerProps['disabledDate'] = (current: any) => {
  //   // Can not select days before today and today
  //   return current && current < moment(values.eventDates[1]);
  // };

  const disableStrokeplayRegDate: RangePickerProps['disabledDate'] = (
    current: any
  ) => {
    // Can not select days before today and today
    return current && current > moment(values.eventDates[0]);
  };


  const holesCount =
    values.course.holes === '3-Holes'
      ? 3
      : values.course.holes === '6-Holes'
        ? 6
        : values.course.holes === '9-Holes'
          ? 9
          : values.course.holes === '12-Holes'
            ? 12
            : 15;

  const holesParArr = values.course?.event_holes?.map((hole: any) => hole.par);
  const holesCountSum = holesParArr?.filter((par: any) => par !== null).length;
  const parSum = Number(holesParArr?.reduce(smashReducer));

  const hole1Par = Number(topTracerDetailsFromRedux?.holes[0]?.par);
  const hole2Par = Number(topTracerDetailsFromRedux?.holes[1]?.par);
  const hole3Par = Number(topTracerDetailsFromRedux?.holes[2]?.par);
  const hole4Par = Number(topTracerDetailsFromRedux?.holes[3]?.par);
  const hole5Par = Number(topTracerDetailsFromRedux?.holes[4]?.par);
  const hole6Par = Number(topTracerDetailsFromRedux?.holes[5]?.par);
  const hole7Par = Number(topTracerDetailsFromRedux?.holes[6]?.par);
  const hole8Par = Number(topTracerDetailsFromRedux?.holes[7]?.par);
  const hole9Par = Number(topTracerDetailsFromRedux?.holes[8]?.par);
  const hole10Par = Number(topTracerDetailsFromRedux?.holes[9]?.par);
  const hole11Par = Number(topTracerDetailsFromRedux?.holes[10]?.par);
  const hole12Par = Number(topTracerDetailsFromRedux?.holes[11]?.par);
  const hole13Par = Number(topTracerDetailsFromRedux?.holes[12]?.par);
  const hole14Par = Number(topTracerDetailsFromRedux?.holes[13]?.par);
  const hole15Par = Number(topTracerDetailsFromRedux?.holes[14]?.par);
  const hole16Par = Number(topTracerDetailsFromRedux?.holes[15]?.par);
  const hole17Par = Number(topTracerDetailsFromRedux?.holes[16]?.par);
  const hole18Par = Number(topTracerDetailsFromRedux?.holes[17]?.par);

  const parValSum =
    hole1Par +
    hole2Par +
    hole3Par +
    hole4Par +
    hole5Par +
    hole6Par +
    hole7Par +
    hole8Par +
    hole9Par +
    hole10Par +
    hole11Par +
    hole12Par +
    hole13Par +
    hole14Par +
    hole15Par +
    hole16Par +
    hole17Par +
    hole18Par;

  return (
    <MainLayout>
      <>
        <Row>
          <Col xl={20} sm={24}>
            <Divider orientation="left" orientationMargin="0">
              <Text className="heading">Edit Event</Text>
            </Divider>
          </Col>
        </Row>

        {reduxStatus !== 'loading' ? (
          <FormikProvider value={formikMain}>
            <form onSubmit={handleSubmit} className="create-event-form">
              <Row>
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <FormikTextField
                    label="EVENT NAME"
                    style={{ marginBottom: 36 }}
                    autoComplete="off"
                    error={Boolean(errors.name && touched.name) && errors.name}
                    placeholder="Enter your event name"
                    {...getFieldProps('name')}
                  />
                </Col>
                <Col span={8}></Col>
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <Alert message="Note: Kindly exercise caution while making updates to the Flights/Shot Clock Time as this shall have impact on player experience and score uploads. We recommend you to avoid making changes to Flights/Shot Clocks once the event is live." type="info" style={{ marginBottom: 10 }} />
                </Col>
              </Row>
              <FormikRadioInputThreeFields
                label="EVENT LEVEL"
                option1="Non-League"
                option2="League"
                value1="non_league"
                value2="league"
                onChange={() => setFieldValue('league_id', "")}
                autoComplete="off"
                error={
                  Boolean(errors.event_level && touched.event_level) &&
                  errors.event_level
                }
                {...getFieldProps('event_level')}
              />
              {values.event_level === "non_league" &&
                <Row>
                  <Col xl={24} lg={12} sm={12} xs={24}>
                    <FormikRadioInput
                      label="IS PRIVATE EVENT? (PASSWORD PROTECTED)"
                      option1="Yes"
                      option2="No"
                      autoComplete="off"
                      error={
                        Boolean(errors.is_private && touched.is_private) &&
                        errors.is_private
                      }
                      {...getFieldProps('is_private')}
                    />
                  </Col>
                </Row>
              }
              {values.is_private === "Yes" &&
                <Row>
                  <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                    <FormikTextField
                      label="PASSWORD"
                      type="Password"
                      autoComplete="off"
                      error={Boolean(errors.password && touched.password) && errors.password}
                      placeholder="Enter password"
                      {...getFieldProps('password')}
                    />
                  </Col>
                  <Col span={8}></Col>
                  <Col span={8}></Col>
                </Row>}
              {values.event_level === "league" &&
                <Row >
                  <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                    <FormikLeaguNamesSelect
                      label="SELECT LEAGUE"
                      placeholder={"Select League"}
                      options={leagueNamesOptions}
                      autoComplete="off"
                      setFieldValue={setFieldValue}
                      error={
                        Boolean(errors.league_id && touched.league_id) && errors.league_id
                      }
                      {...getFieldProps('league_id')}
                    />
                  </Col>
                </Row>
              }
              <Row>
                <Col xl={18} sm={24}>
                  <Divider
                    orientation="left"
                    orientationMargin="0"
                    style={{ marginBottom: 36 }}
                  >
                    <Text className="points-heading"> Toptracer Setup </Text>
                  </Divider>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <FormikCourseNamesSelect
                    label="SELECT COURSE"
                    placeholder={'Select a Course'}
                    options={courseNamesOptions}
                    autoComplete="off"
                    setFieldValue={setFieldValue}
                    error={
                      Boolean(errors.course && touched.course) &&
                      errors.course.course_id
                    }
                    {...getFieldProps('course.course_id')}
                  />
                </Col>
              </Row>
              <Row>
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <FormikSelect
                    label="SELECT EVENT TYPE"
                    placeholder={'Select a type of event'}
                    style={{ marginBottom: 36, textTransform: 'capitalize' }}
                    autoComplete="off"
                    setFieldValue={setFieldValue}
                    onClick={async () => {
                      await setFieldValue('scoring', "");
                      await setFieldValue('course.tee_box', "");
                      await setFieldValue('course.holes', "");
                      await setFieldValue('smash_points', '');
                      await setFieldValue('world_points', '');
                      await setFieldValue('total_league_points', '');
                      await setFieldValue('rewards[0].smash_points', '');
                      await setFieldValue('rewards[0].world_points', '');
                      await setFieldValue('rewards[0].gross_smash_cash', '');
                      await setFieldValue('rewards[0].net_smash_cash', '');
                      await setFieldValue('rewards[0].gross_league_points', '');
                      await setFieldValue('rewards[0].net_league_points', '');
                      await setFieldValue('rewards[0].cttp_league_points', '');
                      await setFieldValue('rewards[0].ld_league_points', '');
                      await setFieldValue('flight_distribution_id', undefined);
                      await setFieldValue('rewards[0].gross_world_points', '');
                      await setFieldValue('rewards[0].net_world_points', '');
                    }}
                    options={eventTypeOptions}
                    fieldValue="course.event_type"
                    error={
                      Boolean(errors.course && touched.course) &&
                      errors.course.event_type
                    }
                    {...getFieldProps('course.event_type')}
                  />
                </Col>
              </Row>
              {(values.course.event_type === 'Strokeplay' ||
                values.course.event_type === 'Scramble') && (
                  <>
                    <Row gutter={8}>
                      <Col xl={4} lg={5} sm={12} xs={24}>
                        <FormikSelect
                          label="TEE BOX"
                          placeholder={'Select Tee Box'}
                          style={{ marginBottom: 36 }}
                          autoComplete="off"
                          setFieldValue={setFieldValue}
                          options={teeBoxOptions}
                          fieldValue="course.tee_box"
                          error={
                            Boolean(errors.course && touched.course) &&
                            errors.course.tee_box
                          }
                          {...getFieldProps('course.tee_box')}
                        />
                      </Col>
                      <Col xl={4} lg={5} sm={12} xs={24}>
                        <FormikSelect
                          label="NO. OF HOLES"
                          placeholder={'Select No. of Holes'}
                          style={{ marginBottom: 36 }}
                          autoComplete="off"
                          setFieldValue={setFieldValue}
                          options={holeOptions}
                          fieldValue="course.holes"
                          error={
                            Boolean(errors.course && touched.course) &&
                            errors.course.holes
                          }
                          {...getFieldProps('course.holes')}
                          onClick={(e: any) => {
                            setFieldValue(
                              'par_value',
                              e.target.textContent === '18-Holes'
                                ? parValSum
                                : null
                            );
                          }}
                          onSelect={() => {
                            for (
                              let i = 0;
                              i < 18;
                              i++
                            ) {
                              setFieldValue(
                                `course.event_holes[${i}].par`,
                                null
                              );
                              setFieldValue(
                                `uniqueHoleArr[${i}]`,
                                null
                              )
                            }
                          }}
                        />
                      </Col>

                      {(values.course.event_type === 'Strokeplay' ||
                        values.course.event_type === 'Scramble') && (
                          <>
                            {values.course.holes === '18-Holes' && (
                              <Col xl={4} lg={5} md={6} sm={12} xs={24}>
                                <FormikTextField
                                  label="PAR VALUE"
                                  style={{
                                    marginBottom: 36,
                                  }}
                                  autoComplete="off"
                                  disabled
                                  {...getFieldProps('par_value')}
                                />
                              </Col>
                            )}
                            <Col xl={4} lg={5} sm={12} xs={24}>
                              <Text className="poster-text">{
                                values.course.event_type === 'Scramble'
                                  ? 'SCRAMBLE EVENT TYPE'
                                  : 'STROKEPLAY EVENT TYPE'
                              }</Text>
                              <Field as="select" name="scoring" className="scoring-options" >
                                <option value="" selected disabled hidden>
                                  Select Event Type
                                </option>
                                <option value="Gross">Gross</option>
                                <option value="Net">Net</option>
                                <option value="Gross_Net">Both (Gross+Net)</option>
                              </Field>
                            </Col>
                          </>
                        )}
                      {values.course.event_type === 'Scramble' && (
                        <Col xl={4} lg={5} sm={12} xs={24}>
                          <FormikSelect
                            label="SCRAMBLE TEAM MEMBERS"
                            style={{
                              marginBottom: 36,
                            }}
                            autoComplete="off"
                            setFieldValue={setFieldValue}
                            fieldValue="team_members"
                            options={[2, 4]}
                            placeholder="Select course"
                            error={
                              Boolean(errors.team_members && touched.team_members) &&
                              errors.team_members
                            }
                            {...getFieldProps('team_members')}
                          />
                        </Col>
                      )}
                      {values.course.event_type === 'Strokeplay' &&
                        values.scoring === 'Net' && (
                          <Col xl={24} lg={12} sm={12} xs={24}>
                            <FormikRadioInput
                              label="FLIGHTS"
                              style={{
                                marginBottom: 36,
                                marginLeft: 6,
                              }}
                              option1="Yes"
                              option2="No"
                              autoComplete="off"
                              error={
                                Boolean(errors.flights && touched.flights) &&
                                errors.flights
                              }
                              {...getFieldProps('flights')}
                            />
                          </Col>
                        )}
                    </Row>
                    {values.course.event_type === 'Strokeplay' &&
                      values.flights === 'Yes' &&
                      <Row >
                        <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                          <FormikFlightsSelect
                            label="SELECT FLIGHT SETUP"
                            placeholder={"Select Flight Setup"}
                            options={flightDistributionOptions}
                            autoComplete="off"
                            setFieldValue={setFieldValue}
                            error={
                              Boolean(errors.flight_distribution_id && touched.flight_distribution_id) && errors.flight_distribution_id
                            }
                            {...getFieldProps('flight_distribution_id')}
                          />
                        </Col>
                      </Row>}
                    <Row>
                      <Col span={24}>
                        {values.course.holes !== '18-Holes' && (
                          <>
                            <Text className="poster-text">SETUP CUSTOM HOLES</Text>
                            <div className="holesCheckbox">
                              <div style={{ textAlign: 'end' }}>PAR: {parSum ? parSum : null}</div>
                              <div style={{ marginBottom: 10 }}>
                                <Text>FRONT</Text>
                                {(values.course.holes !== '3-Holes' && values.course.holes !== '6-Holes' && values.course.holes !== undefined) &&
                                  <Button type="text" className='holeSelectAll' disabled={selectAllBackHoles} onClick={() => {
                                    setSelectAllFrontHoles(!selectAllFrontHoles)
                                    if (!selectAllFrontHoles) {
                                      for (
                                        let i = 0;
                                        i < 9;
                                        i++
                                      ) {
                                        setFieldValue(
                                          `course.event_holes[${i}].par`,
                                          Number(topTracerDetailsFromRedux?.holes[i]?.par)
                                        );
                                        setFieldValue(
                                          `uniqueHoleArr[${i}]`,
                                          i
                                        )
                                      }
                                    } else {
                                      for (
                                        let i = 0;
                                        i < 9;
                                        i++
                                      ) {
                                        setFieldValue(
                                          `course.event_holes[${i}].par`,
                                          null
                                        );
                                        setFieldValue(
                                          `uniqueHoleArr[${i}]`,
                                          null
                                        )
                                      }
                                    }

                                  }}>{selectAllFrontHoles ? "(UNSELECT ALL FRONT NINE)" : "(SELECT ALL FRONT NINE)"}</Button>
                                }

                              </div>
                              <div >
                                {[...Array(9)].map((_, index: any) => {

                                  return (
                                    <Checkbox key={index} className="holeParValCheckbox" onChange={(e: CheckboxChangeEvent) => {
                                      setFieldValue(`course.event_holes[${index}].par`, e.target.checked ? Number(topTracerDetailsFromRedux?.holes[index]?.par) : null);
                                      setFieldValue(`uniqueHoleArr[${index}]`, e.target.checked ? index : null)

                                    }}

                                      checked={values.course.event_holes[index].par !== null}
                                      disabled={holesCount === holesCountSum &&
                                        !values.uniqueHoleArr.includes(index)}
                                    >{index + 1}</Checkbox>
                                  )
                                })}

                              </div>
                              <div style={{ marginTop: 10, marginBottom: 10 }}>
                                <Text>BACK</Text>
                                {(values.course.holes !== '3-Holes' && values.course.holes !== '6-Holes' && values.course.holes !== undefined) &&

                                  <Button type="text" className='holeSelectAll' disabled={selectAllFrontHoles} onClick={() => {
                                    setSelectAllBackHoles(!selectAllBackHoles)
                                    if (!selectAllBackHoles) {
                                      for (
                                        let i = 9;
                                        i < 18;
                                        i++
                                      ) {
                                        setFieldValue(
                                          `course.event_holes[${i}].par`,
                                          Number(topTracerDetailsFromRedux?.holes[i]?.par)
                                        );
                                        setFieldValue(
                                          `uniqueHoleArr[${i + 9}]`,
                                          i
                                        )
                                      }
                                    } else {
                                      for (
                                        let i = 9;
                                        i < 18;
                                        i++
                                      ) {
                                        setFieldValue(
                                          `course.event_holes[${i}].par`,
                                          null
                                        );
                                        setFieldValue(
                                          `uniqueHoleArr[${i + 9}]`,
                                          null
                                        )
                                      }
                                    }

                                  }}>{selectAllBackHoles ? "(UNSELECT ALL BACK NINE)" : "(SELECT ALL BACK NINE)"}</Button>
                                }

                              </div>
                              <div >
                                {[...Array(9)].map((_, index: any) => {
                                  return (
                                    <Checkbox key={index} className="holeParValCheckbox" onChange={(e: CheckboxChangeEvent) => {
                                      setFieldValue(`course.event_holes[${index + 9}].par`, e.target.checked ? Number(topTracerDetailsFromRedux?.holes[index + 9]?.par) : null);
                                      setFieldValue(`uniqueHoleArr[${index + 9}]`, e.target.checked ? index + 9 : null)

                                    }}
                                      checked={values.course.event_holes[index + 9].par !== null}
                                      disabled={holesCount === holesCountSum &&
                                        !values.uniqueHoleArr.includes(index + 9)}
                                    >{index + 10}</Checkbox>
                                  )
                                })}

                              </div>



                            </div>
                          </>
                        )}
                      </Col>
                    </Row>
                  </>
                )}
              <Row gutter={4}>
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <FormikRangePicker
                    format="dddd, MM/DD/YYYY h:mm:ss a"
                    showTime
                    label="EVENT START - END DATE"
                    style={{ marginBottom: 36 }}
                    autoComplete="off"
                    setFieldValue={setFieldValue}
                    fieldValue="eventDates"
                    error={
                      Boolean(errors.eventDates && touched.eventDates) &&
                      errors.eventDates
                    }
                    {...getFieldProps('eventDates')}
                  />
                </Col>
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <FormikDatePicker
                    format="dddd, MM/DD/YYYY h:mm:ss a"
                    showTime
                    label="REGISTRATION DEADLINE"
                    style={{ marginBottom: 36 }}
                    autoComplete="off"
                    fieldValue="registration_deadline"
                    setFieldValue={setFieldValue}
                    disabledDate={(values.course.event_type === 'Strokeplay' &&
                      values.flights === 'Yes' && values.scoring === 'Net') ?
                      disableStrokeplayRegDate : null
                    }
                    error={
                      Boolean(
                        errors.registration_deadline &&
                        touched.registration_deadline
                      ) && errors.registration_deadline
                    }
                    {...getFieldProps('registration_deadline')}
                  />
                </Col>
                <Col span={8}></Col>
              </Row>
              <Row>
                <Col offset={8} span={8}>
                  {values.course.event_type === 'Strokeplay' && values.flights === 'Yes' && values.scoring === 'Net' &&
                    <Text style={{ color: 'red', fontSize: 11 }}>*Please note that you can only create flighted events with future dates(upcoming events). We recommend creating flighted events with a registration date/time that is earlier than the event start date.
                    </Text>}
                </Col>
              </Row>
              <Row>
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <FormikDatePicker
                    format="dddd, MM/DD/YYYY h:mm:ss a"
                    showTime
                    //disabledDate={disabledDate}
                    label="SCORE UPLOAD DEADLINE"
                    style={{ marginBottom: 36 }}
                    autoComplete="off"
                    fieldValue="scores_upload_cutoff_time"
                    setFieldValue={setFieldValue}
                    error={
                      Boolean(
                        errors.scores_upload_cutoff_time &&
                        touched.scores_upload_cutoff_time
                      ) && errors.scores_upload_cutoff_time
                    }
                    {...getFieldProps('scores_upload_cutoff_time')}
                  />
                </Col>
                <Col span={8}></Col>
                <Col span={8}></Col>
              </Row>
              <Row>
                <Col xl={24} lg={12} sm={12} xs={24}>
                  <FormikRadioInputThreeFields
                    label="SHOT CLOCK"
                    option1="Enable"
                    option2="Disable"
                    value1="Yes"
                    value2="No"
                    {...getFieldProps("shot_clock_enabled")}
                  />
                </Col>
              </Row>
              {values.shot_clock_enabled === "Yes" &&
                <CounterInputField
                  label={
                    <>
                      <Text style={{ marginRight: 5, fontSize: 11, letterSpacing: 1 }}>ALLOWED TIME(MINS)</Text>
                      <Tooltip
                        color="#d3d3d3"
                        placement="rightTop"
                        title={
                          <div style={{ textAlign: 'center' }}>
                            <h5><b>Alllowed Time = Time Per Hole * No. Of Holes</b></h5>
                            <h5>As per the <b>3-Holes</b> event setup, players will have <b>45 Minutes</b> to enter scores.</h5>
                          </div>

                        }
                      >
                        <InfoCircleOutlined style={{ color: 'black', cursor: 'pointer' }} />
                      </Tooltip>
                    </>

                  }
                  onDecrement={() => {
                    setFieldValue("shot_clock_time", values.shot_clock_time - 1)
                  }}
                  onIncrement={() => {
                    setFieldValue("shot_clock_time", values.shot_clock_time + 1)
                  }}
                  decrementDisabled={values.shot_clock_time === 1}
                  value={values.shot_clock_time}
                  onInputChange={(val: any) => setFieldValue("shot_clock_time", val)}
                />
              }
              {values.shot_clock_enabled === "Yes" &&
                <Row>
                  <Col xl={24} lg={12} sm={12} xs={24}>
                    <FormikRadioInputThreeFields
                      label="EXTRA TIME/GRACE TIME"
                      option1="Yes"
                      option2="No"
                      value1="Yes"
                      value2="No"
                      {...getFieldProps("grace_time_enabled")}
                    />
                  </Col>
                </Row>
              }
              {values.grace_time_enabled === "Yes" && values.shot_clock_enabled === "Yes" &&
                <CounterInputField
                  label={<Text style={{ fontSize: 11, letterSpacing: 1 }}>EXTRA/GRACE TIME(MINS)</Text>}
                  onDecrement={() => {
                    setFieldValue("grace_time", values.grace_time - 1)
                  }}
                  onIncrement={() => {
                    setFieldValue("grace_time", values.grace_time + 1)
                  }}
                  decrementDisabled={values.grace_time === 1}
                  value={values.grace_time}
                  onInputChange={(val: any) => setFieldValue("grace_time", val)}
                />
              }
              {(values.grace_time > values.shot_clock_time) && values.grace_time_enabled === "Yes" &&
                <Text style={{ color: '#004C98', marginBottom: 5 }}>*Entered Extra Time &gt; Shot Clock Time. We recommend Extra Time &lt; Shot Clock Time.</Text>
              }
              <Row>
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <Text className="poster-text">EVENT RULES</Text>
                  <DraftRTE
                    initialValue={values.rules}
                    onTextChange={handleRTETextChange}
                  />
                  <div
                    className="error-wrapper"
                    style={{ color: 'red', fontSize: 11 }}
                  >
                    <ErrorMessage name="rules" className="error-line" />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col
                  xl={8}
                  lg={10}
                  md={12}
                  sm={24}
                  xs={24}
                  style={{ marginTop: 36 }}
                  onClick={() => setPreviewLogo(false)}
                >
                  <Text className="poster-text">EVENT POSTER</Text>
                  <ImageUpload
                    logoBase={posterBase}
                    setLogoBase={setPosterBase}
                  />
                </Col>
                <Col
                  xl={8}
                  lg={10}
                  md={12}
                  sm={24}
                  xs={24}
                  className="preview-img"
                >
                  {previewLogo && liveEventState && (
                    <Image
                      style={{
                        width: 80,
                        height: 80,
                        objectFit: 'contain',
                        marginRight: 10,
                        marginTop: 36,
                        background:
                          liveEventState.event_poster === null
                            ? '#141879'
                            : 'unset',
                      }}
                      src={
                        liveEventState.event_poster === null
                          ? Logo
                          : liveEventState.event_poster
                      }
                    />
                  )}
                </Col>
              </Row>

              {/* Points & awards starts here */}

              <>
                <Row>
                  <Col xl={18} sm={24}>
                    <Divider
                      orientation="left"
                      orientationMargin="0"
                      style={{ marginTop: 50 }}
                    >
                      <Text className="points-heading"> Points & Awards </Text>
                    </Divider>
                  </Col>
                </Row>
                <Row gutter={4}>
                  <Col xl={3} lg={4} sm={6} xs={12}>
                    <FormikTextField
                      label="TOTAL SMASH CASH"
                      style={{ marginBottom: 36 }}
                      autoComplete="off"
                      error={
                        Boolean(errors.smash_points && touched.smash_points) &&
                        errors.smash_points
                      }
                      placeholder="Enter smash cash"
                      {...getFieldProps('smash_points')}
                    />
                  </Col>
                  {(values.event_level === 'non_league') && (values.course.event_type === 'Strokeplay' ||
                    values.course.event_type === 'Scramble') && (
                      <Col xl={3} lg={4} sm={6} xs={12}>
                        <FormikTextField
                          label="TOTAL WORLD POINTS"
                          style={{ marginBottom: 36 }}
                          autoComplete="off"
                          error={
                            Boolean(errors.world_points && touched.world_points) &&
                            errors.world_points
                          }
                          placeholder="Enter world points"
                          {...getFieldProps('world_points')}
                        />
                      </Col>
                    )}
                  {values.event_level === 'league' && (
                    <Col xl={3} lg={4} sm={6} xs={12}>
                      <FormikTextField
                        label="TOTAL LEAGUE POINTS"
                        style={{ marginBottom: 36 }}
                        autoComplete="off"
                        error={
                          Boolean(errors.total_league_points && touched.total_league_points) &&
                          errors.total_league_points
                        }
                        placeholder="Enter league points"
                        {...getFieldProps('total_league_points')}
                      />
                    </Col>
                  )}
                </Row>
                <Row>
                <Col xl={4} lg={4} sm={6} xs={12}>
                  <FormikTextField
                    label="SMASH CASH FOR POSTING SCORE"
                    style={{ marginBottom: 36 }}
                    autoComplete="off"
                    error={
                      Boolean(errors.post_scoring_smash_cash && touched.post_scoring_smash_cash) &&
                      errors.post_scoring_smash_cash
                    }
                    placeholder="Smash Cash For Posting Score"
                    {...getFieldProps('post_scoring_smash_cash')}
                  />
                </Col>
              </Row>
                <Row>
                  <Col xl={16} sm={24}>
                    <Divider orientation="left" orientationMargin="0">
                      <Text
                        className="points-heading"
                        style={{ fontWeight: 'normal', fontSize: 14 }}
                      >
                        Manual Entry
                      </Text>
                    </Divider>
                  </Col>
                </Row>
                {values.course.event_type === 'Strokeplay' && values.scoring === "Net" &&
                  values.flights === 'Yes' &&
                  (<div className="flights-distr-text">Distribution For Strokeplay With Flights</div>
                  )}
                <div>
                  <FieldArray
                    name="rewards"
                    render={(arrayHelpers) => (
                      <div className="rewards-table-wrapper" style={{ width: values.scoring === "Gross_Net" ? 650 : 500 }}>
                        <div className="table-columns">
                          {values.course.event_type === 'Strokeplay' && values.scoring === "Net" &&
                            values.flights === 'Yes' && (
                              <Text className="column">Flights</Text>
                            )}
                          <Text className="column">Position</Text>
                          {values.scoring !== 'Gross_Net' && <Text className="column">Smash Cash</Text>}
                          {values.scoring === 'Gross_Net' && <Text className="column">Smash Cash For Gross</Text>}
                          {(values.event_level === "league" && (values.scoring === 'Gross' || values.scoring === 'Gross_Net')) && <Text className="column">Gross League Points</Text>}
                          {values.scoring === 'Gross_Net' && <Text className="column">Smash Cash For Net</Text>}
                          {(values.event_level === "league" && (values.scoring === 'Net' || values.scoring === 'Gross_Net')) && <Text className="column">{values.flights === "Yes" ? "League Points" : "Net League Points"}</Text>}
                          {(values.event_level === "non_league") && (values.scoring !== 'Gross_Net') && (values.course.event_type === 'Strokeplay' ||
                            values.course.event_type === 'Scramble') && (
                              <Text className="column">World Points</Text>
                            )}
                          {values.scoring === 'Gross_Net' && values.event_level === "non_league" && <Text className="column">World Points For Gross</Text>}
                          {values.scoring === 'Gross_Net' && values.event_level === "non_league" && <Text className="column">World Points For Net</Text>}
                          {values.event_level === "league" && values.course.event_type === "Closest To The Pin" && (
                            <Text className="column">CTTP League Points</Text>

                          )}
                          {values.event_level === "league" && values.course.event_type === "Longest Drive" && (
                            <Text className="column">LD League Points</Text>

                          )}
                        </div>
                        {values.rewards?.map((formItem: any, index: any) => (
                          <div key={index} className="rewards-table-row">
                            {values.course.event_type === 'Strokeplay' && values.scoring === "Net" &&
                              values.flights === 'Yes' && (
                                <div
                                  className="input-container"
                                  style={{ flex: 2, fontSize: 12 }}
                                >
                                  <Field
                                    as="select"
                                    {...getFieldProps(
                                      `rewards[${index}].flight_id`
                                    )}
                                    style={{ padding: 0 }}
                                    onChange={(e: any) => {
                                      const selectedFlightId = e.target.value;
                                      setFieldValue(
                                        `rewards[${index}].flight_id`,
                                        Number(selectedFlightId)
                                      );
                                      const allRewardsWithSelectedFlightId =
                                        values.rewards?.filter(
                                          (x: any) =>
                                            x.flight_id ===
                                            Number(selectedFlightId)
                                        );
                                      let largestPosition;
                                      if (
                                        allRewardsWithSelectedFlightId.length >
                                        0
                                      ) {
                                        const allPosMap =
                                          allRewardsWithSelectedFlightId.map(
                                            (x: any) => Number(x.position)
                                          );
                                        largestPosition = Math.max(
                                          ...allPosMap
                                        );
                                        if (largestPosition) {
                                          setFieldValue(
                                            `rewards[${index}].position`,
                                            Number(largestPosition + 1)
                                          );
                                        }
                                      } else {
                                        setFieldValue(
                                          `rewards[${index}].position`,
                                          Number(1)
                                        );
                                      }
                                    }}
                                  >
                                    <option value="" selected disabled hidden>
                                      Select Flight
                                    </option>
                                    {FlightNamesOptions?.map((flight: any) => (
                                      <option key={flight.id} value={flight.id}>
                                        {flight.name}  {flight.percentage === null ? `(${flight.min_hcp} to ${flight.max_hcp})` : `(${flight.percentage * 100}%)`}
                                      </option>
                                    ))}
                                  </Field>
                                </div>
                              )}
                            <div className="input-container">
                              <Field
                                disabled
                                {...getFieldProps(`rewards[${index}].position`)}
                              />
                              <div className="error-wrapper">
                                <ErrorMessage
                                  className="error-line"
                                  name={`rewards[${index}].position`}
                                />
                              </div>
                            </div>
                            {values.scoring !== 'Gross_Net' &&
                              <div className="input-container">
                                <Field
                                  {...getFieldProps(
                                    `rewards[${index}].smash_points`
                                  )}
                                />
                                <div className="error-wrapper">
                                  <ErrorMessage
                                    className="error-line"
                                    name={`rewards[${index}].smash_points`}
                                  />
                                </div>
                              </div>}
                            {values.scoring === 'Gross_Net' &&

                              <div className="input-container">
                                <Field
                                  {...getFieldProps(
                                    `rewards[${index}].gross_smash_cash`
                                  )}

                                />
                                <div className="error-wrapper">
                                  <ErrorMessage
                                    className="error-line"
                                    name={`rewards[${index}].gross_smash_cash`}
                                  />
                                </div>
                              </div>}
                            {(values.event_level === "league" && (values.scoring === 'Gross' || values.scoring === 'Gross_Net')) &&
                              <div className="input-container">
                                <Field
                                  {...getFieldProps(
                                    `rewards[${index}].gross_league_points`
                                  )}

                                />
                                <div className="error-wrapper">
                                  <ErrorMessage
                                    className="error-line"
                                    name={`rewards[${index}].gross_league_points`}
                                  />
                                </div>
                              </div>}
                            {values.scoring === 'Gross_Net' &&
                              <div className="input-container">
                                <Field
                                  {...getFieldProps(
                                    `rewards[${index}].net_smash_cash`
                                  )}

                                />
                                <div className="error-wrapper">
                                  <ErrorMessage
                                    className="error-line"
                                    name={`rewards[${index}].net_smash_cash`}
                                  />
                                </div>
                              </div>}
                            {(values.event_level === "league" && (values.scoring === 'Net' || values.scoring === 'Gross_Net')) &&
                              <div className="input-container">
                                <Field
                                  {...getFieldProps(
                                    `rewards[${index}].net_league_points`
                                  )}

                                />
                                <div className="error-wrapper">
                                  <ErrorMessage
                                    className="error-line"
                                    name={`rewards[${index}].net_league_points`}
                                  />
                                </div>
                              </div>}


                            {(values.event_level === "non_league") && (values.scoring !== 'Gross_Net') && (values.course.event_type === 'Strokeplay' ||
                              values.course.event_type === 'Scramble') && (
                                <div className="input-container">
                                  <Field
                                    {...getFieldProps(
                                      `rewards[${index}].world_points`
                                    )}
                                  />
                                  <div className="error-wrapper">
                                    <ErrorMessage
                                      name={`rewards[${index}].world_points`}
                                    />
                                  </div>
                                </div>
                              )}
                            {values.scoring === 'Gross_Net' && values.event_level === "non_league" &&
                              <div className="input-container">
                                <Field
                                  {...getFieldProps(
                                    `rewards[${index}].gross_world_points`
                                  )}

                                />
                                <div className="error-wrapper">
                                  <ErrorMessage
                                    className="error-line"
                                    name={`rewards[${index}].gross_world_points`}
                                  />
                                </div>
                              </div>}
                            {values.scoring === 'Gross_Net' && values.event_level === "non_league" &&
                              <div className="input-container">
                                <Field
                                  {...getFieldProps(
                                    `rewards[${index}].net_world_points`
                                  )}

                                />
                                <div className="error-wrapper">
                                  <ErrorMessage
                                    className="error-line"
                                    name={`rewards[${index}].net_world_points`}
                                  />
                                </div>
                              </div>}
                            {values.event_level === "league" && values.course.event_type === "Closest To The Pin" && (
                              <div className="input-container">
                                <Field
                                  {...getFieldProps(
                                    `rewards[${index}].cttp_league_points`
                                  )}
                                />
                                <div className="error-wrapper">
                                  <ErrorMessage
                                    name={`rewards[${index}].cttp_league_points`}
                                  />
                                </div>
                              </div>
                            )}
                            {values.event_level === "league" && values.course.event_type === "Longest Drive" && (
                              <div className="input-container">
                                <Field
                                  {...getFieldProps(
                                    `rewards[${index}].ld_league_points`
                                  )}
                                />
                                <div className="error-wrapper">
                                  <ErrorMessage
                                    name={`rewards[${index}].ld_league_points`}
                                  />
                                </div>
                              </div>
                            )}
                            {values.flights !== 'Yes' && (
                              <div className="rowDeleteBtn">
                                <DeleteOutlined
                                  style={{
                                    display:
                                      values.rewards.map((x: any) => {
                                        return x;
                                      }).length === 1
                                        ? 'none'
                                        : 'block',
                                  }}
                                  onClick={() => {
                                    const positionArr = values.rewards.map(
                                      (x: any) => {
                                        return x;
                                      }
                                    );

                                    arrayHelpers.remove(index);
                                    for (
                                      let i = index;
                                      i < positionArr.length - 1;
                                      i++
                                    ) {
                                      setFieldValue(
                                        `rewards[${i}].position`,
                                        positionArr[i].position
                                      );
                                    }
                                  }} // Deletes a row
                                />
                              </div>
                            )}
                          </div>
                        ))}

                        <div style={{ textAlign: 'end', marginTop: 16 }}>
                          {values.flights === 'Yes' && (
                            <Button
                              danger
                              style={{ marginRight: 10 }}
                              disabled={
                                values.rewards?.map((x: any) => {
                                  return x;
                                }).length === 1
                              }
                              onClick={() => arrayHelpers.remove(-1)} // Deletes the last row
                            >
                              Delete Position
                            </Button>
                          )}
                          <Button
                            type="primary"
                            disabled={
                              (Number(
                                values.rewards?.map((x: any) => Number(x.smash_points))
                                  .reduce(smashReducer)
                              ) >= Number(values.smash_points) ||
                                Number(
                                  values.rewards?.map((x: any) => Number(x.gross_smash_cash) + Number(x.net_smash_cash))
                                    .reduce(smashReducer)
                                ) >= Number(values.smash_points))
                              &&
                              (Number(
                                values.rewards?.map((x: any) => Number(x.world_points))
                                  .reduce(smashReducer)
                              ) >= Number(values.world_points) ||
                                Number(
                                  values.rewards?.map((x: any) => Number(x.gross_world_points) + Number(x.net_world_points))
                                    .reduce(smashReducer)
                                ) >= Number(values.world_points))
                              &&
                              (Number(
                                values.rewards?.map((x: any) => Number(x.cttp_league_points) + Number(x.ld_league_points))
                                  .reduce(smashReducer)
                              ) >= Number(values.total_league_points) ||
                                Number(
                                  values.rewards?.map((x: any) => Number(x.gross_league_points) + Number(x.net_league_points))
                                    .reduce(smashReducer)
                                ) >= Number(values.total_league_points))
                            }
                            onClick={() => {
                              arrayHelpers.push({
                                position:
                                  values.flights !== 'Yes'
                                    ? values.rewards.length &&
                                    values.rewards.length + 1
                                    : '',
                                smash_points: '',
                                world_points: '',
                                flight_id: undefined,
                                gross_league_points: '',
                                net_league_points: '',
                                gross_smash_cash: '',
                                net_smash_cash: '',
                                cttp_league_points: '',
                                ld_league_points: '',
                                gross_world_points: '',
                                net_world_points: ''
                              });
                            }} // insert an empty string at a position
                          >
                            + Add Position
                          </Button>
                        </div>
                      </div>
                    )}
                  />
                </div>
              </>
              <FormikRadioInput
                label="EVENT ENTRY"
                style={{
                  marginBottom: 36,
                  textTransform: 'capitalize',
                }}
                option1="free"
                option2="paid"
                autoComplete="off"
                error={
                  Boolean(errors.event_entry && touched.event_entry) &&
                  errors.event_entry
                }
                {...getFieldProps('event_entry')}
              />

              {/* Payments And Promos starts here */}
              {values.event_entry === 'paid' && (
                <>
                  <Row>
                    <Col xl={18} sm={24}>
                      <Divider orientation="left" orientationMargin="0">
                        <Text className="payments-heading">
                          {' '}
                          Payments & Promos{' '}
                        </Text>
                      </Divider>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={4} lg={4} md={4} sm={8}>
                      <FormikTextField
                        label="EVENT FEES"
                        style={{ marginBottom: 36 }}
                        autoComplete="off"
                        error={
                          Boolean(errors.event_fees && touched.event_fees) &&
                          errors.event_fees
                        }
                        placeholder="Fee"
                        {...getFieldProps('event_fees')}
                      />
                    </Col>
                  </Row>
                  <FormikRadioInput
                    label="MULTIPLE ENTRIES"
                    style={{ marginBottom: 36 }}
                    option1="Yes"
                    option2="No"
                    autoComplete="off"
                    error={
                      Boolean(
                        errors.multiple_entries && touched.multiple_entries
                      ) && errors.multiple_entries
                    }
                    {...getFieldProps('multiple_entries')}
                  />
                  <FormikRadioInput
                    label="COUPON/PROMO CODES?"
                    style={{ marginBottom: 36 }}
                    option1="Yes"
                    option2="No"
                    autoComplete="off"
                    error={
                      Boolean(
                        errors.has_promocodes && touched.has_promocodes
                      ) && errors.has_promocodes
                    }
                    placeholder="Fee"
                    {...getFieldProps('has_promocodes')}
                  />

                  {values.has_promocodes === 'Yes' && (
                    <div>
                      <FieldArray
                        name="promocodes"
                        render={(arrayHelpers) => (
                          <div className="rewards-table-wrapper">
                            <div className="table-columns">
                              <Text className="column">Promo Code</Text>
                              <Text className="column">Value Off($)</Text>
                            </div>
                            {values.promocodes.map(
                              (formItem: any, index: any) => (
                                <div key={index} className="rewards-table-row">
                                  <div className="input-container">
                                    <Field
                                      style={{ textTransform: 'upperCase' }}
                                      {...getFieldProps(
                                        `promocodes.${index}.name`
                                      )}
                                    />
                                    <div className="error-wrapper">
                                      <ErrorMessage
                                        className="error-line"
                                        name={`promocodes.${index}.name`}
                                      />
                                    </div>
                                  </div>

                                  <div className="input-container">
                                    <Field
                                      {...getFieldProps(
                                        `promocodes.${index}.value`
                                      )}
                                    />
                                    <div className="error-wrapper">
                                      <ErrorMessage
                                        name={`promocodes.${index}.value`}
                                      />
                                    </div>
                                  </div>
                                  <div className="rowDeleteBtn">
                                    <DeleteOutlined
                                      onClick={() => arrayHelpers.remove(index)} // Deletes a row
                                    />
                                  </div>
                                </div>
                              )
                            )}
                            <div style={{ textAlign: 'end', marginTop: 16 }}>
                              <Button
                                type="primary"
                                onClick={() =>
                                  arrayHelpers.push({
                                    name: '',
                                    value: '',
                                  })
                                } // insert an empty string at a position
                              >
                                + Add Promo
                              </Button>
                            </div>
                          </div>
                        )}
                      />
                    </div>
                  )}
                </>
              )}
              <Space direction="horizontal" style={{ marginTop: 60 }}>
                <Button
                  disabled={isSubmitting}
                  loading={isSubmitting}
                  type="primary"
                  htmlType="submit"
                  className="create-event-btn"
                >
                  Save Changes
                </Button>
                <Link to="/admin/events">
                  <Button
                    type="link"
                    size={'middle'}
                    style={{ color: 'gray', marginTop: 20 }}
                  >
                    Cancel
                  </Button>
                </Link>
              </Space>
            </form>
          </FormikProvider>
        ) : (
          <Row>
            <Col
              span={24}
              style={{
                paddingTop: 100,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Spin />
            </Col>
          </Row>
        )}
      </>
    </MainLayout>
  );
};

export default EditEvent;
