import {
  FormikRadioInputThreeFields,
  FormikTextField,
} from "@components/inputFeilds/InputFeilds";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import { MainLayout } from "@components/layouts";
import { Alert, Col, Row, Space, Typography, notification } from "antd";
import { ErrorMessage, Field, FormikProvider, useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "@config/storeConfig";
import { Slide, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { fetchFlight } from "@slices/flightsSlice";
import CancelBtn from "@components/buttons/CancelBtn";
import Spinner from "@components/loaders/Spinner";
import FormHeader from "@components/formHeader/FormHeader";
import SaveBtn from "@components/buttons/SaveBtn";
import { editFlightApi } from "@slices/API/flightsApi";
import CounterInputField from "@components/inputFeilds/CounterInputField";

const { Text } = Typography;

const GENDER_OPTIONS = [
  {
    id: 1,
    name: "Male",
    value: "male",
  },
  {
    id: 2,
    name: "Female",
    value: "female",
  },
  {
    id: 3,
    name: "Both",
    value: "both",
  },
];

const EditFlight = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const currentFlight = useSelector((state: any) => state.flights.data.data);
  const reduxStatus = useSelector((state: any) => state.flights.status);
  const [isError, setIsError] = useState(false);

  const updatedFlightsData = currentFlight?.flights
    .map((data: any) => {
      return {
        name: data.name,
        min_hcp: data.min_hcp === -12345 ? "-" : data.min_hcp,
        max_hcp: data.max_hcp === 12345 ? "-" : data.max_hcp,
        percentage: data.percentage * 100,
        gender: data.gender,
      };
    })
    .sort((a: any, b: any) => a.min_hcp - b.min_hcp);

  const formikMain = useFormik({
    initialValues: {
      name: currentFlight && currentFlight.name ? currentFlight.name : "",
      flight_type:
        currentFlight && currentFlight.flight_type
          ? currentFlight.flight_type
          : "",
      number: currentFlight && currentFlight.number ? currentFlight.number : "",
      flights: updatedFlightsData && updatedFlightsData,
    },
    enableReinitialize: true,
    onSubmit: async () => {
      setSubmitting(true);
      const flightsData = {
        name: values.name,
        flight_type: values.flight_type,
        number: values.number,
        flights: formulatedFinalFlights(values.flight_type, values.number)
      };
      if (values.flight_type === "percentage" && flightsSum === 100) {
        const response = await editFlightApi({ flightsData, id: params.id });
        setSubmitting(false);
        if (response.data.status === 201 || response.data.status === 200) {
          setIsError(false);
          resetForm();
          navigate("/admin/flights");
        } else if (response.data.status === 403) {
          notification.error({
            message:
              "Distribution cannot be updated, one or more events are using this distribution",
          });
        } else if (response.data.status === 500) {
          notification.error({
            message: response.data.statusText
              ? response.data.statusText
              : "Internal Server Error(500)",
          });
        } else {
          setIsError(true);
        }
      } else if (values.flight_type === "hcp_value") {
        const response = await editFlightApi({ flightsData, id: params.id });
        setSubmitting(false);
        if (response.data.status === 201 || response.data.status === 200) {
          setIsError(false);
          resetForm();
          navigate("/admin/flights");
        } else if (response.data.status === 403) {
          notification.error({
            message:
              "Distribution cannot be updated, one or more events are using this distribution",
          });
        } else if (response.data.status === 500) {
          notification.error({
            message: response.data.statusText
              ? response.data.statusText
              : "Internal Server Error(500)",
          });
        } else {
          setIsError(true);
        }
      } else if (values.flight_type === "inrange_flights") {
        const response = await editFlightApi({ flightsData, id: params.id });
        setSubmitting(false);
        if (response.data.status === 201 || response.data.status === 200) {
          setIsError(false);
          resetForm();
          navigate("/admin/flights");
        } else if (response.data.status === 403) {
          notification.error({
            message:
              "Distribution cannot be updated, one or more events are using this distribution",
          });
        } else if (response.data.status === 500) {
          notification.error({
            message: response.data.statusText
              ? response.data.statusText
              : "Internal Server Error(500)",
          });
        } else {
          setIsError(true);
        }
      } else {
        setIsError(false);
      }
    },
    validationSchema: yup.object({
      name: yup.string().required("*Required Flight Name"),
      flight_type: yup.string().required("*Please Select flight type"),
      flights: yup.array().of(
        yup.object().shape({
          percentage: yup
            .number()
            .min(0, "*Must be >= 0")
            .typeError("*Should be a number"),
          min_hcp: yup
            .string()
            .matches(/^[0-9.-]+$/, "Must be a Number or -")
            .nullable(),
          max_hcp: yup
            .string()
            .matches(/^[0-9.-]+$/, "Must be a Number or -")
            .nullable()
            .when("min_hcp", {
              is: (val: any) => val && val.length > 0 && val !== "-",
              then: yup
                .string()
                .test(
                  "max_hcp",
                  "Max HCP must be greater than Min HCP",
                  function (value: any) {
                    if (value === "-") return true;
                    return (
                      parseFloat(value) >
                      parseFloat(this.resolve(yup.ref("min_hcp")))
                    );
                  }
                ),
            }),
        })
      ),
    }),
  });

  const {
    getFieldProps,
    handleSubmit,
    values,
    setSubmitting,
    resetForm,
    isSubmitting,
    errors,
    touched,
    setFieldValue,
  } = formikMain;

  useEffect(() => {
    dispatch(fetchFlight({ id: params.id }));
    toast.info(
      <>
        <p>Edit your flight here!</p>
      </>,
      {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
        transition: Slide,
        style: { minWidth: 400 },
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //From response we are getting extra fields inside flights array
  //so in order to not send those extra fields in the payload filtering the flights data
  //is required to pass the only required fields.
  const filteredPercentageFlights = values.flights?.map((flight: any) => {
    return {
      name: flight?.name,
      min_hcp: undefined,
      max_hcp: undefined,
      percentage: flight?.percentage,
    };
  });

  const filteredHcpFlights = values.flights?.map((flight: any) => {
    return {
      name: flight?.name,
      min_hcp: flight?.min_hcp,
      max_hcp: flight?.max_hcp,
      percentage: undefined,
    };
  });

  const filteredInrangeFlights = values.flights?.map((flight: any) => {
    if (flight?.min_hcp === "-") {
      return {
        ...flight,
        min_hcp: -12345,
        max_hcp: Number(flight?.max_hcp),
        percentage: undefined,
      };
    }
    if (flight?.max_hcp === "-") {
      return {
        ...flight,
        max_hcp: 12345,
        min_hcp: Number(flight?.min_hcp),
        percentage: undefined,
      };
    }
    return {
      ...flight,
      min_hcp: Number(flight?.min_hcp),
      max_hcp: Number(flight?.max_hcp),
      percentage: undefined,
      gender: flight?.gender,
    };
  });

  //For converting "-" to Number for min_hcp and max_hcp as
  //this is the way backend wants the payload
  const updatedHcpFlightsArray = filteredHcpFlights?.map((flight: any) => {
    if (flight?.min_hcp === "-") {
      return {
        ...flight,
        min_hcp: -12345,
        max_hcp: Number(flight?.max_hcp),
        percentage: undefined,
      };
    }
    if (flight?.max_hcp === "-") {
      return {
        ...flight,
        max_hcp: 12345,
        min_hcp: Number(flight?.min_hcp),
        percentage: undefined,
      };
    }
    return {
      ...flight,
      min_hcp: Number(flight?.min_hcp),
      max_hcp: Number(flight?.max_hcp),
      percentage: undefined,
    };
  });

  const formulatedFinalFlights = (type: string, number: number) => {
    switch (type) {
      case "percentage":
        return filteredPercentageFlights.slice(0, values.number);
      case "hcp_values":
        return updatedHcpFlightsArray.slice(0, values.number);
      case "inrange_flights":
        return filteredInrangeFlights.slice(0, values.number);
      default:
        return filteredPercentageFlights;
    }
  };

  const reducer = (accumulator: any, curr: any) => accumulator + curr;
  const flightsSum = values.flights
    ?.map((flight: any) => Number(flight?.percentage))
    .reduce(reducer);

  return (
    <MainLayout>
      <div className="community-container">
        <FormHeader heading="Edit Flight Setup" />
        {reduxStatus !== "loading" ? (
          <FormikProvider value={formikMain}>
            <form onSubmit={handleSubmit} className="create-community-form">
              <Row>
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <FormikTextField
                    label="FLIGHT SETUP NAME"
                    autoComplete="off"
                    className="community-input"
                    error={Boolean(errors.name && touched.name) && errors.name}
                    placeholder="Enter your flight name"
                    {...getFieldProps("name")}
                  />
                </Col>
                <Col span={8}></Col>
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <Alert
                    message="Note: Kindly exercise caution while making updates to the flight as one or more events may be using this flight configuration."
                    type="info"
                  />
                </Col>
              </Row>
              <FormikRadioInputThreeFields
                label="SELECT TYPE"
                option1="Percentage"
                option2="HCP Values"
                value1="percentage"
                value2="hcp_value"
                option3="Inrange Flights"
                value3="inrange_flights"
                error={
                  Boolean(errors.flight_type && touched.flight_type) &&
                  errors.flight_type
                }
                {...getFieldProps("flight_type")}
              />
              <CounterInputField
                label={
                  <Text style={{ fontSize: 11, letterSpacing: 1 }}>
                    NO. OF FLIGHTS
                  </Text>
                }
                onDecrement={() => {
                  setFieldValue("number", values.number - 1);
                  if (updatedFlightsData.length > 1) {
                    updatedFlightsData.pop();
                    setFieldValue("flights", updatedFlightsData);
                  }
                }}
                onIncrement={() => {
                  setFieldValue("number", values.number + 1);
                  if (values.flight_type === "hcp_value") {
                    setFieldValue(
                      `flights[${values.number}].min_hcp`,
                      Number.isNaN(
                        values.flights[values.number - 1].max_hcp + 0.01
                      )
                        ? ""
                        : Number(values.flights[values.number - 1].max_hcp) +
                            0.01
                    );
                  }
                }}
                onInputClick={() => {
                  if (values.flight_type === "hcp_value") {
                    setFieldValue(
                      `flights[${values.number}].min_hcp`,
                      Number.isNaN(
                        values.flights[values.number - 1].max_hcp + 0.01
                      )
                        ? ""
                        : Number(values.flights[values.number - 1].max_hcp) +
                            0.01
                    );
                  }
                }}
                decrementDisabled={values.number === 1}
                value={values.number}
                onInputChange={(val: any) => setFieldValue("number", val)}
              />
              <div className="rewards-table-wrapper">
                <div className="table-columns">
                  <Text className="column">Flight Name</Text>
                  {values.flight_type === "hcp_value" && (
                    <>
                      <Text className="column">Min HCP</Text>
                      <Text className="column">Max HCP</Text>
                    </>
                  )}
                  {values.flight_type === "percentage" && (
                    <Text className="column">Percentage</Text>
                  )}
                  {values.flight_type === "inrange_flights" && (
                    <>
                      <Text className="column">Gender</Text>
                      <Text className="column">Min HCP</Text>
                      <Text className="column">Max HCP</Text>
                    </>
                  )}
                </div>
                {[...Array(values.number)].map((cur, index: number) => {
                  return (
                    <div key={index} className="rewards-table-row">
                      <div className="input-container">
                        <Field
                          {...getFieldProps(`flights.${index}.name`)}
                          onClick={() => setIsError(false)}
                        />
                      </div>
                      {values.flight_type === "hcp_value" && (
                        <>
                          <div className="input-container">
                            <Field
                              {...getFieldProps(`flights.${index}.min_hcp`)}
                              onClick={() => setIsError(false)}
                              disabled={index !== 0}
                            />
                            <div className="error-wrapper">
                              <ErrorMessage name={`flights.${index}.min_hcp`} />
                            </div>
                          </div>

                          <div className="input-container">
                            <Field
                              {...getFieldProps(`flights.${index}.max_hcp`)}
                              //For auto incrementing next min_hcp value by 0.01
                              //based on previuos max_hcp value
                              onBlur={() =>
                                setFieldValue(
                                  `flights.${index + 1}.min_hcp`,
                                  index + 1 < values.number
                                    ? Number.isNaN(
                                        Number(values.flights[index]?.max_hcp) +
                                          0.01
                                      )
                                      ? ""
                                      : Number(values.flights[index]?.max_hcp) +
                                        0.01
                                    : undefined
                                )
                              }
                              onClick={() => setIsError(false)}
                            />
                            <div className="error-wrapper">
                              <ErrorMessage name={`flights.${index}.max_hcp`} />
                            </div>
                          </div>
                        </>
                      )}
                      {values.flight_type === "percentage" && (
                        <div className="input-container">
                          <Field
                            {...getFieldProps(`flights.${index}.percentage`)}
                            onClick={() => setIsError(false)}
                          />
                          <div className="error-wrapper">
                            <ErrorMessage
                              name={`flights.${index}.percentage`}
                            />
                          </div>
                        </div>
                      )}

                      {values.flight_type === "inrange_flights" && (
                        <div className="input-container">
                          <Field
                            as="select"
                            {...getFieldProps(`flights[${index}].gender`)}
                            style={{ padding: 0 }}
                            onChange={(e: any) => {
                              const selectedFlightsGender = e.target.value;
                              setFieldValue(
                                `flights[${index}].gender`,
                                selectedFlightsGender
                              );
                            }}
                          >
                            <option value="" selected disabled hidden>
                              Select Gender
                            </option>
                            {GENDER_OPTIONS?.map((gender: any) => (
                              <option key={gender.id} value={gender.value}>
                                {gender.name}
                              </option>
                            ))}
                          </Field>
                        </div>
                      )}
                      {values.flight_type === "inrange_flights" && (
                        <>
                          <div className="input-container">
                            <Field
                              {...getFieldProps(`flights.${index}.min_hcp`)}
                              onClick={() => setIsError(false)}
                            />
                            <div className="error-wrapper">
                              <ErrorMessage name={`flights.${index}.min_hcp`} />
                            </div>
                          </div>

                          <div className="input-container">
                            <Field
                              {...getFieldProps(`flights.${index}.max_hcp`)}
                              onClick={() => setIsError(false)}
                              //For auto incrementing next min_hcp value by 0.01
                              //based on previuos max_hcp value
                            />
                            <div className="error-wrapper">
                              <ErrorMessage name={`flights.${index}.max_hcp`} />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  );
                })}
                {isError && (
                  <p style={{ color: "red", fontSize: 11 }}>
                    Enter All Flight Fields
                  </p>
                )}
                {formikMain.errors.flights?.length &&
                  values.flight_type === "hcp_value" && (
                    <p style={{ color: "red", fontSize: 11, textAlign: "end" }}>
                      Max HCP must &gt; Min HCP
                    </p>
                  )}
                {values.flight_type === "percentage" && (
                  <div className="total-percentage">
                    Total Percentage:{" "}
                    {Number.isNaN(flightsSum) ? 0 : flightsSum}%
                  </div>
                )}
                {values.flight_type === "percentage" && flightsSum !== 100 && (
                  <div className="percentage-error">
                    Total Flights Percentage should be = 100%
                  </div>
                )}
              </div>
              <Space direction="horizontal">
                <SaveBtn
                  text="Save Changes"
                  disabled={isSubmitting}
                  loading={isSubmitting}
                />
                <CancelBtn link="/admin/flights" />
              </Space>
            </form>
          </FormikProvider>
        ) : (
          <Spinner />
        )}
      </div>
    </MainLayout>
  );
};

export default EditFlight;
