// A "slice" is a collection of Redux reducer logic and actions for a single feature in your app,
// typically defined together in a single file. The name comes from splitting up the root Redux
// state object into multiple "slices" of state.

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { toast } from 'react-toastify';
import {
  createEventApi,
  deleteEventApi,
  fetchEventApi,
  getSingleEvent,
  updateEventApi,
} from './API/eventsApi';

// You need to create a slice first, under the features folder
// that slice should have a createSlice() function and the intial state provided to it
// The slice name is also required, hence give the name: param a proper string

const initialState = {
  status: 'idle',
  data: {},
  events: [],
};

// Writing a thunk for creating events
export const createEvent = createAsyncThunk(
  'events/create',
  async (data: any, thunkAPI) => {
    const response = await createEventApi(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500 || response.data.status === 422) {
      // notify the user error
      notification.error({
        message:
          response.data.data.status === 500
            ? response.data.data.error
            : response.data.status === 422
            ? response.data.data.message
            : null,
      });
    } else if (response.data) {
      // notify the user he has successfully created the event
      toast.success(response.data.data.message, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

//fetching events slice
export const fetchEvents = createAsyncThunk(
  'events/get',
  async (data: any, thunkAPI) => {
    const response = await fetchEventApi(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user error
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully created the event
      toast.success('List of events fetched successfully', {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (response.data.status === 401) {
      notification.error({
        message: `${response.data.statusText} Please Login Again`,
      });
    }
    return response.data;
  }
);

export const fetchEvent = createAsyncThunk(
  'event/get',
  async (data: any, thunkAPI) => {
    const response = await getSingleEvent(data.id);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user error
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully created the event
      toast.success('Event fetched successfully', {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

// For updating the event
export const updateEvent = createAsyncThunk(
  'event/update',
  async (data: any, thunkAPI) => {
    const response = await updateEventApi(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500 || response.data.status === 422) {
      // notify the user error
      toast.error(
        response.data.data.status === 500
          ? response.data.data.error
          : response.data.status === 422
          ? response.data.data.message
          : null,
        {
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } else if (response.data.data.success) {
      // notify the user he has successfully created the event
      toast.success('Event updated successfully', {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

export const deleteEvent = createAsyncThunk(
  'events/delete',
  async (id: any, thunkAPI) => {
    const response = await deleteEventApi(id);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully created the event
      toast.success('Event deleted successfully', {
        position: 'top-right',

        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

const eventsSlice = createSlice({
  name: 'events',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createEvent.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchEvents.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchEvent.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteEvent.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateEvent.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createEvent.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data = action.payload.data;
      })
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.status = 'idle';
        state.events = action.payload.data;
      })
      .addCase(fetchEvent.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data = action.payload.data;
      })
      .addCase(deleteEvent.fulfilled, (state, action) => {
        state.status = 'idle';
        state.events = action.payload.data;
      })
      .addCase(updateEvent.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data = action.payload.data;
      })
      .addCase(createEvent.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchEvents.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchEvent.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(deleteEvent.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(updateEvent.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

// Here we are exporting the actions that the component/button will need
// eslint-disable-next-line no-empty-pattern
export const {} = eventsSlice.actions;

// please look carefully at the below line, it returns a reducer object that's why it gets added in the store.
export default eventsSlice.reducer;
