// A "slice" is a collection of Redux reducer logic and actions for a single feature in your app,
// typically defined together in a single file. The name comes from splitting up the root Redux
// state object into multiple "slices" of state.

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { toast } from 'react-toastify';

import { createPartnerBrandApi, deletePartnerBrandApi, fetchPartnerBrandApi, fetchPartnerBrandsApi, updatePartnerBrandApi } from './API/partnerBrandsApi';

// You need to create a slice first, under the features folder
// that slice should have a createSlice() function and the intial state provided to it
// The slice name is also required, hence give the name: param a proper string

const initialState = {
  status: 'idle',
  data: {},
  partnerBrands: [],
};

// Writing a thunk for creating partner brands
export const createPartnerBrand = createAsyncThunk(
  'partnerBrands/create',
  async (data: any, thunkAPI) => {
    const response = await createPartnerBrandApi(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user he has successfully created the partner brand
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data) {
      // notify the user he has successfully created the partner brand
      toast.success("Partner Brand has been successfully created", {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

//fetching brands slice
export const fetchPartnerBrands = createAsyncThunk(
  'partnerBrands/get',
  async (data: any, thunkAPI) => {
    const response = await fetchPartnerBrandsApi(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user he has successfully fetched the brands
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully fetched the brands
      toast.success('List of brands fetched successfully', {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (response.data.status === 401) {
      notification.error({
        message: `${response.data.statusText} Please Login Again`,
      });
    }
    return response.data;
  }
);

export const fetchPartnerBrand = createAsyncThunk(
  'partnerBrand/get',
  async (data: any, thunkAPI) => {
    const response = await fetchPartnerBrandApi(data.id);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user he has successfully created the eventS
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully created the event
      toast.success('Event fetched successfully', {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

// For updating the partner brand
export const updatePartnerBrand = createAsyncThunk(
  'PartnerBrand/update',
  async (data: any, thunkAPI) => {
    const response = await updatePartnerBrandApi(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user he has successfully updated the brand
      toast.error(response.data.data.error, {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully updated the brand
      toast.success('Partner Brand updated successfully', {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

export const deletePartnerBrand = createAsyncThunk(
  'partnerBrand/delete',
  async (id: any, thunkAPI) => {
    const response = await deletePartnerBrandApi(id);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user he has successfully deleted the brand
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully deleted the brand
      toast.success('Brand deleted successfully', {
        position: 'top-right',

        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

const partnerBrandsSlice = createSlice({
  name: 'partnerBrands',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createPartnerBrand.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPartnerBrands.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPartnerBrand.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deletePartnerBrand.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updatePartnerBrand.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createPartnerBrand.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data = action.payload.data;
      })
      .addCase(fetchPartnerBrands.fulfilled, (state, action) => {
        state.status = 'idle';
        state.partnerBrands = action.payload.data;
      })
      .addCase(fetchPartnerBrand.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data = action.payload.data;
      })
      .addCase(deletePartnerBrand.fulfilled, (state, action) => {
        state.status = 'idle';
        state.partnerBrands = action.payload.data;
      })
      .addCase(updatePartnerBrand.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data = action.payload.data;
      })
      .addCase(createPartnerBrand.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchPartnerBrands.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchPartnerBrand.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(deletePartnerBrand.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(updatePartnerBrand.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

// Here we are exporting the actions that the component/button will need
// eslint-disable-next-line no-empty-pattern
export const { } = partnerBrandsSlice.actions;

// please look carefully at the below line, it returns a reducer object that's why it gets added in the store.
export default partnerBrandsSlice.reducer;
