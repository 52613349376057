import { FormikSelect, FormikTextField } from '@components/inputFeilds/InputFeilds';
import React, { useEffect, useState } from 'react';
import { MainLayout } from '@components/layouts';
import * as yup from 'yup';
import {
  Button,
  Col,
  Divider,
  Image,
  Row,
  Space,
  Spin,
  Typography,

} from 'antd';
import { FormikProvider, useFormik } from 'formik';
import FacebookLogo from '@assets/images/facebook.png';
import InstaLogo from '@assets/images/instagram.png';
import YoutubeLogo from '@assets/images/youtube.png';
import TikTokLogo from '@assets/images/tiktok.png';
import WebsiteLogo from '@assets/images/website.png';
import { useDispatch, useSelector } from 'react-redux';
import { editPost, fetchPost } from '@slices/postsSlice';
import { AppDispatch } from '@config/storeConfig';
import { Link, useNavigate, useParams } from 'react-router-dom';
import '../styles.css';
import { toast } from 'react-toastify';
import { Slide } from 'react-toastify';
import ImageUpload from '@components/imageUpload/ImageUpload';

const { Text } = Typography;

const EditPost = () => {
  const params = useParams();
  const livePostsState = useSelector((state: any) => state.posts.data.data);
  const [logoBase, setLogoBase] = useState<any>('');
  const [previewLogo, setPreviewLogo] = useState(true);
  const reduxStatus = useSelector((state: any) => state.posts.status);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const currentPostId = params?.id;





  const formikMain = useFormik({
    initialValues: {
      heading: livePostsState?.heading ? livePostsState.heading : '',
      link: livePostsState?.link ? livePostsState.link : '',
      website: livePostsState?.website ? livePostsState.website : '',
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      setSubmitting(true);
      const postsData = {
        heading: values.heading,
        link: values.link,
        website: values.website
      };
      const logoParam = Boolean(logoBase);
      if (logoParam) {
        const addLogo = { post_image: logoBase };
        await handleEditPostFormSubmit({
          ...postsData,
          ...addLogo,
        });
      } else {

        await handleEditPostFormSubmit(postsData);
      }
      setSubmitting(false);
      resetForm();
      navigate("/admin/posts");
    },
    validationSchema: yup.object({
      heading: yup.string().required('*Required post heading'),
      link: yup.string().required('*Required link'),
      website: yup.string().required('*Select  Website'),
    }),
  });

  const {
    errors,
    getFieldProps,
    handleSubmit,
    setSubmitting,
    isSubmitting,
    resetForm,
    touched,
    values,
    setFieldValue,
  } = formikMain;

  useEffect(() => {
    //Fetch the partner brand right here and populate the values
    // use current id currentPartnerBrandId
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(fetchPost({ id: currentPostId }));
  }, [currentPostId, dispatch]);

  useEffect(() => {
    toast.info(
      <>
        <p>Edit your Post here!</p>
      </>,
      {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
        transition: Slide,
        style: { minWidth: 400 },
      }
    );
  }, []);

  const handleEditPostFormSubmit = async (argo: any) => {
    await dispatch(editPost({ id: params.id, postsData: argo }));
  };

  return (
    <MainLayout>
      <div className="community-container">
        <Row>
          <Col xl={20} sm={24}>
            <Divider orientation="left" orientationMargin="0">
              <Text className="community-heading">Edit Post</Text>
            </Divider>
          </Col>
        </Row>

        {reduxStatus !== 'loading' ? (
          <FormikProvider value={formikMain}>
            <form onSubmit={handleSubmit}>
              <Row>
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <FormikTextField
                    label="POST HEADING"
                    className="community-input"
                    error={Boolean(errors.heading && touched.heading) && errors.heading}
                    autoComplete="off"
                    placeholder="Enter your post heading"
                    {...getFieldProps('heading')}
                  />
                </Col>
                <Col span={8}></Col>
                <Col span={8}></Col>
              </Row>
              <Row>
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <FormikTextField
                    label="POST LINK"
                    className="community-input"
                    error={
                      Boolean(errors.link && touched.link) &&
                      errors.link
                    }
                    autoComplete="off"
                    placeholder="Enter link"
                    {...getFieldProps('link')}
                  />
                </Col>
                <Col span={8}></Col>
                <Col span={8}></Col>
              </Row>
              <Row>
                <Col xl={8} lg={10} md={12} sm={24} xs={24} onClick={() => setPreviewLogo(false)}>
                  <Text className="poster-text">POST IMAGE</Text>
                  <ImageUpload
                    logoBase={logoBase}
                    setLogoBase={setLogoBase}
                  />

                </Col>
                <Col xl={8} lg={10} md={12} sm={24} xs={24} className="preview-img">
                  {previewLogo && (livePostsState &&
                    <Image
                      style={{
                        width: 80,
                        height: 80,
                        objectFit: 'contain',
                        marginRight: 10,
                        background:
                          livePostsState?.post_image === null
                            ? '#141879'
                            : 'unset',
                      }}
                      src={
                        livePostsState?.post_image === null
                          ? values.website === 'Facebook'
                            ? FacebookLogo
                            : values.website === 'Youtube'
                              ? YoutubeLogo
                              : values.website === 'Instagram'
                                ? InstaLogo
                                : values.website === 'TikTok'
                                  ? TikTokLogo
                                  : WebsiteLogo
                          : livePostsState.post_image
                      }
                    />
                  )}
                </Col>
                <Col span={8}></Col>
              </Row>
              <Row>
                <Col xl={4} lg={5} sm={12} xs={24}>
                  <FormikSelect
                    label="SELECT SITE"
                    style={{
                      marginTop: 36,
                    }}
                    autoComplete="off"
                    error={
                      Boolean(errors.website && touched.website) &&
                      errors.website
                    }
                    setFieldValue={setFieldValue}
                    fieldValue="website"
                    options={['Facebook', 'Youtube', 'TikTok', 'Instagram', 'Website']}
                    placeholder="Select website"
                    {...getFieldProps('website')}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Space direction="horizontal">
                    <Button
                      disabled={isSubmitting}
                      loading={isSubmitting}
                      type="primary"
                      htmlType="submit"
                      className="primary-btn"
                    >
                      Save Changes
                    </Button>
                    <Link to="/admin/posts">
                      <Button
                        type="link"
                        size={'middle'}
                        style={{ color: 'gray', marginTop: 20 }}
                      >
                        Cancel
                      </Button>
                    </Link>

                  </Space>
                </Col>
              </Row>
            </form>
          </FormikProvider>
        ) : (
          <Row>
            <Col
              span={24}
              style={{
                paddingTop: 100,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Spin />
            </Col>
          </Row>
        )}
      </div>
    </MainLayout>
  );
};

export default EditPost;
