import { message, Space, Upload, UploadProps, Typography, Button } from 'antd';
import { toast } from 'react-toastify';
import Dragger from 'antd/lib/upload/Dragger';
import { CloudUploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { Slide } from 'react-toastify';
const { Text } = Typography




const CSVUpload = ({ csv, setCsv }: any) => {


  // Function for reading csv from system
  const getFile = (file: any, cb: Function) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    setCsv(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.warn('Error: ', error);
    };
  };

  const removeCsv = () => {
    toast.error(
      <>
        <p>File removed</p>
      </>,
      {
        position: 'bottom-right',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
        transition: Slide,
        style: { minWidth: 400 },
      }
    );
    setCsv('');
  };




  const props: UploadProps = {
    showUploadList: false,
    beforeUpload(file, FileList) {
      const isCsv = (file.type === 'text/csv' || file.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || file.type === 'application/vnd.ms-excel');
      console.log(file.type)
      if (!isCsv) {
        message.error(`${file.name} is not a csv/excel file`);
      }
      return isCsv || Upload.LIST_IGNORE;
    },
    onChange(info) {
      const { status, type } = info.file;
      if (status !== 'uploading' && (type === 'text/csv' || type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || type === 'application/vnd.ms-excel')) {
        getFile(info.file.originFileObj, () => { });
      }
    },
    onRemove: () => {
      removeCsv();
    },
  };

  return (
    <>
      <Dragger {...props} maxCount={1}>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
          }}
        >
          <div>
            <p className="ant-upload-text">
              Select a CSV/Excel file or drag and drop here
            </p>
            <p className="instructions">
              File with following headers only
            </p>
            <p className="instructions" style={{ fontWeight: 'bold', color: "black", marginTop: 10 }}>
              Code | Pin

            </p>
          </div>
          <div className="upload-container">
            <p>SELECT FILE</p>
            <CloudUploadOutlined />
          </div>
        </div>
      </Dragger>
      {csv && (
        <Space
          direction="horizontal"
          style={{
            justifyContent: 'space-between',
            width: '100%',
            paddingTop: 10,
          }}
        >
          <Space>
            <Text>{csv.name}</Text>
          </Space>
          <Button
            size="small"
            onClick={removeCsv}
            danger
            style={{ border: 'none' }}
          >
            <DeleteOutlined />
          </Button>
        </Space>
      )}
    </>

  )
}

export default CSVUpload;