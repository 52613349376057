// A "slice" is a collection of Redux reducer logic and actions for a single feature in your app,
// typically defined together in a single file. The name comes from splitting up the root Redux
// state object into multiple "slices" of state.

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notification } from "antd";



import { approveScoreApi, eventsSearchApi, fetchScoresApi, leaguesSearchApi, reApproveScoreApi, rejectScoreApi, updateScoresApi } from "./API/scoresApi";

import { toast } from "react-toastify";

// You need to create a slice first, under the features folder
// that slice should have a createSlice() function and the intial state provided to it
// The slice name is also required, hence give the name: param a proper string

const initialState = {
  status: "idle",
  data: {},
  scores: [],
  events: [],
  leagues: [],
};

//fetching scores slice
export const fetchScores = createAsyncThunk(
  "scores/get",
  async (data: any, thunkAPI) => {
    const response = await fetchScoresApi(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user he has successfully created the eventS
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully fetched the scores
      // toast.success("Scores are Fetched", {
      //   position: "top-right",
      //   autoClose: 2000,
      //   hideProgressBar: false,
      //   closeOnClick: true,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
    } else if (response.data.status === 401) {
      notification.error({
        message: `${response.data.statusText} Please Login Again`,
      });
    }
    return response.data;
  }
);

//Reject scores slice
export const rejectScore = createAsyncThunk(
  "scores/reject",
  async (data: any, thunkAPI) => {
    const response = await rejectScoreApi(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user he has successfully created the eventS
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully fetched the scores
      toast.info("Scores Rejected!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

// For updating the scores
export const updateScores = createAsyncThunk(
  'scores/update',
  async (data: any, thunkAPI) => {
    const response = await updateScoresApi(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user he has successfully updated the scores
      toast.error(response.data.data.error, {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully updated the scores
      toast.success('Score updated successfully', {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

//Reapprove scores slice
export const reApproveScore = createAsyncThunk(
  "scores/reapprove",
  async (data: any, thunkAPI) => {
    const response = await reApproveScoreApi(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user he has successfully reapproved scores
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully fetched the scores
      toast.info("Scores Reapproved!", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

//Approve scores slice
export const approveScore = createAsyncThunk(
  "scores/approve",
  async (data: any, thunkAPI) => {
    const response = await approveScoreApi(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user he has successfully approved scores
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully fetched the scores
      toast.info("Scores approved successfully", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

export const eventsSearch = createAsyncThunk(
  "events/search",
  async (data?: any) => {
    const response = await eventsSearchApi(data);
    if (response.data.data.status === 500) {

      notification.error({
        message: response.data.data.error,
      });
    }
    return response.data;
  }
)

export const leaguesSearch = createAsyncThunk(
  "leagues/search",
  async (data?: any) => {
    const response = await leaguesSearchApi(data);
    if (response.data.data.status === 500) {

      notification.error({
        message: response.data.data.error,
      });
    }
    return response.data;
  }
)

const scoresSlice = createSlice({
  name: "events",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchScores.pending, (state) => {
        state.status = "loading";
      })
      .addCase(rejectScore.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateScores.pending, (state) => {
        state.status = "loading";
      })
      .addCase(reApproveScore.pending, (state) => {
        state.status = "loading";
      })
      .addCase(approveScore.pending, (state) => {
        state.status = "loading";
      })
      .addCase(eventsSearch.pending, (state) => {
        state.status = "loading";
      })
      .addCase(leaguesSearch.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchScores.fulfilled, (state, action) => {
        state.status = "idle";
        state.data = action.payload.data;
      })
      .addCase(rejectScore.fulfilled, (state, action) => {
        state.status = "idle";
        state.data = action.payload.data;
      })
      .addCase(updateScores.fulfilled, (state, action) => {
        state.status = 'idle';
        state.scores = action.payload.data;
      })
      .addCase(reApproveScore.fulfilled, (state, action) => {
        state.status = "idle";
        state.data = action.payload.data;
      })
      .addCase(approveScore.fulfilled, (state, action) => {
        state.status = "idle";
        state.data = action.payload.data;
      })
      .addCase(eventsSearch.fulfilled, (state, action) => {
        state.status = "idle";
        state.events = action.payload.data;
      })
      .addCase(leaguesSearch.fulfilled, (state, action) => {
        state.status = "idle";
        state.leagues = action.payload.data;
      })
      .addCase(fetchScores.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(rejectScore.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updateScores.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(reApproveScore.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(approveScore.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(eventsSearch.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(leaguesSearch.rejected, (state) => {
        state.status = "failed";
      });
  },
});

// Here we are exporting the actions that the component/button will need
// eslint-disable-next-line no-empty-pattern
export const { } = scoresSlice.actions;

// please look carefully at the below line, it returns a reducer object that's why it gets added in the store.
export default scoresSlice.reducer;
