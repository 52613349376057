// A "slice" is a collection of Redux reducer logic and actions for a single feature in your app,
// typically defined together in a single file. The name comes from splitting up the root Redux
// state object into multiple "slices" of state.

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { toast } from 'react-toastify';
import {
  createSupportCategoryApi,
  deleteSupportCategoryApi,
  editSupportCategoryApi,
  fetchSingleSupportCategoryApi,
  fetchSupportCategoryApi,
} from './API/supportCategoryApi';

// You need to create a slice first, under the features folder
// that slice should have a createSlice() function and the intial state provided to it
// The slice name is also required, hence give the name: param a proper string

const initialState = {
  status: 'idle',
  data: {},
  supportCategory: [],
};

// Writing a thunk for creating support category
export const createSupportCategory = createAsyncThunk(
  'supportCategory/create',
  async (data: any, thunkAPI) => {
    const response = await createSupportCategoryApi(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user he has successfully created the support category
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data) {
      // notify the user he has successfully created the community
      toast.success(response.data.data.message, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

export const fetchSupportCategories = createAsyncThunk(
  'supportCategories/get',
  async (data: any, thunkAPI) => {
    const response = await fetchSupportCategoryApi(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user he has successfully fetched categories
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully fetched categories
      toast.success('List of categories fetched successfully', {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

//fetching single category
export const fetchSupportCategory = createAsyncThunk(
  'supportCategory/get',
  async (data: any, thunkAPI) => {
    const response = await fetchSingleSupportCategoryApi(data.id);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user he has successfully fetched the category
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully fetched the category
      toast.success('Support Category fetched successfully', {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

export const deleteSupportCategory = createAsyncThunk(
  'supportCategory/delete',
  async (id: any, thunkAPI) => {
    const response = await deleteSupportCategoryApi(id);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user he has successfully deleted the category
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully deleted the category
      toast.success('Support Category deleted successfully', {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

export const editSupportCategory = createAsyncThunk(
  'supportCategory/edit',
  async (data: any, thunkAPI) => {
    const response = await editSupportCategoryApi(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user he has successfully edited the category
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully edited the category
      toast.success('Support Category has been successfully updated', {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

const supportCategorySlice = createSlice({
  name: 'supportCategory',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSupportCategories.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createSupportCategory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteSupportCategory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(editSupportCategory.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSupportCategory.pending, (state) => {
        state.status = 'loading';
      })

      .addCase(fetchSupportCategories.fulfilled, (state, action) => {
        state.status = 'idle';
        state.supportCategory = action.payload.data;
      })
      .addCase(createSupportCategory.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data = action.payload.data;
      })
      .addCase(deleteSupportCategory.fulfilled, (state, action) => {
        state.status = 'idle';
        state.supportCategory = action.payload.data;
      })
      .addCase(editSupportCategory.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data = action.payload.data;
      })
      .addCase(fetchSupportCategory.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data = action.payload.data;
      })

      .addCase(fetchSupportCategories.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(createSupportCategory.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(deleteSupportCategory.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchSupportCategory.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(editSupportCategory.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

// Here we are exporting the actions that the component/button will need
// eslint-disable-next-line no-empty-pattern
export const { } = supportCategorySlice.actions;

// please look carefully at the below line, it returns a reducer object that's why it gets added in the store.
export default supportCategorySlice.reducer;
