import { Button } from 'antd';

const SaveBtn = ({ disabled, loading, text, ...props }) => {
  return (
    <Button
      disabled={disabled}
      loading={loading}
      style={{ marginTop: 20 }}
      type="primary"
      htmlType="submit"
      className="primary-btn"
    >
      {text}
    </Button>
  );
};

export default SaveBtn;
