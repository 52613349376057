import { FormikSelect, FormikTextField } from '@components/inputFeilds/InputFeilds';
import React, { useEffect } from 'react';
import { MainLayout } from '@components/layouts';
import {
  Button,
  Col,
  Divider,
  Image,
  Row,
  Space,
  Spin,
  Typography,
} from 'antd';
import { FormikProvider, useFormik } from 'formik';
import FacebookLogo from '@assets/images/facebook.png';
import InstaLogo from '@assets/images/instagram.png';
import YoutubeLogo from '@assets/images/youtube.png';
import TikTokLogo from '@assets/images/tiktok.png';
import WebsiteLogo from '@assets/images/website.png';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPost } from '@slices/postsSlice';
import { AppDispatch } from '@config/storeConfig';
import { Link, useParams } from 'react-router-dom';
import '../styles.css';
import { toast } from 'react-toastify';
import { Slide } from 'react-toastify';

const { Text } = Typography;

const ViewPost = () => {
  const params = useParams();
  const livePostsState = useSelector((state: any) => state.posts.data.data);

  const reduxStatus = useSelector((state: any) => state.posts.status);
  const dispatch = useDispatch<AppDispatch>();

  const formikMain = useFormik({
    initialValues: {
      heading: livePostsState?.heading ? livePostsState.heading : '',
      link: livePostsState?.link ? livePostsState.link : '',
      website: livePostsState?.website ? livePostsState.website : '',
    },
    enableReinitialize: true,
    onSubmit: () => { },
  });

  const {
    getFieldProps,
    handleSubmit,
    values,
    setFieldValue,
  } = formikMain;

  useEffect(() => {
    dispatch(fetchPost({ id: params.id }));
    toast.info(
      <>
        <p>View your Post here!</p>
      </>,
      {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
        transition: Slide,
        style: { minWidth: 400 },
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout>
      <div className="community-container">
        <Row>
          <Col xl={20} sm={24}>
            <Divider orientation="left" orientationMargin="0">
              <Text className="community-heading">View Post</Text>
            </Divider>
          </Col>
        </Row>

        {reduxStatus !== 'loading' ? (
          <FormikProvider value={formikMain}>
            <form onSubmit={handleSubmit}>
              <Row>
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <FormikTextField
                    label="POST HEADING"
                    className="community-input"
                    disabled
                    autoComplete="off"
                    placeholder="Enter your post heading"
                    {...getFieldProps('heading')}
                  />
                </Col>
                <Col span={8}></Col>
                <Col span={8}></Col>
              </Row>
              <Row>
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <FormikTextField
                    label="POST LINK"
                    className="community-input"
                    disabled
                    autoComplete="off"
                    placeholder="Enter link"
                    {...getFieldProps('link')}
                  />
                </Col>
                <Col span={8}></Col>
                <Col span={8}></Col>
              </Row>
              {livePostsState && (
                <>
                  <Text className="poster-text" style={{ display: 'block' }}>
                    POST IMAGE
                  </Text>
                  <Image
                    style={{ width: 100, height: 100, objectFit: 'contain' }}
                    src={
                      livePostsState?.post_image === null
                        ? values.website === 'Facebook'
                          ? FacebookLogo
                          : values.website === 'Youtube'
                            ? YoutubeLogo
                            : values.website === 'Instagram'
                              ? InstaLogo
                              : values.website === 'TikTok'
                                ? TikTokLogo
                                : WebsiteLogo
                        : livePostsState.post_image
                    }
                  />
                </>
              )}
              <Row>
                <Col xl={4} lg={5} sm={12} xs={24}>
                  <FormikSelect
                    label="SELECT SITE"
                    style={{
                      marginTop: 36,
                    }}
                    autoComplete="off"
                    disabled
                    setFieldValue={setFieldValue}
                    fieldValue="website"
                    options={['Facebook', 'Youtube', 'TikTok', 'Instagram', 'Website']}
                    placeholder="Select website"
                    {...getFieldProps('website')}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Space direction="horizontal">
                    <Link to={`/admin/edit/post/${params.id}`}>
                      <Button
                        type="primary"
                        htmlType="submit"
                        className="primary-btn"
                      >
                        Edit Post
                      </Button>
                    </Link>
                    <Link to="/admin/posts">
                      <Button
                        type="link"
                        size={'middle'}
                        style={{ color: 'gray', marginTop: 24 }}
                      >
                        Cancel
                      </Button>
                    </Link>
                  </Space>
                </Col>
              </Row>
            </form>
          </FormikProvider>
        ) : (
          <Row>
            <Col
              span={24}
              style={{
                paddingTop: 100,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Spin />
            </Col>
          </Row>
        )}
      </div>
    </MainLayout>
  );
};

export default ViewPost;
