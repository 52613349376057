import { FC, useLayoutEffect, useState } from "react";
import { BrowserHistory } from "history";
import { Router, BrowserRouterProps } from "react-router-dom";

interface CustomRouterProps extends BrowserRouterProps {
  history: BrowserHistory;
}

const CustomRouter: FC<CustomRouterProps> = ({ history, ...props }) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => {
    const unlisten = history.listen(setState);
    return () => {
      unlisten();
    };
  }, [history]);

  return (
    <Router
      {...props}
      location={state.location}
      navigationType={state.action}
      navigator={history}
    />
  );
};

export default CustomRouter;
