import {
  FormikTextField,
  FormikDatePicker,
  FormikRadioInputThreeFields,
} from '@components/inputFeilds/InputFeilds';
import { MainLayout } from '@components/layouts';
import { Spin, Table } from 'antd';
import { Button, Col, Divider, Row, Space, Typography } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch } from '@config/storeConfig';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Slide } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { fetchGiftCard } from '@slices/giftCardsSlice';





const { Text } = Typography;


const ViewGiftCard = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const params = useParams();
  const currentGiftCardId = params?.id;
  const liveGiftCardState = useSelector((state: any) => state.giftCards.data.data);
  const reduxStatus = useSelector((state: any) => state.giftCards.status);





  const formikMain = useFormik({
    initialValues: {
      name: liveGiftCardState && liveGiftCardState.name ? liveGiftCardState.name : "",
      title: liveGiftCardState && liveGiftCardState.title ? liveGiftCardState.title : "",
      gift_card_code: liveGiftCardState && liveGiftCardState.code ? liveGiftCardState.code : undefined,
      external_website_link: liveGiftCardState && liveGiftCardState.external_website_link ? liveGiftCardState.external_website_link : "",
      instructions: liveGiftCardState && liveGiftCardState.instructions ? liveGiftCardState.instructions : "",
      smash_cash: liveGiftCardState && liveGiftCardState.smash_cash ? liveGiftCardState.smash_cash : "",
      validity: liveGiftCardState && liveGiftCardState.validity ? liveGiftCardState.validity : "",
      quantity: liveGiftCardState && liveGiftCardState.quantity ? liveGiftCardState.quantity : "",
      partner_brand_id: liveGiftCardState && liveGiftCardState.partner_brand_id ? liveGiftCardState.partner_brand_id : "",
      creation_type: liveGiftCardState && liveGiftCardState.creation_type ? liveGiftCardState.creation_type : "",
      format: liveGiftCardState && liveGiftCardState.format ? liveGiftCardState.format : "",
      pin: liveGiftCardState && liveGiftCardState.pin ? liveGiftCardState.pin : undefined,
      csv_file: undefined,
      value: liveGiftCardState && liveGiftCardState.value ? liveGiftCardState.value : undefined,
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      setSubmitting(true);

    },

  });

  const {
    getFieldProps,
    handleSubmit,
    values,
    setFieldValue,
    setSubmitting,
  } = formikMain;





  useEffect(() => {
    //Fetch the gift card right here and populate the values
    // use current id currentGiftCardtId
    dispatch(fetchGiftCard({ id: currentGiftCardId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    toast.info(
      <>
        <p>View your gift card here!</p>
      </>,
      {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
        transition: Slide,
        style: { minWidth: 400 },
      }
    );
  }, []);





  const onEditGiftCard = (id: any) => {
    //Redirect to edit gift card for that record
    navigate(`/admin/edit/gift-card/${id}`)

  };

  const codesPlusPinsCol = [
    {
      title: 'S.No',
      key: 's.no',
      width: '20%',
      render: (val: any, record: any, ind: any) => ind + 1

    },
    {
      title: 'Codes',
      dataIndex: 'code',
      key: 'code',
      width: '40%',
    },
    {
      title: 'PIN',
      dataIndex: 'pin',
      key: 'pin',
      width: '40%',
    },
  ]

  const codesCol = [
    {
      title: 'S.No',
      key: 's.no',
      width: '20%',
      render: (val: any, record: any, ind: any) => ind + 1

    },
    {
      title: 'Codes',
      dataIndex: 'code',
      key: 'code',
      width: '40%',
    },

  ]

  const pinsCol = [
    {
      title: 'S.No',
      key: 's.no',
      width: '20%',
      render: (val: any, record: any, ind: any) => ind + 1

    },
    {
      title: 'PIN',
      dataIndex: 'pin',
      key: 'pin',
      width: '40%',
    },
  ]

  return (
    <MainLayout>
      <div className="community-container">
        <Row>
          <Col xl={20} sm={24}>
            <Divider orientation="left" orientationMargin="0">
              <Text className="community-heading">View Gift Card</Text>
            </Divider>
          </Col>
        </Row>
        {reduxStatus !== 'loading' ? <FormikProvider value={formikMain}>
          <form onSubmit={handleSubmit} className="create-community-form">
            <Row>
              <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                <FormikTextField
                  label="VENDOR COMPANY"
                  className="community-input"
                  disabled
                  placeholder="Enter heading"
                  {...getFieldProps("name")}
                />
              </Col>
              <Col span={8}></Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                <FormikTextField
                  label="GIFT CARD HEADING"
                  className="community-input"
                  disabled
                  placeholder="Enter heading"
                  {...getFieldProps("title")}
                />
              </Col>
              <Col span={8}></Col>
              <Col span={8}></Col>
            </Row>
            {values.value !== undefined &&
              <Row>
                <Col xl={4} >
                  <FormikTextField
                    label="GIFT CARD VALUE"
                    className="community-input"
                    disabled
                    placeholder="$Value"
                    {...getFieldProps("value")}
                  />
                </Col>
                <Col span={8}></Col>
                <Col span={8}></Col>
              </Row>
            }
            <Row>
              <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                <FormikTextField
                  label="VENDOR LINK"
                  className="community-input"
                  disabled
                  placeholder="Enter link here"
                  {...getFieldProps("external_website_link")}
                />
              </Col>
              <Col span={8}></Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col
                xl={8}
                lg={10}
                md={12}
                sm={24}
                xs={24}
                style={{ marginBottom: 20 }}
              >
                <Text className="poster-text" style={{ marginBottom: 20 }}>
                  INSTRUCTIONS
                </Text>
                <div
                  className="view-rules"
                  dangerouslySetInnerHTML={{ __html: values.instructions }}
                />
              </Col>
              <Col span={8}></Col>
              <Col span={8}></Col>
            </Row>


            <Row gutter={8}>
              <Col xl={3} lg={4} md={4} sm={8}>
                <FormikTextField
                  label="SMASH CASH"
                  className="community-input"
                  disabled
                  placeholder="Enter smash cash"
                  {...getFieldProps("smash_cash")}
                />
              </Col>
              <Col xl={3} lg={4} md={4} sm={8}>
                <FormikDatePicker
                  label="VALIDITY"
                  showTime
                  style={{ marginBottom: 36 }}
                  format="MM-DD-YYYY"
                  autoComplete="off"
                  setFieldValue={setFieldValue}
                  fieldValue="validity"
                  disabled
                  {...getFieldProps("validity")}
                />
              </Col>
            </Row>
            <FormikRadioInputThreeFields
              label="GIFT CARD TYPE"
              style={{
                marginBottom: 36,
                textTransform: 'capitalize',
              }}
              option1="Code Only"
              option2="Pin Only"
              option3="Code + Pin"
              value1="code"
              value2="pin"
              value3="code_and_pin"
              autoComplete="off"
              disabled
              {...getFieldProps('format')}
            />
            <FormikRadioInputThreeFields
              label="NO. OF GIFT CARD CODES"
              style={{
                marginBottom: 36,
                textTransform: 'capitalize',
              }}
              option1="Single"
              option2="Many (Bulk Upload)"
              value1="single"
              value2="multiple"
              autoComplete="off"
              disabled
              {...getFieldProps('creation_type')}
            />
            {((values.format === "code" && values.creation_type === "single") || (values.format === "code_and_pin" && values.creation_type === "single")) &&

              <Row>
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <FormikTextField
                    label="GIFT CARD CODE"
                    className="community-input uppercase"
                    autoComplete="off"
                    disabled
                    placeholder="Enter code"
                    {...getFieldProps("gift_card_code")}
                  />
                </Col>
                <Col span={8}></Col>
                <Col span={8}></Col>
              </Row>
            }
            {((values.format === "pin" && values.creation_type === "single") || (values.format === "code_and_pin" && values.creation_type === "single")) &&

              <Row>
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <FormikTextField
                    label="GIFT CARD PIN"
                    className="community-input uppercase"
                    autoComplete="off"
                    disabled
                    placeholder="Enter pin"
                    {...getFieldProps("pin")}
                  />
                </Col>
                <Col span={8}></Col>
                <Col span={8}></Col>
              </Row>
            }
            {values.creation_type === "single" &&
              <Row>
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <FormikTextField
                    label="QUANTITY"
                    className="community-input"
                    autoComplete="off"
                    disabled
                    placeholder="Enter quantity"
                    {...getFieldProps("quantity")}
                  />
                </Col>
              </Row>
            }
            {values.creation_type === "multiple" &&
              <Row className="view-vouchers-table">
                <Col xl={12} lg={10} md={12} sm={24} xs={24}>
                  <Text className="poster-text">VOUCHERS</Text>
                  <Table
                    columns={liveGiftCardState.format === "code"
                      ? codesCol :
                      liveGiftCardState.format === "pin" ?
                        pinsCol : codesPlusPinsCol}


                    dataSource={liveGiftCardState.vouchers}
                    pagination={false}
                  />

                </Col>
              </Row>
            }
            <Row>
              <Space direction="horizontal">
                <Col>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="create-event-btn"
                    onClick={() => {
                      onEditGiftCard(params.id);
                    }}
                  >
                    Edit Gift Card
                  </Button>
                </Col>
                <Link to="/admin/gift-cards">
                  <Button
                    type="link"
                    size={'middle'}
                    style={{ color: 'gray', marginTop: 18 }}
                  >
                    Cancel
                  </Button>
                </Link>
              </Space>
            </Row>
          </form>
        </FormikProvider> :
          <Row>
            <Col span={24} style={{ paddingTop: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Spin />
            </Col>
          </Row>}
      </div>
    </MainLayout>
  );
};

export default ViewGiftCard;