import { FormikRadioInput, FormikTextField } from '@components/inputFeilds/InputFeilds';
import React, { forwardRef, useEffect, } from "react";
import { ReactComponent as DropDownOutlined } from '@assets/icons/dropdown-icon.svg';
import { MainLayout } from '@components/layouts';
import { Button, Col, Divider, Row, Select, Space, Spin, Typography } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "@config/storeConfig";
import { Slide, toast } from "react-toastify";
import '../styles.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { editTopTracer, fetchTopTracer } from '@slices/topTracersSlice';
import { fetchUSStates } from '@slices/USStatesSlice';
import Item from 'antd/lib/list/Item';
import { Option } from 'antd/lib/mentions';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const { Text } = Typography;

const EditTopTracer = () => {

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const params = useParams();
  const currentTopTracerId = params?.id;
  const liveTopTracerState = useSelector((state: any) => state.topTracers.data.data);
  const reduxStatus = useSelector((state: any) => state.topTracers.status);
  const sateNamesFromRedux = useSelector(
    (state: any) => state.USStates.USStates.data
  );


  const stateNameOptions =
    sateNamesFromRedux &&
    sateNamesFromRedux.map((state: any) => ({
      name: state.name,
      id: state.id,
    }));

  const formikMain = useFormik({
    initialValues: {
      name: liveTopTracerState?.name ? liveTopTracerState.name : '',
      website: liveTopTracerState?.website ? liveTopTracerState.website : '',
      address: liveTopTracerState?.address ? liveTopTracerState.address : '',
      state_id: liveTopTracerState?.state_id ? liveTopTracerState.state_id : '',
      phone: liveTopTracerState?.phone ? liveTopTracerState.phone : '',
      device_support: liveTopTracerState?.device_support ? liveTopTracerState.device_support : '',
    },
    onSubmit: async (values) => {
      setSubmitting(true);
      const topTracerData = {
        name: values.name,
        website: values.website,
        address: values.address,
        state_id: values.state_id,
        phone: values.phone,
        device_support: values.device_support,
      };
      await handleEditTopTracerFormSubmit(topTracerData);
      setSubmitting(false);
      resetForm();
      navigate("/admin/top-tracer");
    },
    enableReinitialize: true,
    validationSchema: yup.object({
      name: yup.string().required('*Required Toptracer Name'),
      website: yup.string().matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        'Enter correct Website!'
      ).required('*Required Website'),
      address: yup.string().required('*Required Address'),
      state_id: yup.string().required('*Select a State'),
      //phone: yup.string().required('*Required No'),
      device_support: yup.string().required('*Required Device Support'),
    }),
  });

  const {
    errors,
    getFieldProps,
    handleSubmit,
    touched,
    values,
    isSubmitting,
    setSubmitting,
    setFieldValue,
    resetForm,
  } = formikMain;

  const FormikStatesSelect = forwardRef(
    (
      {
        error,
        label,
        name,
        onChange,
        onBlur,
        placeholder,
        value,
        options,
        style,
        setFieldValue,
        ...props
      }: any,
      ref
    ) => (
      <Item
        style={style}
        {...(Boolean(error) && { validateStatus: 'error', help: error })}
      >
        {!!label && <p className="text-field-label">{label}</p>}

        <Select
          className="form-select-field-custom"
          suffixIcon={<DropDownOutlined />}
          placeholder={placeholder}
          ref={ref}
          value={value}
          onChange={(value) => {
            setFieldValue('state_id', value);
          }}
          {...props}
        >
          {options &&
            options.map((state: any, ind: any) => (
              <Option key={ind} value={state.id}>
                {state.name}
              </Option>
            ))}
        </Select>
      </Item>
    )
  );


  const handleEditTopTracerFormSubmit = async (argo: any) => {
    await dispatch(editTopTracer({ id: params.id, editedData: argo }));
  };

  useEffect(() => {
    //Fetch the topTracer right here and populate the values
    // use current id 
    window.scrollTo({ top: 0, behavior: 'smooth' });
    dispatch(fetchTopTracer({ id: currentTopTracerId }));
  }, [currentTopTracerId, dispatch]);


  useEffect(() => {
    dispatch(fetchUSStates())
    toast.info(
      <>
        <p>Edit your Toptracer right here</p>
      </>,
      {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
        transition: Slide,
        style: { minWidth: 400 },
      }
    );
  }, []);

  return (
    <MainLayout>
      <div className='community-container'>
        <Row>
          <Col xl={20} sm={24} >
            <Divider orientation="left" orientationMargin="0" >
              <Text className="community-heading">Edit Toptracer Range</Text>
            </Divider>
          </Col>
        </Row>
        {reduxStatus !== 'loading' ? (
          <FormikProvider value={formikMain}>
            <form onSubmit={handleSubmit} className='create-community-form'>
              <Row>
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <FormikTextField
                    label="TOPTRACER RANGE NAME"
                    className="community-input"
                    autoComplete="off"
                    error={Boolean(errors.name && touched.name) && errors.name}
                    placeholder="Enter Toptracer name"
                    {...getFieldProps('name')}
                  />
                </Col>
                <Col span={8}></Col>
                <Col span={8}></Col>
              </Row>
              <Row>
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <FormikTextField
                    label="ADDRESS"
                    autoComplete="off"
                    type="textarea"
                    error={Boolean(errors.address && touched.address) && errors.address}
                    placeholder="Enter address"
                    {...getFieldProps('address')}
                  />
                </Col>
                <Col span={8}></Col>
                <Col span={8}></Col>
              </Row>
              <Row className="statesDropdown">
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <FormikStatesSelect
                    label="STATE"
                    placeholder="Select a State"
                    options={stateNameOptions}
                    autoComplete="off"
                    setFieldValue={setFieldValue}
                    error={
                      Boolean(errors.state_id && touched.state_id) && errors.state_id
                    }
                    {...getFieldProps('state_id')}
                  />
                </Col>
              </Row>
              <Row className='topTracerphoneInput'>
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <Text className='poster-text'>PHONE</Text>
                  <PhoneInput
                    international
                    countryCallingCodeEditable={false}
                    value={values.phone}
                    defaultCountry="US"
                    onChange={(inpVal) => setFieldValue('phone', inpVal)} />
                </Col>
                <Col span={8}></Col>
                <Col span={8}></Col>
              </Row>
              <Row>
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <FormikTextField
                    label="WEBSITE"
                    className="community-input"
                    autoComplete="off"
                    error={Boolean(errors.website && touched.website) && errors.website}
                    placeholder="Enter websiite"
                    {...getFieldProps('website')}
                  />
                </Col>
                <Col span={8}></Col>
                <Col span={8}></Col>
              </Row>
              <Row className='deviceSupportRadioBtn'>
                <Col xl={24} lg={12} sm={12} xs={24}>
                  <FormikRadioInput
                    label="DEVICE SUPPORT"
                    style={{
                      marginBottom: 36,
                      marginLeft: 6,
                    }}
                    option1="Mobile"
                    option2="Monitor"
                    option3="Both (Mobile & Monitor)"
                    value3="Both"
                    autoComplete="off"
                    error={
                      Boolean(errors.device_support && touched.device_support) &&
                      errors.device_support
                    }
                    {...getFieldProps('device_support')}
                  />
                </Col>
              </Row>
              <Row>
                <Space direction="horizontal" >
                  <Col>
                    <Button
                      disabled={isSubmitting}
                      loading={isSubmitting}
                      style={{ marginTop: 20 }}
                      type="primary"
                      htmlType="submit"
                      className='primary-btn'>
                      Save Changes
                    </Button>
                  </Col>
                  <Link to="/admin/top-tracer">
                    <Button
                      type="link"
                      size={"middle"}
                      style={{ color: "gray", marginTop: 36 }}
                    >
                      Cancel
                    </Button>
                  </Link>
                </Space>
              </Row>
            </form>
          </FormikProvider>
        ) : (
          <Row>
            <Col
              span={24}
              style={{
                paddingTop: 100,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Spin />
            </Col>
          </Row>
        )}
      </div>
    </MainLayout>
  );
};

export default EditTopTracer;
