// A "slice" is a collection of Redux reducer logic and actions for a single feature in your app,
// typically defined together in a single file. The name comes from splitting up the root Redux
// state object into multiple "slices" of state.

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notification } from "antd";
import { toast } from "react-toastify";
import { fetchLeagueRequestsApi } from "./API/leagueRequestsApi";

// You need to create a slice first, under the features folder
// that slice should have a createSlice() function and the intial state provided to it
// The slice name is also required, hence give the name: param a proper string

const initialState = {
  status: "idle",
  data: {},
};

//fetching league requests slice
export const fetchLeagueRequests = createAsyncThunk(
  "leagueRequests/get",
  async (data: any, thunkAPI) => {
    const response = await fetchLeagueRequestsApi(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user he has successfully fetched league requests
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully fetched the support ticets
      toast.success("League Requests are fetched", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (response.data.status === 401) {
      notification.error({
        message: `${response.data.statusText} Please Login Again`,
      });
    }
    return response.data;
  }
);







const leagueRequestsSlice = createSlice({
  name: "leagueRequests",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLeagueRequests.pending, (state) => {
        state.status = "loading";
      })


      .addCase(fetchLeagueRequests.fulfilled, (state, action) => {
        state.status = "idle";
        state.data = action.payload.data;
      })


      .addCase(fetchLeagueRequests.rejected, (state) => {
        state.status = "failed";
      });

  },
});

// Here we are exporting the actions that the component/button will need
// eslint-disable-next-line no-empty-pattern
export const { } = leagueRequestsSlice.actions;

// please look carefully at the below line, it returns a reducer object that's why it gets added in the store.
export default leagueRequestsSlice.reducer;
