import { Col, Row, Spin } from 'antd';

const Spinner = () => {
  return (
    <Row>
      <Col
        span={24}
        style={{
          paddingTop: 100,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Spin />
      </Col>
    </Row>
  );
};

export default Spinner;
