// A "slice" is a collection of Redux reducer logic and actions for a single feature in your app,
// typically defined together in a single file. The name comes from splitting up the root Redux
// state object into multiple "slices" of state.

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { toast } from 'react-toastify';
import { fetchDashboardMetricsApi } from './API/dashboardApi';

// You need to create a slice first, under the features folder
// that slice should have a createSlice() function and the intial state provided to it
// The slice name is also required, hence give the name: param a proper string

const initialState = {
  status: 'idle',
  data: {},
  dashboardMetrics: [],
};



//fetching metrics slice
export const fetchDashboardMetrics = createAsyncThunk(
  'dashboardMetrics/get',
  async (data: any, thunkAPI) => {
    const response = await fetchDashboardMetricsApi(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user he has successfully created the post
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.status === 401) {
      notification.error({
        message: `${response.data.statusText} Please Login Again`,
      });
     }
    return response.data;
  }
);






const DashboardMetricsSlice = createSlice({
  name: 'dashboardMetrics',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(fetchDashboardMetrics.pending, (state) => {
        state.status = 'loading';
      })


      .addCase(fetchDashboardMetrics.fulfilled, (state, action) => {
        state.status = 'idle';
        state.dashboardMetrics = action.payload.data;
      })

      .addCase(fetchDashboardMetrics.rejected, (state) => {
        state.status = 'failed';
      })

  },
});

// Here we are exporting the actions that the component/button will need
// eslint-disable-next-line no-empty-pattern
export const { } = DashboardMetricsSlice.actions;

// please look carefully at the below line, it returns a reducer object that's why it gets added in the store.
export default DashboardMetricsSlice.reducer;
