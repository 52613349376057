import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notification } from "antd";
import { fetchCourseNamesApi, fetchTopTracerDetailsApi } from "./API/courseNamesApi";


const initialState = {
  status: "idle",
  data: {},
  courseNames: [],
};

//fetching courseNames slice
export const fetchCourseNames = createAsyncThunk(
  "courseNames/get",
  async () => {
    const response = await fetchCourseNamesApi();
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {

      notification.error({
        message: response.data.data.error,
      });
    }
    return response.data;
  }
);

export const fetchTopTracerDetails = createAsyncThunk(
  'topTracerDetails/get',
  async (id: any, thunkAPI) => {
    const response = await fetchTopTracerDetailsApi(id);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user error
      notification.error({
        message: response.data.data.error,
      });
    }
    return response.data;
  }
);



const courseNamesSlice = createSlice({
  name: "courseNames",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCourseNames.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTopTracerDetails.pending, (state) => {
        state.status = "loading";
      })

      .addCase(fetchCourseNames.fulfilled, (state, action) => {
        state.status = "idle";
        state.courseNames = action.payload.data;
      })
      .addCase(fetchTopTracerDetails.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data = action.payload.data;
      })

      .addCase(fetchCourseNames.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(fetchTopTracerDetails.rejected, (state) => {
        state.status = "failed";
      });

  },
});

// Here we are exporting the actions that the component/button will need
// eslint-disable-next-line no-empty-pattern
export const { } = courseNamesSlice.actions;

// please look carefully at the below line, it returns a reducer object that's why it gets added in the store.
export default courseNamesSlice.reducer;
