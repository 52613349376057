// A "slice" is a collection of Redux reducer logic and actions for a single feature in your app,
// typically defined together in a single file. The name comes from splitting up the root Redux
// state object into multiple "slices" of state.

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notification } from "antd";
import { toast } from "react-toastify";
import { fetchTransactionApi, fetchTransactionsApi } from "./API/TransactionsApi";

// You need to create a slice first, under the features folder
// that slice should have a createSlice() function and the intial state provided to it
// The slice name is also required, hence give the name: param a proper string

const initialState = {
  status: "idle",
  data: {},
  transactions: [],
};

//fetching transactions slice
export const fetchTransactions = createAsyncThunk(
  "transactions/get",
  async (data: any, thunkAPI) => {
    const response = await fetchTransactionsApi(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user error
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully fetched the Transactions
      toast.success("Transactions are fetched", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (response.data.status === 401) {
      notification.error({
        message: `${response.data.statusText} Please Login Again`,
      });
    }
    return response.data;
  }
);

export const fetchTransaction = createAsyncThunk(
  'transaction/get',
  async (data: any, thunkAPI) => {
    const response = await fetchTransactionApi(data.id);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user error
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully fetched Transactions
      toast.success('Transaction fetched successfully', {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);





const TransactionsSlice = createSlice({
  name: "transactions",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTransactions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTransaction.pending, (state) => {
        state.status = "loading";
      })


      .addCase(fetchTransactions.fulfilled, (state, action) => {
        state.status = "idle";
        state.data = action.payload.data;
      })
      .addCase(fetchTransaction.fulfilled, (state, action) => {
        state.status = "idle";
        state.transactions = action.payload.data;
      })


      .addCase(fetchTransactions.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(fetchTransaction.rejected, (state) => {
        state.status = "failed";
      })


  },
});

// Here we are exporting the actions that the component/button will need
// eslint-disable-next-line no-empty-pattern
export const { } = TransactionsSlice.actions;

// please look carefully at the below line, it returns a reducer object that's why it gets added in the store.
export default TransactionsSlice.reducer;
