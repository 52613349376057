import { Col, Divider, Row, Typography } from 'antd';

const { Text } = Typography;

const FormHeader = ({ heading }) => {
  return (
    <Row>
      <Col xl={20} sm={24}>
        <Divider orientation="left" orientationMargin="0">
          <Text className="community-heading">{heading}</Text>
        </Divider>
      </Col>
    </Row>
  );
};

export default FormHeader;
