import { smashersAPI } from '@utils/APIInterceptor';

export async function createFlightApi(flightsData: any) {
  const accessToken = localStorage.getItem('accessToken');
  const stringFlights = JSON.stringify(flightsData.flights);
  const data: any = await smashersAPI(accessToken).post(
    'api/v1/admin/flight_distributions',
    {
      ...flightsData,
      flights: stringFlights,
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function fetchFlightApi(payload: any) {
  const accessToken = localStorage.getItem('accessToken');
  const data: any = await smashersAPI(accessToken).get(
    `api/v1/admin/flight_distributions?page=${payload.page}&per_page=${payload.per_page}`
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function deleteFlightApi(id: any) {
  const accessToken = localStorage.getItem('accessToken');
  const data: any = await smashersAPI(accessToken).delete(
    `api/v1/admin/flight_distributions/${id}`
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function fetchSingleFlightApi(id: any) {
  const accessToken = localStorage.getItem('accessToken');
  const data: any = await smashersAPI(accessToken).get(
    `api/v1/admin/flight_distributions/${id}`
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function editFlightApi(payload: { flightsData: any; id: any }) {
  const { flightsData, id } = payload;
  const accessToken = localStorage.getItem('accessToken');
  const stringFlights = JSON.stringify(flightsData?.flights);
  const data: any = await smashersAPI(accessToken).put(
    `api/v1/admin/flight_distributions/${id}`,
    {
      ...flightsData,
      flights: stringFlights,
    }
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function fetchFlightDistributionsApi() {
  const accessToken = localStorage.getItem('accessToken');
  const data: any = await smashersAPI(accessToken).get(
    'api/v1/admin/flight_distributions/names?term=inrange_flights'
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function fetchFlightNamesApi(id: any) {
  const accessToken = localStorage.getItem('accessToken');
  const data: any = await smashersAPI(accessToken).get(
    `api/v1/admin/flight_distributions/${id}/flights`
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}
