import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notification } from "antd";
import { fetchUSStatesApi } from "./API/USStateListApi";


const initialState = {
  status: "idle",
  data: {},
  USStates: [],
};

//fetching USStates slice
export const fetchUSStates = createAsyncThunk(
  "USStates/get",
  async () => {
    const response = await fetchUSStatesApi();
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {

      notification.error({
        message: response.data.data.error,
      });
    }
    return response.data;
  }
);




const USStatesSlice = createSlice({
  name: "USStates",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUSStates.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchUSStates.fulfilled, (state, action) => {
        state.status = "idle";
        state.USStates = action.payload.data;
      })
      .addCase(fetchUSStates.rejected, (state) => {
        state.status = "failed";
      })


  },
});

// Here we are exporting the actions that the component/button will need
// eslint-disable-next-line no-empty-pattern
export const { } = USStatesSlice.actions;

// please look carefully at the below line, it returns a reducer object that's why it gets added in the store.
export default USStatesSlice.reducer;
