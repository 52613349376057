import { configureStore } from '@reduxjs/toolkit';
import eventsSlice from '@slices/eventsSlice';

import campaignsSlice from '@slices/campaignsSlice';
import authSlice from '@slices/authSlice';
import scoresSlice from '@slices/scoresSlice';
import postsSlice from '@slices/postsSlice';
import supportTicketsSlice from '@slices/supportTicketsSlice';
import supportCategorySlice from '@slices/supportCategorySlice';
import partnerBrandsSlice from '@slices/partnerBrandsSlice';
import giftCardsSlice from '@slices/giftCardsSlice';
import flightsSlice from '@slices/flightsSlice';
import transactionsSlice from '@slices/transactionsSlice';
import courseSlice from '@slices/courseSlice';
import courseNamesSlice from '@slices/courseNamesSlice';
import topTracersSlice from '@slices/topTracersSlice';
import inrangeSlice from '@slices/inrangeSlice';
import USStatesSlice from '@slices/USStatesSlice';
import leaguesSlice from '@slices/leaguesSlice';
import leagueRequestsSlice from '@slices/leagueRequestsSlice';
import dashBoardSlice from '@slices/dashBoardSlice';
import playersSlice from '@slices/playersSlice';

// let sagaMiddleware = createSagaMiddleware();
// const middleware = [sagaMiddleware];

export const store = configureStore({
  reducer: {
    events: eventsSlice,
    auth: authSlice,
    scores: scoresSlice,
    posts: postsSlice,
    support: supportTicketsSlice,
    supportCategory: supportCategorySlice,
    partnerBrands: partnerBrandsSlice,
    giftCards: giftCardsSlice,
    flights: flightsSlice,
    transactions: transactionsSlice,
    courses: courseSlice,
    courseNames: courseNamesSlice,
    topTracers: topTracersSlice,
    USStates: USStatesSlice,
    leagues: leaguesSlice,
    leagueRequests: leagueRequestsSlice,
    dashboardMetrics: dashBoardSlice,
    players: playersSlice,
    campaigns: campaignsSlice,
    inrange: inrangeSlice
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
