import { smashersInrangeAPI } from "@utils/APIInterceptor";

export async function fetchInrangeRangesAPI() {
  const data: any = await smashersInrangeAPI().get("ranges");
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function fetchInrangeChallengesAPI() {
  const data: any = await smashersInrangeAPI().get("challenges");
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}
