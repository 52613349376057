// A "slice" is a collection of Redux reducer logic and actions for a single feature in your app,
// typically defined together in a single file. The name comes from splitting up the root Redux
// state object into multiple "slices" of state.

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notification } from "antd";
import { toast } from "react-toastify";
import { fetchSupportTicketApi, fetchSupportTicketsApi, updateSupportTicketApi } from "./API/supportTicketsApi";

// You need to create a slice first, under the features folder
// that slice should have a createSlice() function and the intial state provided to it
// The slice name is also required, hence give the name: param a proper string

const initialState = {
  status: "idle",
  data: {},
  supportTickets: [],
};

//fetching support tickets slice
export const fetchSupportTickets = createAsyncThunk(
  "supportTickets/get",
  async (data: any, thunkAPI) => {
    const response = await fetchSupportTicketsApi(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user he has successfully fetched support tickets
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully fetched the support ticets
      toast.success("Support Tickets are fetched", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (response.data.status === 401) {
      notification.error({
        message: `${response.data.statusText} Please Login Again`,
      });
    }
    return response.data;
  }
);

export const fetchSupportTicket = createAsyncThunk(
  'supportTicket/get',
  async (data: any, thunkAPI) => {
    const response = await fetchSupportTicketApi(data.id);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user he has successfully fetched supportTicket
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully fetched supportTicket
      toast.success('SupportTicket fetched successfully', {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

// For updating the support ticket
export const updateSupportTicket = createAsyncThunk(
  'supportTicket/update',
  async (data: any, thunkAPI) => {
    const response = await updateSupportTicketApi(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user he has successfully updated the support ticket
      toast.error(response.data.data.error, {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully updated the support ticket
      toast.success('Ticket updated successfully', {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);



const supportTicketsSlice = createSlice({
  name: "supportTickets",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSupportTickets.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchSupportTicket.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateSupportTicket.pending, (state) => {
        state.status = 'loading';
      })

      .addCase(fetchSupportTickets.fulfilled, (state, action) => {
        state.status = "idle";
        state.data = action.payload.data;
      })
      .addCase(fetchSupportTicket.fulfilled, (state, action) => {
        state.status = "idle";
        state.supportTickets = action.payload.data;
      })
      .addCase(updateSupportTicket.fulfilled, (state, action) => {
        state.status = 'idle';
        state.supportTickets = action.payload.data;
      })

      .addCase(fetchSupportTickets.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(fetchSupportTicket.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updateSupportTicket.rejected, (state) => {
        state.status = 'failed';
      });

  },
});

// Here we are exporting the actions that the component/button will need
// eslint-disable-next-line no-empty-pattern
export const { } = supportTicketsSlice.actions;

// please look carefully at the below line, it returns a reducer object that's why it gets added in the store.
export default supportTicketsSlice.reducer;
