import { Col, Layout, Row } from "antd";
import React, { useEffect } from "react";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import Logo from "@assets/images/Logo.png";

type Props = {
  children: any;
  pageTitle?: string;
};

const AuthLayout = (props: Props) => {
  const navigate = useNavigate();
  useEffect(() => {
    const accessToken = localStorage.getItem("accessToken");

    if (!!accessToken) {
      navigate("/admin/dashboard", { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout className="auth-layout">
      <Row className="auth-layout-row">
        <Col xl={12} lg={12} md={12} sm={24} xs={24} className="auth-left">
          <div>
            <div>
              <img className="smashers-logo" src={Logo} alt="logo" width="200" />
            </div>
            {!!props.pageTitle && <p>{props.pageTitle}</p>}
          </div>
        </Col>
        <Col xl={12} lg={12} md={12} sm={24} xs={24} className="auth-right">
          {props.children}
        </Col>
      </Row>
    </Layout>
  );
};

export default AuthLayout;
