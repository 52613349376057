import { smashersAPI } from '@utils/APIInterceptor';

export async function fetchPlayersApi(payload: any) {
  const accessToken = localStorage.getItem('accessToken');
  const searchTerm = payload.term || '';
  const sorter = payload.sort_by || '';
  const sortOrder = payload.direction || '';
  const data: any = await smashersAPI(accessToken).get(
    `api/v1/admin/users?page=${payload.page}&per_page=${payload.per_page}&term=${searchTerm}&sort_by=${sorter}&direction=${sortOrder}`
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function fetchUserRegisteredEventsApi(id: any) {
  const accessToken = localStorage.getItem('accessToken');
  const data: any = await smashersAPI(accessToken).get(
    `api/v1/admin/users/${id}/events`
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function assignSmashCashApi({
  id,
  event_id,
  smash_cash,
  assignment_type,
  comments,
  gross_smash_cash,
  net_smash_cash,
}: any) {
  const accessToken = localStorage.getItem('accessToken');
  const data: any = await smashersAPI(accessToken).post(
    `api/v1/admin/users/${id}/assign_smash_cash`,
    {
      event_id,
      smash_cash,
      assignment_type,
      comments,
      gross_smash_cash,
      net_smash_cash,
    }
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function fetchPlayersCSVApi() {
  const accessToken = localStorage.getItem('accessToken');
  const data: any = await smashersAPI(accessToken).get(
    'api/v1/admin/users.csv'
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}
