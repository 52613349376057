import { smashersAPI } from '@utils/APIInterceptor';

export async function fetchUSStatesApi() {
  const accessToken = localStorage.getItem('accessToken');
  const data: any = await smashersAPI(accessToken).get('api/v1/states');
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}
