import React from "react";
import LogoIcon from '@assets/images/smashers-logo-mini.webp'
type Props = {};

const StaticLoader = (props: Props) => {
  return (
    <div
      style={{
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >

        <img alt='content-loading' src={LogoIcon}  height='70'/>
      <div className="spinner"></div>
    </div>
  );
};

export default StaticLoader;
