// A "slice" is a collection of Redux reducer logic and actions for a single feature in your app,
// typically defined together in a single file. The name comes from splitting up the root Redux
// state object into multiple "slices" of state.

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { toast } from 'react-toastify';
import { createTopTracerApi, deleteTopTracerApi, editTopTracerApi, fetchSingleTopTracerApi, fetchTopTracerApi } from './API/topTracerApi';

// You need to create a slice first, under the features folder
// that slice should have a createSlice() function and the intial state provided to it
// The slice name is also required, hence give the name: param a proper string

const initialState = {
  status: 'idle',
  data: {},
  topTracers: [],
};

// Writing a thunk for creating topTracer
export const createTopTracer = createAsyncThunk(
  'topTracers/create',
  async (data: any, thunkAPI) => {
    const response = await createTopTracerApi(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user response error
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data) {
      // notify the user he has successfully created the topTracer
      toast.success('TopTracer has been created successfully', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

//fetching topTracers slice
export const fetchTopTracers = createAsyncThunk(
  'topTracers/get',
  async (data: any, thunkAPI) => {
    const response = await fetchTopTracerApi(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user response error
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully fetched the topTracer
      toast.success('List of TopTracers fetched successfully', {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (response.data.status === 401) {
      notification.error({
        message: `${response.data.statusText} Please Login Again`,
      });
    }
    return response.data;
  }
);

//fetching single topTracer
export const fetchTopTracer = createAsyncThunk(
  'topTracer/get',
  async (data: any, thunkAPI) => {
    const response = await fetchSingleTopTracerApi(data.id);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      //  notify the user response error
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully fetched the topTracer
      toast.success('TopTracer details fetched successfully', {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

export const deleteTopTracer = createAsyncThunk(
  'topTracer/delete',
  async (id: any, thunkAPI) => {
    const response = await deleteTopTracerApi(id);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      //  notify the user response error
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully deleted the topTracer
      toast.success('TopTracer deleted successfully', {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

export const editTopTracer = createAsyncThunk(
  'topTracer/edit',
  async (data: any, thunkAPI) => {
    const response = await editTopTracerApi(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user response error
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully edited the flight
      toast.success('TopTracer has been successfully updated', {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

const TopTracersSlice = createSlice({
  name: 'topTracers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createTopTracer.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTopTracers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteTopTracer.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(editTopTracer.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTopTracer.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createTopTracer.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data = action.payload.data;
      })
      .addCase(fetchTopTracers.fulfilled, (state, action) => {
        state.status = 'idle';
        state.topTracers = action.payload.data;
      })
      .addCase(deleteTopTracer.fulfilled, (state, action) => {
        state.status = 'idle';
        state.topTracers = action.payload.data;
      })
      .addCase(editTopTracer.fulfilled, (state, action) => {
        state.status = 'idle';
        state.topTracers = action.payload.data;
      })
      .addCase(fetchTopTracer.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data = action.payload.data;
      })
      .addCase(createTopTracer.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchTopTracers.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(deleteTopTracer.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchTopTracer.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(editTopTracer.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

// Here we are exporting the actions that the component/button will need
// eslint-disable-next-line no-empty-pattern
export const { } = TopTracersSlice.actions;

// please look carefully at the below line, it returns a reducer object that's why it gets added in the store.
export default TopTracersSlice.reducer;
