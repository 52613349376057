import { smashersAPI } from '@utils/APIInterceptor';

export async function fetchSupportTicketsApi(payload: any) {
  const accessToken = localStorage.getItem('accessToken');
  const data: any = await smashersAPI(accessToken).get(
    `api/v1/admin/support_tickets?page=${payload.page}&per_page=${payload.per_page}`
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function fetchSupportTicketApi(id: any) {
  const accessToken = localStorage.getItem('accessToken');
  const data: any = await smashersAPI(accessToken).get(
    `api/v1/admin/support_tickets/${id}`
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function updateSupportTicketApi(payload: any) {
  const accessToken = localStorage.getItem('accessToken');

  const data: any = await smashersAPI(accessToken).put(
    `api/v1/admin/support_tickets/${payload.id}`,
    {
      ...payload.supportTicketData,
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}
