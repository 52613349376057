import { smashersAPI } from '@utils/APIInterceptor';

export async function fetchDashboardMetricsApi(payload: any) {
  const accessToken = localStorage.getItem('accessToken');
  const data: any = await smashersAPI(accessToken).get(
    `api/v1/admin/dashboard?start_time=${payload.start_time}&end_time=${payload.end_time}`
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}
