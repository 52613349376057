// A "slice" is a collection of Redux reducer logic and actions for a single feature in your app,
// typically defined together in a single file. The name comes from splitting up the root Redux
// state object into multiple "slices" of state.

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { toast } from 'react-toastify';
import {
  createCourseApi,
  deleteCourseApi,
  fetchCourseApi,
  getSingleCourse,
  updateCourseApi,
} from './API/coursesApi';

// You need to create a slice first, under the features folder
// that slice should have a createSlice() function and the intial state provided to it
// The slice name is also required, hence give the name: param a proper string

const initialState = {
  status: 'idle',
  data: {},
  courses: [],
};

// Writing a thunk for creating courses
export const createCourse = createAsyncThunk(
  'courses/create',
  async (data: any, thunkAPI) => {
    const response = await createCourseApi(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500 || response.data.status === 422) {
      // notify the user error
      notification.error({
        message:
          response.data.data.status === 500
            ? response.data.data.error
            : response.data.status === 422
            ? response.data.data.message
            : null,
      });
    } else if (response.data) {
      // notify the user he has successfully created the course
      toast.success(response.data.data.message, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

//fetching courses slice
export const fetchCourses = createAsyncThunk(
  'courses/get',
  async (data: any, thunkAPI) => {
    const response = await fetchCourseApi(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user error
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully fetched the courses
      toast.success('List of courses fetched successfully', {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (response.data.status === 401) {
      notification.error({
        message: `${response.data.statusText} Please Login Again`,
      });
    }
    return response.data;
  }
);

export const fetchCourse = createAsyncThunk(
  'course/get',
  async (data: any, thunkAPI) => {
    const response = await getSingleCourse(data.id);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user error
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully fetched the event
      toast.success('Course fetched successfully', {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

// For updating the course
export const updateCourse = createAsyncThunk(
  'course/update',
  async (data: any, thunkAPI) => {
    const response = await updateCourseApi(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500 || response.data.status === 422) {
      // notify the user error
      toast.error(
        response.data.data.status === 500
          ? response.data.data.error
          : response.data.status === 422
          ? response.data.data.message
          : null,
        {
          position: 'top-right',
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    } else if (response.data.data.success) {
      // notify the user he has successfully updated the course
      toast.success('Course updated successfully', {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

export const deleteCourse = createAsyncThunk(
  'course/delete',
  async (id: any, thunkAPI) => {
    const response = await deleteCourseApi(id);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.status === 403) {
      notification.warning({
        message: response.data.data.message,
        placement: 'top',
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully deleted the course
      toast.success('Course deleted successfully', {
        position: 'top-right',

        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

const coursesSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createCourse.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCourses.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchCourse.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteCourse.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(updateCourse.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createCourse.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data = action.payload.data;
      })
      .addCase(fetchCourses.fulfilled, (state, action) => {
        state.status = 'idle';
        state.courses = action.payload.data;
      })
      .addCase(fetchCourse.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data = action.payload.data;
      })
      .addCase(deleteCourse.fulfilled, (state, action) => {
        state.status = 'idle';
        state.courses = action.payload.data;
      })
      .addCase(updateCourse.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data = action.payload.data;
      })
      .addCase(createCourse.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchCourses.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchCourse.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(deleteCourse.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(updateCourse.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

// Here we are exporting the actions that the component/button will need
// eslint-disable-next-line no-empty-pattern
export const {} = coursesSlice.actions;

// please look carefully at the below line, it returns a reducer object that's why it gets added in the store.
export default coursesSlice.reducer;
