import { FormikTextField, FormikRadioInput } from '@components/inputFeilds/InputFeilds';
import { MainLayout } from '@components/layouts';
import { Button, Col, Divider, Image, Row, Space, Spin, Typography, } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector, } from "react-redux";
import { AppDispatch } from "@config/storeConfig";
import DefaultLogo from "@assets/images/Smashers On partner.jpg";
import { useEffect, useState } from 'react';
import { fetchPartnerBrand, updatePartnerBrand } from '@slices/partnerBrandsSlice';
import { toast } from 'react-toastify';
import { Slide } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ImageUpload from '@components/imageUpload/ImageUpload';

//import './styles.css';

const { Text } = Typography;

const EditPartnerBrand = () => {
  const [logoBase, setLogoBase] = useState<any>("");
  const dispatch = useDispatch<AppDispatch>();
  const [previewLogo, setPreviewLogo] = useState(true)
  const reduxStatus = useSelector((state: any) => state.partnerBrands.status);
  const livePartnerBrandState = useSelector((state: any) => state.partnerBrands.data.data);
  const params = useParams();
  const navigate = useNavigate();
  const currentPartnerBrandId = params?.id;



  const formikMain = useFormik({
    initialValues: {
      name: livePartnerBrandState && livePartnerBrandState.name ? livePartnerBrandState.name : "",
      featured: livePartnerBrandState && livePartnerBrandState.featured ? (livePartnerBrandState.featured ? "Yes" : "No") : "",
      rank: livePartnerBrandState && livePartnerBrandState.rank ? livePartnerBrandState.rank : "",
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      setSubmitting(true);
      const partnerBrandsData = {
        name: values.name,
        rank: values.rank,
        featured: values.featured === "Yes" ? true : false,
      };
      const logoParam = Boolean(logoBase);
      if (logoParam) {
        const addLogo = { logo: logoBase };
        handleEditPartnerBrandFormSubmit({
          ...partnerBrandsData,
          ...addLogo,
        });
      } else {

        handleEditPartnerBrandFormSubmit(partnerBrandsData);
      }
      setSubmitting(false);
      resetForm();
      navigate("/admin/partner-brands");

    },
    validationSchema: yup.object({
      name: yup.string().required('*Required Brand Name'),
      rank: yup.number()
        .typeError("*Should be a number")
        .required("*Required Rank"),
      featured: yup.string().required("*Select One"),
    }),
  });

  const {
    errors,
    getFieldProps,
    handleSubmit,
    touched,
    isSubmitting,
    resetForm,
    setSubmitting,
  } = formikMain;



  useEffect(() => {
    //Fetch the partner brand right here and populate the values
    // use current id currentPartnerBrandId
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(fetchPartnerBrand({ id: currentPartnerBrandId }));
  }, [currentPartnerBrandId, dispatch]);

  useEffect(() => {
    toast.info(
      <>
        <p>Get started with creating new partner brand here!</p>
      </>,
      {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
        transition: Slide,
      }
    );
  }, []);

  const handleEditPartnerBrandFormSubmit = async (argo: any) => {
    await dispatch(updatePartnerBrand({ id: params.id, partnerBrandData: argo }));
  };

  return (
    <MainLayout>
      <div className='community-container'>
        <Row>
          <Col xl={20} sm={24} >
            <Divider orientation="left" orientationMargin="0" >
              <Text className="community-heading">Edit Vendor Company</Text>
            </Divider>
          </Col>
        </Row>

        {reduxStatus !== 'loading' ? <FormikProvider value={formikMain}>
          <form onSubmit={handleSubmit} className='create-community-form'>
            <Row>
              <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                <FormikTextField
                  label="VENDOR COMPANY NAME"
                  className="community-input"

                  autoComplete="off"
                  error={Boolean(errors.name && touched.name) && errors.name}
                  placeholder="Enter brand name"
                  {...getFieldProps('name')}
                />
              </Col>
              <Col span={8}></Col>
              <Col span={8}></Col>
            </Row>

            <Row>

              <Col xl={8} lg={10} md={12} sm={24} xs={24} onClick={() => setPreviewLogo(false)}>
                <Text className="poster-text">LOGO</Text>
                <ImageUpload
                  logoBase={logoBase}
                  setLogoBase={setLogoBase}
                />
              </Col>
              <Col xl={8} lg={10} md={12} sm={24} xs={24} className="preview-img">
                {previewLogo && (livePartnerBrandState &&
                  <Image
                    style={{ width: 80, height: 80, objectFit: 'contain', marginRight: 10 }}
                    src={livePartnerBrandState.logo === null ? DefaultLogo : livePartnerBrandState.logo}

                  />)
                }
              </Col>
              <Col span={8}></Col>
            </Row>
            <Col xl={24} lg={12} sm={12} xs={24}>
              <FormikRadioInput
                label="FEATURED BRAND"
                style={{
                  marginTop: 36,
                  marginLeft: 6,
                }}
                option1="Yes"
                option2="No"
                autoComplete="off"
                error={
                  Boolean(errors.featured && touched.featured) &&
                  errors.featured
                }
                {...getFieldProps("featured")}
              />
            </Col>
            <Row>
              <Col xl={4} lg={4} md={4} sm={8}>
                <FormikTextField
                  label="RANK"
                  className="community-input"

                  autoComplete="off"
                  error={Boolean(errors.rank && touched.rank) && errors.rank}
                  placeholder="Enter Rank"
                  {...getFieldProps('rank')}
                />
              </Col>
              <Col span={8}></Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Space direction="horizontal" >
                <Col>
                  <Button style={{ marginTop: 20 }} disabled={isSubmitting}
                    type="primary"
                    loading={isSubmitting} htmlType="submit" className='primary-btn'>
                    Save Changes
                  </Button>
                </Col>
                <Link to="/admin/partner-brands">
                  <Button
                    type="link"
                    size={"middle"}
                    style={{ color: "gray", marginTop: 36 }}
                  >
                    Cancel
                  </Button>
                </Link>
              </Space>
            </Row>
          </form>
        </FormikProvider> :
          <Row>
            <Col span={24} style={{ paddingTop: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Spin />
            </Col>
          </Row>
        }
      </div>
    </MainLayout>
  );
};

export default EditPartnerBrand;
