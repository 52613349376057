import {
  FormikRadioInputThreeFields,
  FormikTextField,
} from "@components/inputFeilds/InputFeilds";
import { useEffect } from "react";
import { MainLayout } from "@components/layouts";
import { Button, Col, Input, Row, Space, Typography } from "antd";
import { Field, FormikProvider, useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "@config/storeConfig";
import { Slide, toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { fetchFlight } from "@slices/flightsSlice";
import CancelBtn from "@components/buttons/CancelBtn";
import Spinner from "@components/loaders/Spinner";
import FormHeader from "@components/formHeader/FormHeader";

const { Text } = Typography;

const GENDER_OPTIONS = [
  {
    id: 1,
    name: "Male",
    value: "male",
  },
  {
    id: 2,
    name: "Female",
    value: "female",
  },
  {
    id: 3,
    name: "Both",
    value: "both",
  },
];

const ViewFlight = () => {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();
  const currentFlight = useSelector((state: any) => state.flights.data.data);
  const reduxStatus = useSelector((state: any) => state.flights.status);

  const updatedFlightsData = currentFlight?.flights
    .map((data: any) => {
      return {
        ...data,
        min_hcp: data.min_hcp === -12345 ? "-" : data.min_hcp,
        max_hcp: data.max_hcp === 12345 ? "-" : data.max_hcp,
        percentage: data.percentage * 100,
      };
    })
    .sort((a: any, b: any) => a.min_hcp - b.min_hcp);

  const formikMain = useFormik({
    initialValues: {
      name: currentFlight && currentFlight.name ? currentFlight.name : "",
      flight_type:
        currentFlight && currentFlight.flight_type
          ? currentFlight.flight_type
          : "",
      number: currentFlight && currentFlight.number ? currentFlight.number : "",
      flights: updatedFlightsData && updatedFlightsData,
    },
    enableReinitialize: true,
    onSubmit: async () => {},
  });

  const { getFieldProps, handleSubmit, values } = formikMain;

  useEffect(() => {
    dispatch(fetchFlight({ id: params.id }));
    toast.info(
      <>
        <p>View your flight here!</p>
      </>,
      {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
        transition: Slide,
        style: { minWidth: 400 },
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainLayout>
      <div className="community-container">
        <FormHeader heading="View Flight Setup" />
        {reduxStatus !== "loading" ? (
          <FormikProvider value={formikMain}>
            <form onSubmit={handleSubmit} className="create-community-form">
              <Row>
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <FormikTextField
                    label="FLIGHT SETUP NAME"
                    className="community-input"
                    placeholder="Enter your flight name"
                    {...getFieldProps("name")}
                    disabled
                  />
                </Col>
                <Col span={8}></Col>
                <Col span={8}></Col>
              </Row>
              <FormikRadioInputThreeFields
                label="SELECT TYPE"
                option1="Percentage"
                option2="HCP Values"
                value1="percentage"
                value2="hcp_value"
                option3="Inrange Flights"
                value3="inrange_flights"
                disabled
                {...getFieldProps("flight_type")}
              />
              <Row>
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <Text style={{ fontSize: 11, letterSpacing: 1 }}>
                    NO. OF FLIGHTS
                  </Text>
                  <Input
                    disabled
                    value={values.number}
                    style={{ height: 50, marginBottom: 30 }}
                  />
                </Col>
              </Row>
              <div className="rewards-table-wrapper">
                <div className="table-columns">
                  <Text className="column">Flight Name</Text>
                  {values.flight_type === "hcp_value" && (
                    <>
                      <Text className="column">Min HCP</Text>
                      <Text className="column">Max HCP</Text>
                    </>
                  )}
                  {values.flight_type === "percentage" && (
                    <Text className="column">Percentage</Text>
                  )}
                  {values.flight_type === "inrange_flights" && (
                    <>
                      <Text className="column">Gender</Text>
                      <Text className="column">Min HCP</Text>
                      <Text className="column">Max HCP</Text>
                    </>
                  )}
                </div>
                {[...Array(values.number)].map((cur, index: number) => {
                  return (
                    <div key={index} className="rewards-table-row">
                      <div className="input-container view-input-container">
                        <Field
                          {...getFieldProps(`flights.${index}.name`)}
                          disabled
                        />
                      </div>
                      {values.flight_type === "hcp_value" && (
                        <>
                          <div className="input-container view-input-container">
                            <Field
                              {...getFieldProps(`flights.${index}.min_hcp`)}
                              disabled
                            />
                          </div>

                          <div className="input-container view-input-container">
                            <Field
                              {...getFieldProps(`flights.${index}.max_hcp`)}
                              disabled
                            />
                          </div>
                        </>
                      )}
                      {values.flight_type === "percentage" && (
                        <div className="input-container view-input-container">
                          <Field
                            {...getFieldProps(`flights.${index}.percentage`)}
                            disabled
                          />
                        </div>
                      )}

                      {values.flight_type === "inrange_flights" && (
                        <div
                          className="input-container view-input-container"
                        >
                          <Field
                            as="select"
                            {...getFieldProps(`flights[${index}].gender`)}
                            style={{ padding: 0 }}
                            disabled
                          >
                            <option value="" selected disabled hidden>
                              Select Gender
                            </option>
                            {GENDER_OPTIONS?.map((gender: any) => (
                              <option key={gender.id} value={gender.value}>
                                {gender.name}
                              </option>
                            ))}
                          </Field>
                        </div>
                      )}
                      {values.flight_type === "inrange_flights" && (
                        <>
                          <div className="input-container  view-input-container">
                            <Field
                              {...getFieldProps(`flights.${index}.min_hcp`)}
                              disabled
                            />
                          </div>

                          <div className="input-container view-input-container">
                            <Field
                              disabled
                              {...getFieldProps(`flights.${index}.max_hcp`)}
                              //For auto incrementing next min_hcp value by 0.01
                              //based on previuos max_hcp value
                            />
                            <div className="error-wrapper"></div>
                          </div>
                        </>
                      )}
                    </div>
                  );
                })}
              </div>
              <Space direction="horizontal">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="create-event-btn"
                  style={{ marginTop: 20 }}
                  onClick={() => {
                    navigate(`/admin/edit/flight/${params.id}`);
                  }}
                >
                  Edit Flight Setup
                </Button>
                <CancelBtn link="/admin/flights" />
              </Space>
            </form>
          </FormikProvider>
        ) : (
          <Spinner />
        )}
      </div>
    </MainLayout>
  );
};

export default ViewFlight;
