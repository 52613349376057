import {
  FormikTextField,
  FormikDatePicker,
  FormikRadioInputThreeFields,
} from "@components/inputFeilds/InputFeilds";
import { MainLayout } from "@components/layouts";
import { Button, Col, Divider, Form, Row, Space, Spin, Typography } from "antd";
import { ReactComponent as DropDownOutlined } from "@assets/icons/dropdown-icon.svg";
import { ErrorMessage, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "@config/storeConfig";

import { forwardRef, useEffect, useState } from "react";
import { Select } from "antd";

import { toast } from "react-toastify";
import { Slide } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";
import { fetchGiftCard, updateGiftCard } from "@slices/giftCardsSlice";
import { fetchPartnerBrands } from "@slices/partnerBrandsSlice";
import DraftRTE from "@components/rte/DraftRTE";
import CSVUpload from "@components/csvUpload/csvUpload";

//import './styles.css';

const { Text } = Typography;
const { Option } = Select;
const { Item } = Form;

const EditGiftCard = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const params = useParams();
  const reduxStatus = useSelector((state: any) => state.giftCards.status);
  const [csv, setCsv] = useState<any>('');
  const currentGiftCardId = params?.id;
  const liveGiftCardState = useSelector(
    (state: any) => state.giftCards.data.data
  );
  const partnerBrandFromRedux = useSelector(
    (state: any) => state.partnerBrands.partnerBrands.data
  );
  const partnerBrandTotalCount = useSelector(
    (state: any) => state.partnerBrands.partnerBrands.total_count
  );

  const BrandOptions =
    partnerBrandFromRedux &&
    partnerBrandFromRedux.map((brand: any) => ({
      name: brand.name,
      id: brand.id,
    }));

  const formikMain = useFormik({
    initialValues: {
      name: liveGiftCardState && liveGiftCardState.name ? liveGiftCardState.name : "",
      title: liveGiftCardState && liveGiftCardState.title ? liveGiftCardState.title : "",
      gift_card_code: liveGiftCardState && liveGiftCardState.code ? liveGiftCardState.code : undefined,
      external_website_link: liveGiftCardState && liveGiftCardState.external_website_link ? liveGiftCardState.external_website_link : "",
      instructions: liveGiftCardState && liveGiftCardState.instructions ? liveGiftCardState.instructions : "",
      smash_cash: liveGiftCardState && liveGiftCardState.smash_cash ? liveGiftCardState.smash_cash : "",
      validity: liveGiftCardState && liveGiftCardState.validity ? liveGiftCardState.validity : "",
      quantity: liveGiftCardState && liveGiftCardState.quantity ? liveGiftCardState.quantity : "",
      partner_brand_id: partnerBrandFromRedux?.filter((brand: any) => brand.name === liveGiftCardState?.name)[0]?.id,
      creation_type: liveGiftCardState && liveGiftCardState.creation_type ? liveGiftCardState.creation_type : "",
      format: liveGiftCardState && liveGiftCardState.format ? liveGiftCardState.format : "",
      pin: liveGiftCardState && liveGiftCardState.pin ? liveGiftCardState.pin : undefined,
      file_type: liveGiftCardState && liveGiftCardState.file_type ? liveGiftCardState.file_type : "",
      value: liveGiftCardState && liveGiftCardState.value ? liveGiftCardState.value : undefined,
      file: undefined,
    },

    enableReinitialize: true,
    onSubmit: async (values) => {
      setSubmitting(true);
      const giftCardsData = {
        name: values.name,
        title: values.title,
        gift_card_code: values.gift_card_code,
        external_website_link: values.external_website_link,
        instructions: values.instructions,
        smash_cash: values.smash_cash,
        validity: values.validity,
        quantity: values.quantity,
        partner_brand_id: values.partner_brand_id,
        creation_type: values.creation_type,
        format: values.format,
        pin: values.pin,
        file_type: values.file_type,
        file: values.file,
        value: values.value
      };

      const csvParam = Boolean(csv);
      if (csvParam) {
        const addCsv = { file: csv };
        await handleEditGiftCardFormSubmit({
          ...giftCardsData,
          ...addCsv,
        });
      } else {
        await handleEditGiftCardFormSubmit(giftCardsData);
      }


      setSubmitting(false);
      resetForm();
    },
    validationSchema: yup.object({
      name: yup.string().required("*Required Company"),
      title: yup.string().required("*Required Heading"),
      gift_card_code: yup.string(),
      external_website_link: yup
        .string()
        .matches(
          /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
          "Enter correct url!"
        )
        .required("*Required link"),
      instructions: yup.string().required("*Required Instructions"),
      smash_cash: yup
        .number()
        .typeError("*Should be a number")
        .required("*Required Smash Cash"),
      value: yup
        .number()
        .typeError("*Should be a number"),
      quantity: yup
        .number()
        .typeError("*Should be a number"),
      format: yup.string().required("*Required Card Type"),
      creation_type: yup.string().required("*Required"),
      file_type: yup.string().when('creation_type', {
        is: 'multiple',
        then: yup.string().required('*Required File Type'),
      }),
    }),
  });

  const {
    errors,
    getFieldProps,
    handleSubmit,
    touched,
    values,
    setFieldValue,
    resetForm,
    setSubmitting,
    isSubmitting,
  } = formikMain;



  const FormikBrandsSelect = forwardRef(
    (
      {
        error,
        label,
        name,
        onChange,
        onBlur,
        placeholder,
        value,
        options,
        style,
        setFieldValue,
        ...props
      }: any,
      ref
    ) => (
      <Item
        style={style}
        {...(Boolean(error) && { validateStatus: "error", help: error })}
      >
        {!!label && <p className="text-field-label">{label}</p>}

        <Select
          className="form-select-field-custom"
          suffixIcon={<DropDownOutlined />}
          placeholder={placeholder}
          ref={ref}
          value={value}
          onChange={(value) => {
            setFieldValue("partner_brand_id", value);
            const brandName = options.filter((x: any) => value === x.id)[0].name;
            setFieldValue("name", brandName);
          }}
          {...props}
        >
          {options &&
            options.map((brand: any, idx: number) => (
              <Option key={brand.id} value={brand.id}>
                {brand.name}
              </Option>
            ))}
        </Select>
      </Item>
    )
  );
  useEffect(() => {
    //Fetch the gift card right here and populate the values
    // use current id currentGiftCardtId
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(fetchGiftCard({ id: currentGiftCardId }));
  }, [currentGiftCardId, dispatch]);

  useEffect(() => {
    dispatch(fetchPartnerBrands({ page: 1, per_page: partnerBrandTotalCount }));
    toast.info(
      <>
        <p>Get started with editing your gift card here!</p>
      </>,
      {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
        transition: Slide,
        style: { minWidth: 400 },
      }
    );
  }, []);

  // For handling quill delta
  const handleRTETextChange = (editorValue: any) => {
    setFieldValue("instructions", editorValue);
  };

  const handleEditGiftCardFormSubmit = async (argo: any) => {
    await dispatch(updateGiftCard({ id: params.id, giftCardData: argo }));

    if (reduxStatus !== "failed") {
      navigate("/admin/gift-cards");
    }

  };

  return (
    <MainLayout>
      <div className="community-container">
        <Row>
          <Col xl={20} sm={24}>
            <Divider orientation="left" orientationMargin="0">
              <Text className="community-heading">Edit Gift Card</Text>
            </Divider>
          </Col>
        </Row>


        {reduxStatus !== 'loading' ? <FormikProvider value={formikMain}>
          <form onSubmit={handleSubmit} className="create-community-form">
            <Row>
              <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                <FormikBrandsSelect
                  label="SELECT VENDOR COMPANY"
                  placeholder="Select a company"
                  options={BrandOptions}
                  autoComplete="off"
                  setFieldValue={setFieldValue}
                  error={Boolean(errors.name && touched.name) && errors.name}
                  {...getFieldProps("name")}
                />
              </Col>
              <Col span={8}></Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                <FormikTextField
                  label="GIFT CARD HEADING"
                  className="community-input"
                  autoComplete="off"
                  error={Boolean(errors.title && touched.title) && errors.title}
                  placeholder="Enter heading"
                  {...getFieldProps("title")}
                />
              </Col>
              <Col span={8}></Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col xl={4} >
                <FormikTextField
                  label="GIFT CARD VALUE"
                  className="community-input"
                  autoComplete="off"
                  placeholder="$Value"
                  error={Boolean(errors.value && touched.value) && errors.value}
                  {...getFieldProps("value")}
                />
              </Col>
              <Col span={8}></Col>
              <Col span={8}></Col>
            </Row>

            <Row>
              <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                <FormikTextField
                  label="VENDOR LINK"
                  className="community-input"
                  autoComplete="off"
                  error={
                    Boolean(
                      errors.external_website_link &&
                      touched.external_website_link
                    ) && errors.external_website_link
                  }
                  placeholder="Enter link here"
                  {...getFieldProps("external_website_link")}
                />
              </Col>
              <Col span={8}></Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col
                xl={8}
                lg={10}
                md={12}
                sm={24}
                xs={24}
                style={{ marginBottom: 20 }}
              >
                <Text className="poster-text" style={{ marginBottom: 20 }}>
                  INSTRUCTIONS
                </Text>
                <DraftRTE
                  initialValue={values.instructions}
                  onTextChange={handleRTETextChange}
                  placeholder="Enter your instructions here"
                />
                <div
                  className="error-wrapper"
                  style={{ color: "red", fontSize: 11 }}
                >
                  <ErrorMessage name="instructions" className="error-line" />
                </div>
              </Col>
              <Col span={8}></Col>
              <Col span={8}></Col>
            </Row>


            <Row gutter={8}>
              <Col xl={3} lg={4} md={4} sm={8}>
                <FormikTextField
                  label="SMASH CASH"
                  className="community-input"
                  autoComplete="off"
                  error={
                    Boolean(errors.smash_cash && touched.smash_cash) &&
                    errors.smash_cash
                  }
                  placeholder="Enter smash cash"
                  {...getFieldProps("smash_cash")}
                />
              </Col>
              <Col xl={3} lg={4} md={4} sm={8}>
                <FormikDatePicker
                  label="VALIDITY"
                  showTime
                  style={{ marginBottom: 36 }}
                  format="MM-DD-YYYY"
                  autoComplete="off"
                  setFieldValue={setFieldValue}
                  fieldValue="validity"
                  error={
                    Boolean(errors.validity && touched.validity) &&
                    errors.validity
                  }
                  {...getFieldProps("validity")}
                />
              </Col>
            </Row>
            <FormikRadioInputThreeFields
              label="GIFT CARD TYPE"
              style={{
                marginBottom: 36,
                textTransform: 'capitalize',
              }}
              option1="Code Only"
              option2="Pin Only"
              option3="Code + Pin"
              value1="code"
              value2="pin"
              value3="code_and_pin"
              autoComplete="off"
              error={
                Boolean(errors.format && touched.format) &&
                errors.format
              }
              {...getFieldProps('format')}
            />
            <FormikRadioInputThreeFields
              label="NO. OF GIFT CARD CODES"
              style={{
                marginBottom: 36,
                textTransform: 'capitalize',
              }}
              option1="Single"
              option2="Many (Bulk Upload)"
              value1="single"
              value2="multiple"
              autoComplete="off"
              error={
                Boolean(errors.creation_type && touched.creation_type) &&
                errors.creation_type
              }
              {...getFieldProps('creation_type')}
            />
            {((values.format === "code" && values.creation_type === "single") || (values.format === "code_and_pin" && values.creation_type === "single")) &&

              <Row>
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <FormikTextField
                    label="GIFT CARD CODE"
                    className="community-input"
                    autoComplete="off"
                    error={
                      Boolean(errors.gift_card_code && touched.gift_card_code) &&
                      errors.gift_card_code
                    }
                    placeholder="Enter code"
                    {...getFieldProps("gift_card_code")}
                  />
                </Col>
                <Col span={8}></Col>
                <Col span={8}></Col>
              </Row>
            }
            {((values.format === "pin" && values.creation_type === "single") || (values.format === "code_and_pin" && values.creation_type === "single")) &&

              <Row>
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <FormikTextField
                    label="GIFT CARD PIN"
                    className="community-input"
                    autoComplete="off"
                    error={
                      Boolean(errors.pin && touched.pin) &&
                      errors.pin
                    }
                    placeholder="Enter pin"
                    {...getFieldProps("pin")}
                  />
                </Col>
                <Col span={8}></Col>
                <Col span={8}></Col>
              </Row>
            }
            {values.creation_type === "single" &&
              <Row>
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <FormikTextField
                    label="QUANTITY"
                    className="community-input"
                    autoComplete="off"
                    error={
                      Boolean(errors.quantity && touched.quantity) &&
                      errors.quantity
                    }
                    placeholder="Enter quantity"
                    {...getFieldProps("quantity")}
                  />
                </Col>
              </Row>
            }
            {values.creation_type === "multiple" &&
              <>
                <FormikRadioInputThreeFields
                  label="FILE TYPE"
                  option1="CSV"
                  option2="Excel"
                  value1="csv"
                  value2="xls"
                  autoComplete="off"
                  error={
                    Boolean(errors.file_type && touched.file_type) &&
                    errors.file_type
                  }
                  {...getFieldProps('file_type')}
                />
                <Row>
                  <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                    <Text className="poster-text">UPLOAD CSV</Text>
                    <CSVUpload
                      csv={csv}
                      setCsv={setCsv}
                    />
                  </Col>
                </Row>
              </>
            }

            <Row>
              <Space direction="horizontal">
                <Col>
                  <Button
                    style={{ marginTop: 20 }}
                    disabled={isSubmitting}
                    type="primary"
                    loading={isSubmitting}
                    htmlType="submit"
                    className="primary-btn"
                  >
                    Save Changes
                  </Button>
                </Col>
                <Link to="/admin/gift-cards">
                  <Button
                    type="link"
                    size={"middle"}
                    style={{ color: "gray", marginTop: 36 }}
                  >
                    Cancel
                  </Button>
                </Link>
              </Space>
            </Row>
          </form>
        </FormikProvider> :
          <Row>
            <Col span={24} style={{ paddingTop: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Spin />
            </Col>
          </Row>}
      </div>
    </MainLayout>
  );
};

export default EditGiftCard;