// A "slice" is a collection of Redux reducer logic and actions for a single feature in your app,
// typically defined together in a single file. The name comes from splitting up the root Redux
// state object into multiple "slices" of state.

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { toast } from 'react-toastify';
import { createLeagueApi, deleteLeagueApi, editLeagueApi, fetchLeagueEventsApi, fetchLeaguesApi, fetchLeaguesDetailsApi, fetchSingleLeagueApi } from './API/leaguesApi';

// You need to create a slice first, under the features folder
// that slice should have a createSlice() function and the intial state provided to it
// The slice name is also required, hence give the name: param a proper string

const initialState = {
  status: 'idle',
  data: {},
  leagues: [],
  leagueEvents: [],
  leaguesDetails: []
};

// Writing a thunk for creating leagues
export const createLeague = createAsyncThunk(
  'leagues/create',
  async (data: any, thunkAPI) => {
    const response = await createLeagueApi(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user error
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data) {
      // notify the user he has successfully created the League 
      toast.success('League  has been created successfully', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

//fetching League  slice
export const fetchLeagues = createAsyncThunk(
  'leagues/get',
  async (data: any, thunkAPI) => {
    const response = await fetchLeaguesApi(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user error 
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully fetched Leagues
      toast.success('List of Leagues fetched successfully', {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (response.data.status === 401) {
      notification.error({
        message: `${response.data.statusText} Please Login Again`,
      });
    }
    return response.data;
  }
);

//fetching single League 
export const fetchLeague = createAsyncThunk(
  'league/get',
  async (data: any, thunkAPI) => {
    const response = await fetchSingleLeagueApi(data.id);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user error
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully fetched the League
      toast.success('League fetched successfully', {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

export const deleteLeague = createAsyncThunk(
  'league/delete',
  async (id: any, thunkAPI) => {
    const response = await deleteLeagueApi(id);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user error
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully deleted the League 
      toast.success('League deleted successfully', {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

export const editLeague = createAsyncThunk(
  'leagues/edit',
  async (data: any, thunkAPI) => {
    const response = await editLeagueApi(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the error
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully edited the League 
      toast.success('League has been successfully updated', {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

export const fetchLeagueEvents = createAsyncThunk(
  'leagueEvents/get',
  async (data: any, thunkAPI) => {
    const response = await fetchLeagueEventsApi(data.id);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user error
      notification.error({
        message: response.data.data.error,
      });
    }
    return response.data;
  }
);

//fetching courseNames slice
export const fetchLeaguesDetails = createAsyncThunk(
  "leaguesDetails/get",
  async () => {
    const response = await fetchLeaguesDetailsApi();
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {

      notification.error({
        message: response.data.data.error,
      });
    }
    return response.data;
  }
);

const LeaguesSlice = createSlice({
  name: 'leagues',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createLeague.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchLeagues.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deleteLeague.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(editLeague.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchLeague.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchLeagueEvents.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchLeaguesDetails.pending, (state) => {
        state.status = 'loading';
      })

      .addCase(createLeague.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data = action.payload.data;
      })
      .addCase(fetchLeagues.fulfilled, (state, action) => {
        state.status = 'idle';
        state.leagues = action.payload.data;
      })
      .addCase(deleteLeague.fulfilled, (state, action) => {
        state.status = 'idle';
        state.leagues = action.payload.data;
      })
      .addCase(editLeague.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data = action.payload.data;
      })
      .addCase(fetchLeague.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data = action.payload.data;
      })
      .addCase(fetchLeagueEvents.fulfilled, (state, action) => {
        state.status = 'idle';
        state.leagueEvents = action.payload.data;
      })
      .addCase(fetchLeaguesDetails.fulfilled, (state, action) => {
        state.status = 'idle';
        state.leaguesDetails = action.payload.data;
      })

      .addCase(createLeague.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchLeagues.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(deleteLeague.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchLeague.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(editLeague.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchLeagueEvents.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchLeaguesDetails.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

// Here we are exporting the actions that the component/button will need
// eslint-disable-next-line no-empty-pattern
export const { } = LeaguesSlice.actions;

// please look carefully at the below line, it returns a reducer object that's why it gets added in the store.
export default LeaguesSlice.reducer;
