import { smashersAPI } from '@utils/APIInterceptor';

export async function fetchLeagueRequestsApi(payload: any) {
  const accessToken = localStorage.getItem('accessToken');
  const data: any = await smashersAPI(accessToken).get(
    `api/v1/admin/league_requests?page=${payload.page}&per_page=${payload.per_page}`
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}
