import { Button } from 'antd';
import { Link } from 'react-router-dom';

const CancelBtn = ({ link }) => {
  return (
    <Link to={link}>
      <Button
        type="link"
        size={'middle'}
        style={{ color: 'gray', marginTop: 36 }}
      >
        Cancel
      </Button>
    </Link>
  );
};

export default CancelBtn;
