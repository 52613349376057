// A "slice" is a collection of Redux reducer logic and actions for a single feature in your app,
// typically defined together in a single file. The name comes from splitting up the root Redux
// state object into multiple "slices" of state.

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { signInUser } from './API/authApi';
import { history } from '@config/routes';
import { notification } from 'antd';
import { toast } from 'react-toastify';
// You need to create a slice first, under the features folder
// that slice should have a createSlice() function and the intial state provided to it
// The slice name is also required, hence give the name: param a proper string

const initialState = {
  userData: {},
  isLoggedIn: false,
  status: 'idle',
};

export const loginUser = createAsyncThunk(
  'user/login',
  async ({ email, password }: any, thunkAPI) => {
    const response = await signInUser(email, password);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.success === false) {
      notification.error({
        message: response.data.data.message,
      });
    } else if (response.data.data.success) {
      localStorage.setItem('accessToken', response.data.data.data.access_token);
      // notify the user he is logged in
      toast.success('You are now logged in', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      history.replace('/admin/dashboard');
    }
    return response.data.data;
  }
);

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginUser.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(loginUser.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = 'idle';
        state.isLoggedIn = true;
        state.userData = action.payload.data;
      });
  },
});

// Here we are exporting the actions that the component/button will need
// eslint-disable-next-line no-empty-pattern
export const {} = authSlice.actions;

// export const selectAuth = state => state.auth;
// please look carefully at the below line, it returns a reducer object that's why it gets added in the store.
export default authSlice.reducer;
