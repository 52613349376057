import React, { lazy, Suspense } from "react";

import { Navigate, Route, Routes } from "react-router-dom";
import { createBrowserHistory } from "history";
import CustomRouter from "./CustomRouter";
import ProtectedRoute from "./components/protectedRoute";
import EditEvent from "@screens/admin/events/Edit/EditEvent";
import ViewEvent from "@screens/admin/events/view/ViewEvent";
import EditPost from "@screens/admin/posts/Edit/EditPost";
import ViewPost from "@screens/admin/posts/View/ViewPost";

import ViewCampaign from "@screens/admin/campaigns/view/ViewCampaign";
import EditPartnerBrand from "@screens/admin/partnerBrands/Edit/EditPartnerBrand";
import ViewPartnerBrand from "@screens/admin/partnerBrands/View/ViewPartnerBrand";
import EditGiftCard from "@screens/admin/giftCards/Edit/EditGiftCard";
import ViewGiftCard from "@screens/admin/giftCards/View/ViewGiftCard";
import EditFlight from "@screens/admin/flights/Edit/EditFlight";
import ViewFlight from "@screens/admin/flights/View/ViewFlight";
import EditCourse from "@screens/admin/courses/Edit/EditCourse";
import ViewCourse from "@screens/admin/courses/View/ViewCourse";
import EditTopTracer from "@screens/admin/topTracers/Edit/editTopTracer";
import ViewTopTracer from "@screens/admin/topTracers/View/viewToptracer";
import EditSupportCategory from "@screens/admin/supportCategory/Edit/EditSupportCategory";
import ViewSupportCategory from "@screens/admin/supportCategory/View/ViewSupportCategory";
import EditLeague from "@screens/admin/leagues/Edit/EditLeague";
import ViewLeague from "@screens/admin/leagues/View/ViewLeague";
import StaticLoader from "@components/loaders";

export const history = createBrowserHistory();

const Dashboard = lazy(() => import("@screens/admin/dashboard/Dashboard"));
const AdminLogin = lazy(() => import("@screens/admin/auth/Signin"));
const AdminForgot = lazy(() => import("@screens/admin/auth/ForgotPassword"));
const CreateEvent = lazy(() => import("@screens/admin/events/CreateEvent"));
const CreateCampaign = lazy(() => import("@screens/admin/campaigns/CreateCampaign"));

const CreateSupportCategory = lazy(() => import("@screens/admin/supportCategory/CreateSupportCategory"));
const CreatePartnerBrand = lazy(() => import("@screens/admin/partnerBrands/CreatePartnerBrand"));
const CreateFlight = lazy(() => import("@screens/admin/flights/CreateFlight"));
const CreateCourse = lazy(() => import("@screens/admin/courses/CreateCourse"));
const CreateTopTracer = lazy(() => import("@screens/admin/topTracers/createTopTracer"));
const CreatePost = lazy(() => import("@screens/admin/posts/CreatePost"));
const CreateGiftCard = lazy(() => import("@screens/admin/giftCards/CreateGiftCard"));
const CreateLeague = lazy(() => import("@screens/admin/leagues/CreateLeague"));




// Edit campaign
const EditCampaign = lazy(() => import("@screens/admin/campaigns/edit/EditCampaign"));


const EventsTable = lazy(() => import("@screens/admin/tables/EventsTable"));
const CampaignsTable = lazy(() => import("@screens/admin/tables/CampaignsTable"));
const PartnerBrandsTable = lazy(() => import("@screens/admin/tables/PartnerBrandsTable"));
const GiftCardsTable = lazy(() => import("@screens/admin/tables/GiftCardsTable"));
const TopTracersTable = lazy(() => import("@screens/admin/tables/TopTracersTable"));
const ScoresTable = lazy(() => import("@screens/admin/tables/ScoresTable"));
const SupportTable = lazy(() => import("@screens/admin/tables/SupportTable"));
const SupportCategoryTable = lazy(() => import("@screens/admin/tables/SupportCategoryTable"));
const TransactionsTable = lazy(() => import("@screens/admin/tables/TransactionsTable"));
const PostsTable = lazy(() => import("@screens/admin/tables/PostsTable"));
const FlightsTable = lazy(() => import("@screens/admin/tables/FlightsTable"));
const CoursesTable = lazy(() => import("@screens/admin/tables/CoursesTable"));
const LeaguesTable = lazy(() => import("@screens/admin/tables/LeaguesTable"));
const LeagueRequestsTable = lazy(() => import("@screens/admin/tables/LeagueRequestsTable"));
const PlayersTable = lazy(() => import("@screens/admin/tables/players/PlayersTable"));




const routesView = () => {
  return (
    <Suspense fallback={<StaticLoader />}>
      <CustomRouter history={history} >
        <Routes>
          <Route path="/" element={<Navigate to="/admin/login" />} />
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route path="/admin/forgot-password" element={<AdminForgot />} />

          {/* Private routes come under here */}
          <Route element={<ProtectedRoute />}>
            <Route path="/admin/events" element={<EventsTable />} />
            <Route path="/admin/campaigns" element={<CampaignsTable />} />
            <Route path="/admin/partner-brands" element={<PartnerBrandsTable />} />
            <Route path="/admin/scores" element={<ScoresTable />} />
            <Route path="/admin/transactions" element={<TransactionsTable />} />
            <Route path="/admin/posts" element={<PostsTable />} />
            <Route path="/admin/support" element={<SupportTable />} />
            <Route path="/admin/support-category" element={<SupportCategoryTable />} />
            <Route path="/admin/gift-cards" element={<GiftCardsTable />} />
            <Route path="/admin/courses" element={<CoursesTable />} />
            <Route path="/admin/top-tracer" element={<TopTracersTable />} />
            <Route path="/admin/leagues" element={<LeaguesTable />} />
            <Route path="/admin/league-requests" element={<LeagueRequestsTable />} />
            <Route path="/admin/players" element={<PlayersTable />} />
            <Route path="/admin/flights" element={<FlightsTable />} />


            {/* These things below are MEANT to be protected/private routes */}
            <Route path="/admin/dashboard" element={<Dashboard />} />
            <Route path="/admin/create/event" element={<CreateEvent />} />
            <Route path="/admin/create/campaign" element={<CreateCampaign />} />
            <Route path="/admin/create/post" element={<CreatePost />} />
            <Route path="/admin/create/support-category" element={<CreateSupportCategory />} />
            <Route path="/admin/create/partner-brand" element={<CreatePartnerBrand />} />
            <Route path="/admin/create/gift-card" element={<CreateGiftCard />} />
            <Route path="/admin/create/course" element={<CreateCourse />} />
            <Route path="/admin/create/top-tracer" element={<CreateTopTracer />} />
            <Route path="/admin/create/league" element={<CreateLeague />} />
            <Route path="/admin/create/flight" element={<CreateFlight />} />



            {/* For editing events */}
            <Route path="/admin/edit/event/:id" element={<EditEvent />} />
            <Route path="/admin/edit/campaign/:id" element={<EditCampaign />} />
            <Route path="/admin/edit/post/:id" element={<EditPost />} />
            <Route path="/admin/edit/partner-brand/:id" element={<EditPartnerBrand />} />
            <Route path="/admin/edit/gift-card/:id" element={<EditGiftCard />} />
            <Route path="/admin/edit/course/:id" element={<EditCourse />} />
            <Route path="/admin/edit/top-tracer/:id" element={<EditTopTracer />} />
            <Route path="/admin/edit/support-category/:id" element={<EditSupportCategory />} />
            <Route path="/admin/edit/league/:id" element={<EditLeague />} />
            <Route path="/admin/edit/flight/:id" element={<EditFlight />} />


            {/* For viewing events */}
            <Route path="/admin/view/event/:id" element={<ViewEvent />} />
            <Route path="/admin/view/post/:id" element={<ViewPost />} />
            <Route path="/admin/view/campaign/:id" element={<ViewCampaign />} />
            <Route path="/admin/view/partner-brand/:id" element={<ViewPartnerBrand />} />
            <Route path="/admin/view/gift-card/:id" element={<ViewGiftCard />} />
            <Route path="/admin/view/course/:id" element={<ViewCourse />} />
            <Route path="/admin/view/top-tracer/:id" element={<ViewTopTracer />} />
            <Route path="/admin/view/support-category/:id" element={<ViewSupportCategory />} />
            <Route path="/admin/view/league/:id" element={<ViewLeague />} />
            <Route path="/admin/view/flight/:id" element={<ViewFlight />} />


          </Route>
        </Routes>
      </CustomRouter>
    </Suspense>
  );
};

export default routesView;
