import { FormikTextField, FormikRadioInput, FormikCheckboxInput } from '@components/inputFeilds/InputFeilds';
import { MainLayout } from '@components/layouts';
import Logo from '@assets/images/Logo.png';
import { Button, Col, Divider, Image, Input, Row, Space, Spin, Typography, } from 'antd';
import { ErrorMessage, Field, FieldArray, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector, } from "react-redux";
import { AppDispatch } from "@config/storeConfig";
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Slide } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';

import '../styles.css';
import { fetchCourse, updateCourse } from '@slices/courseSlice';
import ImageUpload from '@components/imageUpload/ImageUpload';
import { DeleteOutlined } from '@ant-design/icons';

const { Text } = Typography;

const EditCourse = () => {
  const params = useParams();
  const currentCourseId = params?.id;
  const [previewCourseLogo, setPreviewCourseLogo] = useState(true);
  const [previewCourseImage, setPreviewCourseImage] = useState(true);
  const liveCourseState = useSelector((state: any) => state.courses.data.data);
  const reduxStatus = useSelector((state: any) => state.courses.status);
  const [courseImageBase, setCourseImageBase] = useState<any>("");
  const [courseLogoBase, setCourseLogoBase] = useState<any>("");
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();



  const eventsTypeOptions = [
    { label: 'Strokeplay', value: 'strokeplay' },
    { label: 'Closest To The Pin', value: 'closest_to_the_pin' },
    { label: 'Scramble', value: 'scramble' },
    { label: 'Longest Drive', value: 'longest_drive' }
  ]

  const holeFormatOptions = [
    { label: '3 Hole', value: '3' },
    { label: '6 Hole', value: '6' },
    { label: '9 Hole', value: '9' },
    { label: '12 Hole', value: '12' },
    { label: '15 Hole', value: '15' },
    { label: '18 Hole', value: '18' },
  ]

  const reduce = (prev: any, next: any) => Number(prev) + Number(next);


  const formikMain = useFormik({
    initialValues: {
      name: liveCourseState && liveCourseState.name ? liveCourseState.name : '',
      address:
        liveCourseState && liveCourseState.address
          ? liveCourseState.address
          : '',
      active:
        liveCourseState && liveCourseState.active
          ? liveCourseState.active
            ? 'Yes'
            : 'No'
          : 'No',
      supported_events:
        liveCourseState &&
          liveCourseState.supported_events &&
          liveCourseState.supported_events.length
          ? liveCourseState.supported_events
          : [],
      supported_holes:
        liveCourseState &&
          liveCourseState.supported_holes &&
          liveCourseState.supported_holes.length
          ? liveCourseState.supported_holes.map((x: any) => x.number.toString())
          : [],
      total_holes:
        liveCourseState && liveCourseState.total_holes
          ? liveCourseState.total_holes.toString()
          : '',
      tee_box: liveCourseState && liveCourseState.tee_boxes && liveCourseState.tee_boxes.length
        ? liveCourseState.tee_boxes
        : [],
      hole:
        liveCourseState && liveCourseState.holes ? liveCourseState.holes : [],
      out:
        liveCourseState && liveCourseState.holes && liveCourseState.holes.length
          ? liveCourseState.holes
            .slice(0, 9)
            .map((x: any) => x.par)
            .reduce(reduce)
          : '',
      in:
        liveCourseState && liveCourseState.holes && liveCourseState.holes.length
          ? liveCourseState.holes
            .slice(9)
            .map((x: any) => x.par)
            .reduce(reduce)
          : '',
    },
    enableReinitialize: true,

    onSubmit: async (values) => {
      setSubmitting(true);
      const courseCreationData = {
        name: values.name,
        address: values.address,
        active: values.active === "Yes" ? true : false,
        supported_events: values.supported_events,
        supported_holes: values.supported_holes,
        total_holes: values.total_holes,
        hole: values.hole,
        tee_box: values.tee_box,
      };
      const courseImageParam = Boolean(courseImageBase);
      const courseLogoParam = Boolean(courseLogoBase);
      if (courseImageParam || courseLogoParam) {
        const addcourseImage = { course_image: courseImageBase };
        const addcourseLogo = { course_logo: courseLogoBase };
        await handleEditCourseFormSubmit({
          ...courseCreationData,
          ...addcourseImage,
          ...addcourseLogo,
        });
      } else {

        await handleEditCourseFormSubmit(courseCreationData);
      }

      setSubmitting(false);
      resetForm();
      navigate("/admin/courses");

    },
    validationSchema: yup.object({
      name: yup.string().required('*Required Course Name'),
      address: yup.string().required('*Required Course Address'),
      active: yup.string().required("*Select One"),
      supported_events: yup.array().required("*Required Supported Events"),
      supported_holes: yup.array().required("*Required Supported Formats"),
      total_holes: yup.string().required("*Select Holes"),
      tee_box: yup.array().of(
        yup.object().shape({
          name: yup
            .string()
            .required("*Required Name"),
          box_type: yup
            .string()
            .required("*Required TeeBox"),
        })
      ),


    }),
  });

  const {
    errors,
    getFieldProps,
    handleSubmit,
    touched,
    values,
    setFieldValue,
    resetForm,
    setSubmitting,
    isSubmitting
  } = formikMain;

  const handleEditCourseFormSubmit = async (argo: any) => {
    await dispatch(updateCourse({ id: params.id, courseData: argo }));
  }

  useEffect(() => {
    //Fetch the event right here and populate the values
    // use current id currentEventId
    window.scrollTo({ top: 0, behavior: 'smooth' });
    dispatch(fetchCourse({ id: currentCourseId }));
  }, [currentCourseId, dispatch]);

  useEffect(() => {
    toast.info(
      <>
        <p>Edit your course right here</p>
      </>,
      {
        position: 'bottom-right',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
        transition: Slide,
        style: { minWidth: 200 },
      }
    );
  }, []);

  const reducer = (prev: any, next: any) => Number(prev) + Number(next)

  const frontHolesSum = values.hole.length && values.hole.slice(0, 9).map((x: any) => x.par).reduce(reducer)
  const backHolesSum = values.hole.length && values.hole.slice(9,).map((x: any) => x.par).reduce(reducer)


  return (
    <MainLayout>
      <div className='community-container'>
        <Row>
          <Col xl={20} sm={24} >
            <Divider orientation="left" orientationMargin="0" >
              <Text className="community-heading">Edit Course</Text>
            </Divider>
          </Col>
        </Row>
        {reduxStatus !== 'loading' ? (
          <FormikProvider value={formikMain}>
            <form onSubmit={handleSubmit} className='create-community-form'>
              <Row>
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <FormikTextField
                    label="COURSE NAME"
                    className="community-input"
                    autoComplete="off"
                    error={Boolean(errors.name && touched.name) && errors.name}
                    placeholder="Enter course name"
                    {...getFieldProps('name')}
                  />
                </Col>
                <Col span={8}></Col>
                <Col span={8}></Col>
              </Row>
              <Row>
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <FormikTextField
                    label="COURSE ADDRESS"
                    type="textarea"
                    autoComplete="off"
                    error={Boolean(errors.address && touched.address) && errors.address}
                    placeholder="Enter course address"
                    {...getFieldProps('address')}
                  />
                </Col>
                <Col span={8}></Col>
                <Col span={8}></Col>
              </Row>
              <Row>
                <Col xl={24} lg={12} sm={12} xs={24}>
                  <FormikRadioInput
                    label="IS ACTIVE"
                    option1="Yes"
                    option2="No"
                    autoComplete="off"
                    error={
                      Boolean(errors.active && touched.active) &&
                      errors.active
                    }
                    {...getFieldProps("active")}
                  />
                </Col>
              </Row>
              <Row>
                <Col xl={24} lg={12} sm={12} xs={24}>
                  <FormikCheckboxInput
                    label="EVENTS SUPPORTED"
                    options={eventsTypeOptions}
                    autoComplete="off"
                    error={
                      Boolean(errors.supported_events && touched.supported_events) &&
                      errors.supported_events
                    }
                    {...getFieldProps("supported_events")}
                    onChange={(value: string) => {
                      setFieldValue("supported_events", value);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col xl={24} lg={12} sm={12} xs={24}>
                  <FormikCheckboxInput
                    label="SUPPORTED FORMATS"
                    options={holeFormatOptions}
                    autoComplete="off"
                    error={
                      Boolean(errors.supported_holes && touched.supported_holes) &&
                      errors.supported_holes
                    }
                    {...getFieldProps("supported_holes")}
                    onChange={(value: string) => {
                      setFieldValue("supported_holes", value);
                    }}
                  />
                </Col>
              </Row>
              <Row >
                <Col xl={8} lg={10} md={12} sm={24} xs={24} onClick={() => setPreviewCourseImage(false)}>
                  <Text className="poster-text">COURSE IMAGE</Text>
                  <ImageUpload
                    logoBase={courseImageBase}
                    setLogoBase={setCourseImageBase}
                  />
                </Col>
                <Col xl={8} lg={10} md={12} sm={24} xs={24} className="preview-img">
                  {previewCourseImage && liveCourseState && (
                    <Image
                      style={{
                        width: 80,
                        height: 80,
                        objectFit: 'contain',
                        marginRight: 10,
                        background:
                          liveCourseState.course_image === null
                            ? '#141879'
                            : 'unset',
                      }}
                      src={
                        liveCourseState.course_image === null
                          ? Logo
                          : liveCourseState.course_image
                      }
                    />
                  )}
                </Col>
                <Col span={8}></Col>
              </Row>

              <Row style={{ marginTop: 18 }} >
                <Col xl={8} lg={10} md={12} sm={24} xs={24} onClick={() => setPreviewCourseLogo(false)}>
                  <Text className="poster-text">COURSE LOGO</Text>
                  <ImageUpload
                    logoBase={courseLogoBase}
                    setLogoBase={setCourseLogoBase}
                  />
                </Col>
                <Col xl={8} lg={10} md={12} sm={24} xs={24} className="preview-img">
                  {previewCourseLogo && liveCourseState && (
                    <Image
                      style={{
                        width: 80,
                        height: 80,
                        objectFit: 'contain',
                        marginRight: 10,
                        background:
                          liveCourseState.course_logo === null
                            ? '#141879'
                            : 'unset',
                      }}
                      src={
                        liveCourseState.course_logo === null
                          ? Logo
                          : liveCourseState.course_logo
                      }
                    />
                  )}
                </Col>
                <Col span={8}></Col>
              </Row>

              <Row>
                <Col xl={24} lg={12} sm={12} xs={24}>
                  <FormikRadioInput
                    label="TOTAL HOLES"
                    style={{ marginTop: 18 }}
                    option1="9"
                    option2="18"
                    autoComplete="off"
                    error={
                      Boolean(errors.total_holes && touched.total_holes) &&
                      errors.total_holes
                    }
                    {...getFieldProps("total_holes")}
                  />
                </Col>
              </Row>
              <div className='holes-top-container'>
                <Text className='holes-top-container-left'>FRONT</Text>
                <div className='holes-top-container-right'>
                  <Text>{`${values.total_holes} Holes`}</Text>
                  <Text>{`In:${values.total_holes === "9" ? '' : values.in}`}</Text>
                  <Text>{`Out:${values.out}`}</Text>
                  <Text>{`Total:${values.total_holes === "9" ? values.out : values.out + values.in}`}</Text>
                </div>
              </div>

              <div className='holes-container'>
                <div className='holes-number'>
                  <p>HOLE</p>
                  {[...Array(9)].map((_, index: any) => {
                    return (
                      <Text>{index + 1}</Text>
                    )
                  })}
                  <div style={{ borderLeft: '1px solid #000000', marginLeft: 10 }}><Text>OUT</Text></div>

                </div>
                <Field
                  as='text'
                  onBlur={() => setFieldValue('out', frontHolesSum)
                  }
                >
                  <div className='holes-input'>
                    <p>PAR</p>
                    {[...Array(9)].map((_, index: any) => {
                      return (
                        <Input  {...getFieldProps(`hole[${index}].par`)} type='number' onChange={(e: any) => e.target.value = Math.abs(Number(e.target.value))} />
                      )
                    })}
                    <div className="out-hole"><Input {...getFieldProps('out')} disabled /></div>
                  </div>
                  {/* <ErrorMessage
                  className="error-line"
                  name={'hole'}
                /> */}
                </Field>
              </div>
              {values.total_holes === "18" &&
                <>
                  <div className='holes-top-container'>
                    <Text className='holes-top-container-left'>BACK</Text>
                  </div>
                  <div className='holes-container'>
                    <div className='holes-number'>
                      <p>HOLE</p>
                      {[...Array(9)].map((_, index: any) => {
                        return (
                          <Text>{index + 10}</Text>
                        )
                      })}
                      <div style={{ borderLeft: '1px solid #000000', marginLeft: 10 }} ><Text>IN</Text></div>

                    </div>
                    <Field
                      as='text'
                      onBlur={() =>
                        setFieldValue('in', backHolesSum)
                      }
                    >
                      <div className='holes-input'>
                        <p>PAR</p>
                        {[...Array(9)].map((_, index: any) => {
                          return (
                            <Input  {...getFieldProps(`hole[${index + 9}].par`)} type='number' onChange={(e: any) => e.target.value = Math.abs(Number(e.target.value))} />
                          )
                        })}
                        <div className="out-hole"><Input {...getFieldProps('in')} disabled /></div>
                      </div>
                      {/* <ErrorMessage
                      className="error-line"
                      name={'hole'}
                    /> */}
                    </Field>
                  </div>
                </>
              }

              <Text className="poster-text">TEE BOXES</Text>
              <FieldArray
                name="tee_box"
                render={(arrayHelpers) => (
                  <div className="rewards-table-wrapper">
                    <div className="table-columns" style={{ marginTop: 18 }}>
                      <Text className="column">Front/Back</Text>
                      <Text className="column">Tee Box Name</Text>
                    </div>
                    {values.tee_box.map(
                      (formItem: any, index: any) => (
                        <div style={{ display: 'flex' }}>
                          <div
                            className="input-container"
                          >
                            <Field
                              as="select"
                              {...getFieldProps(
                                `tee_box[${index}].box_type`
                              )}
                            >
                              <option value="" selected disabled hidden>
                                Select Tee Box
                              </option>
                              <option value="Front" key="Front">Front</option>
                              <option value="Back 1" key="Back 1">Back 1</option>
                              <option value="Back 2" key="Back 2">Back 2</option>
                              <option value="Back 3" key="Back 3">Back 3</option>
                            </Field>
                          </div>
                          <div key={index} className="rewards-table-row">
                            <div className="input-container">
                              <Field
                                {...getFieldProps(
                                  `tee_box.${index}.name`
                                )}
                              />
                              <div className="error-wrapper">
                                <ErrorMessage
                                  className="error-line"
                                  name={`tee_box.${index}.name`}
                                />
                              </div>
                            </div>


                            <div className="rowDeleteBtn">
                              <DeleteOutlined onClick={() =>
                                arrayHelpers.remove(index)
                              } // Deletes a row
                              />
                            </div>
                          </div>
                        </div>
                      )
                    )}
                    <div style={{ marginTop: 16, textAlign: 'end' }}>
                      <Button
                        type="primary"
                        onClick={() => {
                          arrayHelpers.push({
                            name: "",
                            box_type: "",
                          })
                        }
                        } // insert an empty string
                      >
                        + Add Tee Box
                      </Button>

                    </div>
                  </div>
                )}
              />
              <Row>
                <Space direction="horizontal" >
                  <Col>
                    <Button
                      disabled={isSubmitting}
                      loading={isSubmitting}
                      type="primary"
                      htmlType="submit"
                      className="primary-btn"
                    >
                      Save Changes
                    </Button>
                  </Col>
                  <Link to="/admin/courses">
                    <Button
                      type="link"
                      size={"middle"}
                      style={{ color: "gray", marginTop: 18 }}
                    >
                      Cancel
                    </Button>
                  </Link>
                </Space>
              </Row>
            </form>
          </FormikProvider >
        ) : (
          <Row>
            <Col
              span={24}
              style={{
                paddingTop: 100,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Spin />
            </Col>
          </Row>
        )}
      </div >
    </MainLayout >
  );
};

export default EditCourse;
