// A "slice" is a collection of Redux reducer logic and actions for a single feature in your app,
// typically defined together in a single file. The name comes from splitting up the root Redux
// state object into multiple "slices" of state.

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { notification } from 'antd';
import { toast } from 'react-toastify';
import {
  createPostApi,
  deletePostApi,
  editPostApi,
  fetchPostApi,
  fetchPostsCSVApi,
  fetchSinglePostApi,
} from './API/postsApi';

// You need to create a slice first, under the features folder
// that slice should have a createSlice() function and the intial state provided to it
// The slice name is also required, hence give the name: param a proper string

const initialState = {
  status: 'idle',
  data: {},
  posts: [],
  csv: []
};

// Writing a thunk for creating posts
export const createPost = createAsyncThunk(
  'posts/create',
  async (data: any, thunkAPI) => {
    const response = await createPostApi(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user he has successfully created the post
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data) {
      // notify the user he has successfully created the post
      toast.success('Post has been created successfully', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

//fetching posts slice
export const fetchPosts = createAsyncThunk(
  'posts/get',
  async (data: any, thunkAPI) => {
    const response = await fetchPostApi(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user he has successfully created the post
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully fetched posts
      toast.success('List of posts fetched successfully', {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (response.data.status === 401) {
      notification.error({
        message: `${response.data.statusText} Please Login Again`,
      });
    }
    return response.data;
  }
);

//fetching single post
export const fetchPost = createAsyncThunk(
  'post/get',
  async (data: any, thunkAPI) => {
    const response = await fetchSinglePostApi(data.id);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user he has successfully fetched the post
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully fetched the post
      toast.success('Post fetched successfully', {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

export const deletePost = createAsyncThunk(
  'posts/delete',
  async (id: any, thunkAPI) => {
    const response = await deletePostApi(id);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user he has successfully deleted the post
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully deleted the post
      toast.success('Post deleted successfully', {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

export const editPost = createAsyncThunk(
  'posts/edit',
  async (data: any, thunkAPI) => {
    const response = await editPostApi(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user he has successfully edited the post
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully edited the post
      toast.success('Post has been successfully updated', {
        position: 'top-right',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

export const fetchPostsCSV = createAsyncThunk(
  "postsCSV/get",
  async () => {
    const response = await fetchPostsCSVApi();
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user he has successfully fetched players
      notification.error({
        message: response.data.data.error,
      });
    }
    return response.data;
  }
);

const PostsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createPost.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPosts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(deletePost.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(editPost.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPost.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchPostsCSV.pending, (state) => {
        state.status = 'loading';
      })

      .addCase(createPost.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data = action.payload.data;
      })
      .addCase(fetchPosts.fulfilled, (state, action) => {
        state.status = 'idle';
        state.posts = action.payload.data;
      })
      .addCase(deletePost.fulfilled, (state, action) => {
        state.status = 'idle';
        state.posts = action.payload.data;
      })
      .addCase(editPost.fulfilled, (state, action) => {
        state.status = 'idle';
        state.posts = action.payload.data;
      })
      .addCase(fetchPost.fulfilled, (state, action) => {
        state.status = 'idle';
        state.data = action.payload.data;
      })
      .addCase(fetchPostsCSV.fulfilled, (state, action) => {
        state.status = "idle";
        state.csv = action.payload.data;
      })

      .addCase(createPost.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchPosts.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(deletePost.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchPost.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(editPost.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchPostsCSV.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

// Here we are exporting the actions that the component/button will need
// eslint-disable-next-line no-empty-pattern
export const { } = PostsSlice.actions;

// please look carefully at the below line, it returns a reducer object that's why it gets added in the store.
export default PostsSlice.reducer;