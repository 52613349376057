import { smashersAPI } from '@utils/APIInterceptor';
import { json } from 'stream/consumers';

export async function createSupportCategoryApi(supportCategoryData: any) {
  const accessToken = localStorage.getItem('accessToken');

  const arrayEmail = supportCategoryData.recipients.split(',');

  const data: any = await smashersAPI(accessToken).post(
    'api/v1/admin/support_categories',
    {
      ...supportCategoryData,
      emails: arrayEmail,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function fetchSupportCategoryApi(payload: any) {
  const accessToken = localStorage.getItem('accessToken');
  const data: any = await smashersAPI(accessToken).get(
    `api/v1/admin/support_categories?page=${payload.page}&per_page=${payload.per_page}`
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function deleteSupportCategoryApi(id: any) {
  const accessToken = localStorage.getItem('accessToken');
  const data: any = await smashersAPI(accessToken).delete(
    `api/v1/admin/support_categories/${id}`
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function fetchSingleSupportCategoryApi(id: any) {
  const accessToken = localStorage.getItem('accessToken');
  const data: any = await smashersAPI(accessToken).get(
    `api/v1/admin/support_categories/${id}`
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function editSupportCategoryApi(payload: any) {
  const accessToken = localStorage.getItem('accessToken');
  const arrayEmail = payload.supportCategoryData.recipients.split(',');

  const data: any = await smashersAPI(accessToken).put(
    `api/v1/admin/support_categories/${payload.id}`,
    {
      ...payload.supportCategoryData,
      emails: arrayEmail,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}
