import { message, Space, Upload, UploadProps, Typography, Button } from 'antd';
import { toast } from 'react-toastify';
import Dragger from 'antd/lib/upload/Dragger';
import { CloudUploadOutlined, DeleteOutlined } from '@ant-design/icons';
import { Slide } from 'react-toastify';
import { ReactComponent as EditIcon } from '@assets/icons/edit-icon.svg';
const { Text } = Typography




const ImageUpload = ({ logoBase, setLogoBase, uploadIcon }: any) => {

  // Function for reading image from system
  const getFile = (file: any, cb: Function) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    setLogoBase(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      console.warn("Error: ", error);
    };
  };

  const removeLogo = () => {
    toast.error(
      <>
        <p>Image removed</p>
      </>,
      {
        position: "bottom-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
        transition: Slide,
      }
    );
    setLogoBase("");
  };

  // Fake upload
  function uploadMimic(milliseconds: number) {
    return new Promise(resolve => setTimeout(resolve, milliseconds));
  }
  const uploadImage = async (options: any) => {
    const { onSuccess, onError, file, } = options;
    try {
      uploadMimic(2000)
      onSuccess("Ok", file);
    } catch (err) {
      console.log("Error: ", err);
      onError({ err });
    }
  };


  const props: UploadProps = {
    showUploadList: false,
    customRequest: uploadImage,
    beforeUpload(file, FileList) {
      const isPNG = file.type === "image/png" || file.type === "image/jpeg";
      if (!isPNG) {
        message.error(`${file.name} is not a png file`);
      }
      return isPNG || Upload.LIST_IGNORE;
    },
    onChange(info) {
      getFile(info.file.originFileObj, () => { });
    },
    onRemove: () => {
      removeLogo();
    },
  };

  return (
    <>
      <Dragger {...props} maxCount={1}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <div>
            <p className="ant-upload-text">
              Select a file or drag and drop Image here
            </p>
            <p className="ant-upload-hint">
              Images Only, file size no more than 400*400px & &#60; 5MB
              Recommended PNG with Transparent Background and other than white variant of your logo is preferred.
            </p>
          </div>
          <div className="upload-container">
            {uploadIcon ?
              <>
                <p>SELECT</p>
                <CloudUploadOutlined />
              </> :
              <>
                <p>EDIT</p>
                <EditIcon width="10px" stroke='#004992' />
              </>
            }
          </div>
        </div>
      </Dragger>
      {logoBase && (
        <Space
          direction="horizontal"
          style={{
            justifyContent: "space-between",
            width: "100%",
            paddingTop: 10,
          }}
        >
          <Space>
            <Text>{logoBase.name}</Text>
          </Space>
          <Button
            size="small"
            onClick={removeLogo}
            danger
            style={{ border: "none" }}
          >
            <DeleteOutlined />
          </Button>
        </Space>
      )}
    </>

  )
}

export default ImageUpload;