import { Button, InputNumber } from 'antd';


const CounterInputField = ({
  label,
  onIncrement,
  onDecrement,
  onInputChange,
  onInputClick,
  value,
  decrementDisabled
}: any) => {
  return (
    <div className='counter-input'>
      <div className='text-field-label'>{label}</div>
      <Button
        onClick={onDecrement}
        disabled={decrementDisabled}
      >
        -
      </Button>
      <InputNumber
        min={1}
        controls={false}
        value={value}
        onChange={onInputChange}
        onClick={onInputClick}
      />
      <Button
        onClick={onIncrement}
      >
        +
      </Button>
      {value === null &&
        <p style={{ color: 'red', fontSize: 11 }}>*Required</p>
      }
    </div>
  )
}

export default CounterInputField;