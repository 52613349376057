import { FormikDatePicker, FormikRadioInputThreeFields, FormikTextField } from '@components/inputFeilds/InputFeilds';
import React, { useEffect, useState, } from "react";
import { MainLayout } from '@components/layouts';
import Logo from '@assets/images/Logo.png';
import { Button, Col, Divider, Image, Row, Space, Spin, Typography } from 'antd';
import { ErrorMessage, FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "@config/storeConfig";
import { Slide, toast } from "react-toastify";
import { Link, useNavigate, useParams } from 'react-router-dom';
import ImageUpload from '@components/imageUpload/ImageUpload';
import { editLeague, fetchLeague } from '@slices/leaguesSlice';
import DraftRTE from '@components/rte/DraftRTE';

const { Text } = Typography;

const EditLeague = () => {

  const dispatch = useDispatch<AppDispatch>();
  const [logoBase, setLogoBase] = useState<any>("");
  const navigate = useNavigate();
  const [previewLogo, setPreviewLogo] = useState(true);
  const params = useParams();
  const currentLeagueId = params?.id;
  const liveLeagueState = useSelector(
    (state: any) => state.leagues.data.data
  );
  const reduxStatus = useSelector((state: any) => state.leagues.status);

  const formikMain = useFormik({
    initialValues: {
      name: liveLeagueState?.name ? liveLeagueState.name : "",
      start_date: liveLeagueState?.start_date ? liveLeagueState.start_date : "",
      end_date: liveLeagueState?.end_date ? liveLeagueState.end_date : "",
      league_poster: '',
      manager_name: liveLeagueState?.manager_name ? liveLeagueState.manager_name : "",
      manager_email: liveLeagueState?.manager_email ? liveLeagueState.manager_email : "",
      details: liveLeagueState?.details ? liveLeagueState.details : "",
      league_points: liveLeagueState?.league_points ? liveLeagueState.league_points : "",
      smash_cash: liveLeagueState?.smash_cash ? liveLeagueState.smash_cash : "",
      paid_entry: liveLeagueState?.paid_entry ? true : false,
      entry_type: liveLeagueState?.entry_type ? liveLeagueState.entry_type : "",
      is_featured: liveLeagueState?.is_featured ? true : false,
    },

    enableReinitialize: true,
    onSubmit: async (values) => {
      setSubmitting(true);
      const leaguesData = {
        name: values.name,
        start_date: values.start_date,
        end_date: values.end_date,
        manager_name: values.manager_name,
        manager_email: values.manager_email,
        details: values.details,
        league_points: values.league_points,
        smash_cash: values.smash_cash,
        paid_entry: values.paid_entry,
        entry_type: values.entry_type,
        is_featured: values.is_featured
      };
      const logoParam = Boolean(logoBase);
      if (logoParam) {
        const addLogo = { league_poster: logoBase };
        await handleEditLeagueFormSubmit({
          ...leaguesData,
          ...addLogo,
        });
      } else {

        await handleEditLeagueFormSubmit(leaguesData);
      }
      setSubmitting(false);
      resetForm();
      navigate("/admin/leagues");
    },
    validationSchema: yup.object({
      name: yup.string().required('*Required League Name'),
      start_date: yup.string().required('*Required Start Time'),
      end_date: yup.string().required('*Required End Time'),
      manager_name: yup.string().required('*Required Manager Name'),
      manager_email: yup.string().matches(/^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Enter Valid Email'),
      details: yup.string().required('*Required Rules'),
      league_points: yup.number()
        .min(0, '*Must be >= 0')
        .typeError('*Should be a number')
        .required('*League Points required'),
      smash_cash: yup.number()
        .min(0, '*Must be >= 0')
        .typeError('*Should be a number')
        .required('*Smash Cash required'),
      paid_entry: yup.string().required('*Required'),
      entry_type: yup.string().required('*Required Entry Type'),
      is_featured: yup.string().required('*Required')
    }),
  });

  const {
    errors,
    getFieldProps,
    handleSubmit,
    touched,
    isSubmitting,
    setSubmitting,
    setFieldValue,
    resetForm,
    values
  } = formikMain;

  useEffect(() => {
    //Fetch the league right here and populate the values
    // use current id currentLeagueId
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(fetchLeague({ id: currentLeagueId }));
  }, [currentLeagueId, dispatch]);

  useEffect(() => {

    toast.info(
      <>
        <p>Get started with Editing your league here!</p>
      </>,
      {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
        transition: Slide,
        style: { minWidth: 400 },
      }
    );
  }, []);

  // For handling quill data
  const handleRTETextChange = (editorValue: any) => {
    setFieldValue('details', editorValue);
  };

  const handleEditLeagueFormSubmit = async (argo: any) => {
    await dispatch(editLeague({ id: params.id, leaguesData: argo }));

    if (reduxStatus !== "failed") {
      navigate("/admin/leagues");
    }

  };

  return (
    <MainLayout>
      <div className='community-container'>
        <Row>
          <Col xl={20} sm={24} >
            <Divider orientation="left" orientationMargin="0" >
              <Text className="community-heading">Edit League</Text>
            </Divider>
          </Col>
        </Row>

        {reduxStatus !== 'loading' ? <FormikProvider value={formikMain}>
          <form onSubmit={handleSubmit} className='create-community-form'>
            <Row>
              <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                <FormikTextField
                  label="LEAGUE NAME"
                  className="community-input"
                  autoComplete="off"
                  error={Boolean(errors.name && touched.name) && errors.name}
                  placeholder="Enter league name"
                  {...getFieldProps('name')}
                />
              </Col>
              <Col span={8}></Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                <FormikDatePicker
                  format="dddd, MM/DD/YYYY h:mm:ss a"
                  showTime
                  label="LEAGUE START DATE"
                  style={{ marginBottom: 36 }}
                  autoComplete="off"
                  fieldValue="start_date"
                  setFieldValue={setFieldValue}
                  error={
                    Boolean(
                      errors.start_date &&
                      touched.start_date
                    ) && errors.start_date
                  }
                  {...getFieldProps('start_date')}
                />

              </Col>
              <Col span={8}></Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                <FormikDatePicker
                  format="dddd, MM/DD/YYYY h:mm:ss a"
                  showTime
                  label="LEAGUE END DATE"
                  style={{ marginBottom: 36 }}
                  autoComplete="off"
                  fieldValue="end_date"
                  setFieldValue={setFieldValue}
                  error={
                    Boolean(
                      errors.end_date &&
                      touched.end_date
                    ) && errors.end_date
                  }
                  {...getFieldProps('end_date')}
                />

              </Col>
              <Col span={8}></Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col xl={8} lg={10} md={12} sm={24} xs={24} onClick={() => setPreviewLogo(false)}>
                <Text className="poster-text" >LEAGUE POSTER</Text>
                <ImageUpload
                  logoBase={logoBase}
                  setLogoBase={setLogoBase}
                  uploadIcon={true}
                />
              </Col>

              <Col
                xl={8}
                lg={10}
                md={12}
                sm={24}
                xs={24}
                className="preview-img"
              >
                {previewLogo && liveLeagueState && (
                  <Image
                    style={{
                      width: 80,
                      height: 80,
                      objectFit: 'contain',
                      marginRight: 10,
                      marginTop: 36,
                      background:
                        liveLeagueState?.league_poster === null
                          ? '#141879'
                          : 'unset',
                    }}
                    src={
                      liveLeagueState?.league_poster === null
                        ? Logo
                        : liveLeagueState?.league_poster
                    }
                  />
                )}
              </Col>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                <FormikTextField
                  label="LEAGUE MANAGER NAME"
                  className="community-input"
                  autoComplete="off"
                  error={Boolean(errors.manager_name && touched.manager_name) && errors.manager_name}
                  placeholder="Enter manager name"
                  {...getFieldProps('manager_name')}
                />
              </Col>
              <Col span={8}></Col>
              <Col span={8}></Col>
            </Row>
            <Row>
              <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                <FormikTextField
                  label="LEAGUE MANAGER EMAIL"
                  className="community-input"
                  autoComplete="off"
                  error={Boolean(errors.manager_email && touched.manager_email) && errors.manager_email}
                  placeholder="Enter manager email"
                  {...getFieldProps('manager_email')}
                />
              </Col>
              <Col span={8}></Col>
              <Col span={8}></Col>
            </Row>

            <Row>
              <Col
                xl={8}
                lg={10}
                md={12}
                sm={24}
                xs={24}
                style={{ marginBottom: 30 }}
              >
                <Text className="poster-text" style={{ marginBottom: '12px' }}>
                  LEAGUE DETAILS & RULES
                </Text>
                <DraftRTE
                  initialValue={values.details}
                  onTextChange={handleRTETextChange}
                  placeholder="Enter your league details & rules here"
                />
                <div
                  className="error-wrapper"
                  style={{ color: 'red', fontSize: 11 }}
                >
                  <ErrorMessage name="details" className="error-line" />
                </div>
              </Col>
            </Row>
            <Row gutter={4}>
              <Col xl={4} md={6} sm={12} xs={24}>
                <FormikTextField
                  label="TOTAL LEAGUE POINTS"
                  style={{ marginBottom: 36 }}
                  autoComplete="off"
                  error={
                    Boolean(errors.league_points && touched.league_points) &&
                    errors.league_points
                  }
                  placeholder="Enter league points"
                  {...getFieldProps('league_points')}
                />
              </Col>

              <Col xl={4} md={6} sm={12} xs={24}>
                <FormikTextField
                  label="SMASH CASH"
                  style={{ marginBottom: 36 }}
                  autoComplete="off"
                  error={
                    Boolean(errors.smash_cash && touched.smash_cash) &&
                    errors.smash_cash
                  }
                  placeholder="Enter smash cash"
                  {...getFieldProps('smash_cash')}
                />
              </Col>

            </Row>
            <FormikRadioInputThreeFields
              label="LEAGUE ENTRY"
              style={{
                marginBottom: 36,
                textTransform: 'capitalize',
              }}
              option1="Free"
              option2={<div>
                <div>Paid</div>
                <div style={{ fontSize: 10 }}>(Coming Soon)</div>
              </div>}
              value1={false}
              value2={true}
              optionDisable={true}
              autoComplete="off"
              error={
                Boolean(errors.paid_entry && touched.paid_entry) &&
                errors.paid_entry
              }
              {...getFieldProps('paid_entry')}
            />
            <FormikRadioInputThreeFields
              label="LEAGUE ENTRY TYPE"
              style={{
                marginBottom: 36,
                textTransform: 'capitalize',
              }}
              option1="Pay-Per-Event"
              option2={<div>
                <div>Memberships</div>
                <div style={{ fontSize: 10 }}>(Coming Soon)</div>
              </div>}
              value1="event"
              value2="memberships"
              optionDisable={true}
              autoComplete="off"
              error={
                Boolean(errors.entry_type && touched.entry_type) &&
                errors.entry_type
              }
              {...getFieldProps('entry_type')}
            />
            <FormikRadioInputThreeFields
              label="IS FEATURED?"
              style={{
                marginBottom: 36,
                textTransform: 'capitalize',
              }}
              option1="Yes"
              option2="No"
              value1={true}
              value2={false}
              autoComplete="off"
              error={
                Boolean(errors.is_featured && touched.is_featured) &&
                errors.is_featured
              }
              {...getFieldProps('is_featured')}
            />

            <Row>
              <Space direction="horizontal" >
                <Col>
                  <Button
                    disabled={isSubmitting}
                    loading={isSubmitting}
                    style={{ marginTop: 20 }}
                    type="primary"
                    htmlType="submit"
                    className='primary-btn'>
                    Save Changes
                  </Button>
                </Col>
                <Link to="/admin/leagues">
                  <Button
                    type="link"
                    size={"middle"}
                    style={{ color: "gray", marginRight: 20, marginTop: 36 }}
                  >
                    Cancel
                  </Button>
                </Link>
              </Space>
            </Row>
          </form>
        </FormikProvider> :
          <Row>
            <Col span={24} style={{ paddingTop: 100, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <Spin />
            </Col>
          </Row>}
      </div>
    </MainLayout>
  );
};

export default EditLeague;
