// A "slice" is a collection of Redux reducer logic and actions for a single feature in your app,
// typically defined together in a single file. The name comes from splitting up the root Redux
// state object into multiple "slices" of state.

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notification } from "antd";
import { toast } from "react-toastify";
import {
  fetchInrangeChallengesAPI,
  fetchInrangeRangesAPI,
} from "./API/inrageApi";

// You need to create a slice first, under the features folder
// that slice should have a createSlice() function and the intial state provided to it
// The slice name is also required, hence give the name: param a proper string

const initialState = {
  status: "idle",
  data: {},
  inrangeRanges: [],
  inrageChallenges: [],
};

//fetching Inrange Ranges
export const fetchInrangeRanges = createAsyncThunk(
  "inrange/ranges/get",
  async (data: any, thunkAPI) => {
    const response = await fetchInrangeRangesAPI();
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user error
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully fetched Leagues
      toast.success("List of ranges fetched successfully", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (response.data.status === 401) {
      notification.error({
        message: `${response.data.statusText} Please Login Again`,
      });
    }
    return response.data;
  }
);

//fetching Inrange Challenges
export const fetchInrangeChallenges = createAsyncThunk(
  "inrange/challenges/get",
  async (data: any, thunkAPI) => {
    const response = await fetchInrangeChallengesAPI();
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user error
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully fetched Leagues
      toast.success("List of inrange challenges fetched successfully", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (response.data.status === 401) {
      notification.error({
        message: `${response.data.statusText} Please Login Again`,
      });
    }
    return response.data;
  }
);

const InrageSlice = createSlice({
  name: "inrange",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInrangeRanges.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchInrangeChallenges.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchInrangeRanges.fulfilled, (state, action) => {
        state.status = "idle";
        state.inrangeRanges = action.payload.data;
      })
      .addCase(fetchInrangeChallenges.fulfilled, (state, action) => {
        state.status = "idle";
        state.inrageChallenges = action.payload.data;
      })
      .addCase(fetchInrangeRanges.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(fetchInrangeChallenges.rejected, (state) => {
        state.status = "failed";
      });
  },
});

// Here we are exporting the actions that the component/button will need
// eslint-disable-next-line no-empty-pattern
export const {} = InrageSlice.actions;

// please look carefully at the below line, it returns a reducer object that's why it gets added in the store.
export default InrageSlice.reducer;
