import { smashersAPI } from '@utils/APIInterceptor';

export async function createPartnerBrandApi(partnerBrandData: any) {
  const accessToken = localStorage.getItem('accessToken');

  const data: any = await smashersAPI(accessToken).post(
    'api/v1/admin/partner_brands',
    {
      ...partnerBrandData,
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function fetchPartnerBrandsApi(payload: any) {
  const accessToken = localStorage.getItem('accessToken');
  const data: any = await smashersAPI(accessToken).get(
    `api/v1/admin/partner_brands?page=${payload.page}&per_page=${payload.per_page}`
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function fetchPartnerBrandApi(id: any) {
  const accessToken = localStorage.getItem('accessToken');
  const data: any = await smashersAPI(accessToken).get(
    `api/v1/admin/partner_brands/${id}`
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function deletePartnerBrandApi(id: any) {
  const accessToken = localStorage.getItem('accessToken');
  const data: any = await smashersAPI(accessToken).delete(
    `api/v1/admin/partner_brands/${id}`
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function updatePartnerBrandApi(payload: any) {
  const accessToken = localStorage.getItem('accessToken');

  const data: any = await smashersAPI(accessToken).put(
    `api/v1/admin/partner_brands/${payload.id}`,
    {
      ...payload.partnerBrandData,
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}
