import React, { Component } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

class QuillRTE extends Component<any> {
  state = {
    editorState: EditorState.createEmpty(),
    renderDone: false,
  };
  onEditorStateChange = (editorState: EditorState) => {
    this.setState({
      editorState,
    });
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = draftToHtml(rawContentState);
    this.props.onTextChange(markup);
  };

  componentDidUpdate(prevProps: any) {
    // Typical usage (don't forget to compare props):
    if (
      this.props.initialValue !== prevProps.initialValue &&
      !this.state.renderDone
    ) {
      const blocksFromHTML = htmlToDraft(this.props.initialValue);
      const freshState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      this.setState(
        {
          editorState: EditorState.createWithContent(freshState),
          renderDone: true,
        },
        () => EditorState.moveSelectionToEnd(this.state.editorState)
      );
      EditorState.moveSelectionToEnd(this.state.editorState);
    }
  }

  componentDidMount() {
    if (this.props.initialValue) {
      const blocksFromHTML = htmlToDraft(this.props.initialValue);
      const freshState = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      this.setState({
        editorState: EditorState.createWithContent(freshState),
      });
    }
  }

  render() {
    const { editorState } = this.state;

    return (
      <div>
        <Editor
          toolbar={{
            options: ["inline", "list", "textAlign", "blockType", "fontFamily",],
            inline: {
              inDropdown: false,
              className: 'inline-draft-rte-styles',
              component: undefined,
              dropdownClassName: undefined,
              options: ["bold", "italic", "underline", "strikethrough",],
            },
            blockType: {
              inDropdown: true,
              options: ["Normal", "H1", "H2"],
              className: "blocktype-draft-rte-styles",
              component: undefined,
              dropdownClassName: undefined,
            },
            list: {
              inDropdown: false,
              className: "list-draft-rte-styles",
              component: undefined,
              dropdownClassName: undefined,
              options: ["unordered", "ordered", "indent", "outdent"],
            },
            textAlign: {
              inDropdown: false,
              className: "align-draft-rte-styles",
              component: undefined,
              dropdownClassName: undefined,
              options: ["left", "center", "right", "justify"],
            },
            fontFamily: {
              inDropdown: true,
              className: undefined,
              component: undefined,
              dropdownClassName: undefined,
              options: ["Ubuntu", "Roboto"],
            },

          }}

          placeholder={this.props.placeholder}
          editorState={editorState}
          readOnly={this.props.readOnly}
          wrapperClassName="draftjs-rte-wrapper"
          onEditorStateChange={this.onEditorStateChange}
        />
      </div>
    );
  }
}

export default QuillRTE;
