// A "slice" is a collection of Redux reducer logic and actions for a single feature in your app,
// typically defined together in a single file. The name comes from splitting up the root Redux
// state object into multiple "slices" of state.

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notification } from "antd";
import { toast } from "react-toastify";
import {
  createFlightApi,
  deleteFlightApi,
  editFlightApi,
  fetchFlightApi,
  fetchFlightDistributionsApi,
  fetchFlightNamesApi,
  fetchSingleFlightApi,
} from "./API/flightsApi";

// You need to create a slice first, under the features folder
// that slice should have a createSlice() function and the intial state provided to it
// The slice name is also required, hence give the name: param a proper string

const initialState = {
  status: "idle",
  data: {},
  flights: [],
  flightDistributions: [],
  flightNames: [],
};

// Writing a thunk for creating flights
export const createFlight = createAsyncThunk(
  "flights/create",
  async (data: any, thunkAPI) => {
    const response = await createFlightApi(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user response error
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.status === 422) {
      notification.error({
        message: `${response.data.data.message} Enter All Flight Fields`,
        placement: "top",
        duration: 10,
      });
    } else if (response.data) {
      // notify the user he has successfully created the flight
      toast.success("Flight has been created successfully", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

//fetching flights slice
export const fetchFlights = createAsyncThunk(
  "flights/get",
  async (data: any, thunkAPI) => {
    const response = await fetchFlightApi(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user response error
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully fetched the flight
      toast.success("List of flights fetched successfully", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (response.data.status === 401) {
      notification.error({
        message: `${response.data.statusText} Please Login Again`,
      });
    }
    return response.data;
  }
);

//fetching single flight
export const fetchFlight = createAsyncThunk(
  "flight/get",
  async (data: any, thunkAPI) => {
    const response = await fetchSingleFlightApi(data.id);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      //  notify the user response error
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully fetched the flight
      toast.success("Flight details fetched successfully", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

export const deleteFlight = createAsyncThunk(
  "flight/delete",
  async (id: any, thunkAPI) => {
    const response = await deleteFlightApi(id);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      //  notify the user response error
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully deleted the flight
      toast.success("Flight deleted successfully", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (response.data.status === 403) {
      notification.error({
        message:
          "Distribution cannot be deleted, one or more events are using this distribution",
      });
    }
    return response.data;
  }
);

export const editFlight = createAsyncThunk(
  "flight/edit",
  async (data: any, thunkAPI) => {
    const response = await editFlightApi(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user response error
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.status === 422) {
      notification.error({
        message: `${response.data.data.message} Enter All Flight Fields`,
        placement: "top",
        duration: 10,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully edited the flight
      toast.success("Flight has been successfully updated", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

//fetching flight Distributions slice
export const fetchFlightDistributions = createAsyncThunk(
  "flightDistributions/get",
  async () => {
    const response = await fetchFlightDistributionsApi();
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user response error
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.status === 401) {
      notification.error({
        message: `${response.data.statusText} Please Login Again`,
      });
    }
    return response.data;
  }
);

//fetching flight names
export const fetchFlightNames = createAsyncThunk(
  "flightNames/get",
  async (id: any, thunkAPI) => {
    const response = await fetchFlightNamesApi(id);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      //  notify the user response error
      notification.error({
        message: response.data.data.error,
      });
    }
    return response.data;
  }
);

const FlightsSlice = createSlice({
  name: "flights",
  initialState,
  reducers: {
    resetFlightNames: (state) => {
      state.flightNames = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createFlight.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchFlights.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteFlight.pending, (state) => {
        state.status = "loading";
      })
      .addCase(editFlight.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchFlight.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchFlightDistributions.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchFlightNames.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createFlight.fulfilled, (state, action) => {
        state.status = "idle";
        state.data = action.payload.data;
      })
      .addCase(fetchFlights.fulfilled, (state, action) => {
        state.status = "idle";
        state.flights = action.payload.data;
      })
      .addCase(deleteFlight.fulfilled, (state, action) => {
        state.status = "idle";
        state.flights = action.payload.data;
      })
      .addCase(editFlight.fulfilled, (state, action) => {
        state.status = "idle";
        state.flights = action.payload.data;
      })
      .addCase(fetchFlight.fulfilled, (state, action) => {
        state.status = "idle";
        state.data = action.payload.data;
      })
      .addCase(fetchFlightDistributions.fulfilled, (state, action) => {
        state.status = "idle";
        state.flightDistributions = action.payload.data;
      })
      .addCase(fetchFlightNames.fulfilled, (state, action) => {
        state.status = "idle";
        state.flightNames = action.payload.data;
      })
      .addCase(createFlight.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(fetchFlights.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(deleteFlight.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(fetchFlight.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(editFlight.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(fetchFlightDistributions.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(fetchFlightNames.rejected, (state) => {
        state.status = "failed";
      });
  },
});

// Here we are exporting the actions that the component/button will need
// eslint-disable-next-line no-empty-pattern
export const { resetFlightNames } = FlightsSlice.actions;

// please look carefully at the below line, it returns a reducer object that's why it gets added in the store.
export default FlightsSlice.reducer;
