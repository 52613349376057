import { smashersAPI } from '@utils/APIInterceptor';

export async function signInUser(email: string, password: string) {
  const data: any = await smashersAPI().post('/api/v1/admin/sign_in', {
    email,
    password,
  });

  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}
