import Logo from '@assets/images/Logo.png';
import { Space, Image, Spin, Checkbox, Input, Tooltip, message } from 'antd';
import MainLayout from '@components/layouts/MainLayout';
import {
  FormikDatePicker,
  FormikRangePicker,
  FormikRadioInput,
  FormikTextField,
  FormikRadioInputThreeFields,
} from '@components/inputFeilds/InputFeilds';

import { Col, Row, Button, Divider, Typography } from 'antd';
import {
  Field,
  useFormik,
  FieldArray,
  FormikProvider,
} from 'formik';

import React, { useEffect } from 'react';
import '../styles.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchEvent } from '@slices/eventsSlice';
import { AppDispatch } from '@config/storeConfig';
import { Slide, toast } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as LinkIcon } from "@assets/icons/link.svg";


const { Text } = Typography;


const ViewEvent = () => {
  const params = useParams();
  const currentEventId = params?.id;

  const navigate = useNavigate();
  const liveEventState = useSelector((state: any) => state.events.data.data);
  const reduxStatus = useSelector((state: any) => state.events.status);
  const dispatch = useDispatch<AppDispatch>();


  const holesDataArr = liveEventState?.course?.supported_holes;

  // Writing formik logic right here as it is very specific
  const formikMain = useFormik({
    initialValues: {
      name: liveEventState && liveEventState.name ? liveEventState.name : '',
      event_level: liveEventState && liveEventState.league_name === null ? 'non_league' : 'league',
      is_private:
        liveEventState && liveEventState.is_private
          ? liveEventState.is_private
            ? 'Yes'
            : 'No'
          : 'No',
      password: liveEventState && liveEventState.password ? liveEventState.password : '',
      league_name: liveEventState && liveEventState.league_name ? liveEventState.league_name : '',
      eventDates:
        liveEventState && liveEventState.start_time && liveEventState.end_time
          ? [liveEventState.start_time, liveEventState.end_time]
          : '',
      scores_upload_cutoff_time:
        liveEventState && liveEventState.scores_upload_cutoff_time
          ? liveEventState.scores_upload_cutoff_time
          : '',
      registration_deadline:
        liveEventState && liveEventState.registration_deadline
          ? liveEventState.registration_deadline
          : '',
      scoring:
        liveEventState && liveEventState.scoring
          ? liveEventState.scoring === "Gross_Net" ? "Both (Gross+Net)" : liveEventState.scoring
          : undefined,
      event_poster: '',
      rules: liveEventState && liveEventState.rules ? liveEventState.rules : '',
      par_value: liveEventState && liveEventState.par ? liveEventState.par : '',
      flights:
        liveEventState && liveEventState.flights
          ? liveEventState.flights
            ? 'Yes'
            : 'No'
          : 'No',
      flight_distribution: liveEventState && liveEventState.flight_distribution ? liveEventState.flight_distribution : '',
      smash_points:
        liveEventState && liveEventState.smash_points
          ? liveEventState.smash_points
          : '',
      world_points:
        liveEventState && liveEventState.world_points
          ? liveEventState.world_points
          : '',
      total_league_points: liveEventState && liveEventState.total_league_points
        ? liveEventState.total_league_points
        : '',
      rewards:
        liveEventState && liveEventState.rewards
          ? liveEventState.rewards
          : [
            {
              flight_id: undefined,
              position: 1,
              smash_points: '',
              world_points: '',
              gross_smash_cash: '',
              net_smash_cash: '',
              gross_league_points: '',
              net_league_points: '',
              cttp_league_points: '',
              ld_league_points: '',
              gross_world_points: '',
              net_world_points: '',
            },
          ],
      event_entry:
        liveEventState && liveEventState.event_entry
          ? liveEventState.event_entry
          : '',
      event_fees:
        liveEventState && liveEventState.event_fees
          ? liveEventState.event_fees
          : '',
      multiple_entries:
        liveEventState && liveEventState.multiple_entries
          ? liveEventState.multiple_entries
            ? 'Yes'
            : 'No'
          : 'No',
      has_promocodes:
        liveEventState && liveEventState.has_promocodes
          ? liveEventState.has_promocodes
            ? 'Yes'
            : 'No'
          : 'No',
      promocodes:
        liveEventState && liveEventState.promocodes
          ? liveEventState.promocodes
          : [],
      course: {
        course_id: liveEventState?.course?.name
          ? liveEventState.course.name
          : '',
        event_type: liveEventState?.event_type ? liveEventState.event_type : '',
        tee_box: liveEventState?.course?.tee_box
          ? liveEventState.course.tee_box
          : '',
        holes:
          liveEventState?.holes === 3
            ? '3-Holes'
            : liveEventState?.holes === 6
              ? '6-Holes'
              : liveEventState?.holes === 9
                ? '9-Holes'
                : liveEventState?.holes === 12
                  ? '12-Holes'
                  : liveEventState?.holes === 15
                    ? '15-Holes'
                    : liveEventState?.holes === 18
                      ? '18-Holes'
                      : '',
        event_holes: [
          {
            number: 1,
            par: holesDataArr?.filter((holes: any) => holes.number === 1).length
              ? holesDataArr
                ?.filter((holes: any) => holes.number === 1)
                .map((x: any) => x.par)[0]
              : null,
          },
          {
            number: 2,
            par: holesDataArr?.filter((holes: any) => holes.number === 2).length
              ? holesDataArr
                ?.filter((holes: any) => holes.number === 2)
                .map((x: any) => x.par)[0]
              : null,
          },
          {
            number: 3,
            par: holesDataArr?.filter((holes: any) => holes.number === 3).length
              ? holesDataArr
                ?.filter((holes: any) => holes.number === 3)
                .map((x: any) => x.par)[0]
              : null,
          },
          {
            number: 4,
            par: holesDataArr?.filter((holes: any) => holes.number === 4).length
              ? holesDataArr
                ?.filter((holes: any) => holes.number === 4)
                .map((x: any) => x.par)[0]
              : null,
          },
          {
            number: 5,
            par: holesDataArr?.filter((holes: any) => holes.number === 5).length
              ? holesDataArr
                ?.filter((holes: any) => holes.number === 5)
                .map((x: any) => x.par)[0]
              : null,
          },
          {
            number: 6,
            par: holesDataArr?.filter((holes: any) => holes.number === 6).length
              ? holesDataArr
                ?.filter((holes: any) => holes.number === 6)
                .map((x: any) => x.par)[0]
              : null,
          },
          {
            number: 7,
            par: holesDataArr?.filter((holes: any) => holes.number === 7).length
              ? holesDataArr
                ?.filter((holes: any) => holes.number === 7)
                .map((x: any) => x.par)[0]
              : null,
          },
          {
            number: 8,
            par: holesDataArr?.filter((holes: any) => holes.number === 8).length
              ? holesDataArr
                ?.filter((holes: any) => holes.number === 8)
                .map((x: any) => x.par)[0]
              : null,
          },
          {
            number: 9,
            par: holesDataArr?.filter((holes: any) => holes.number === 9).length
              ? holesDataArr
                ?.filter((holes: any) => holes.number === 9)
                .map((x: any) => x.par)[0]
              : null,
          },
          {
            number: 10,
            par: holesDataArr?.filter((holes: any) => holes.number === 10)
              .length
              ? holesDataArr
                ?.filter((holes: any) => holes.number === 10)
                .map((x: any) => x.par)[0]
              : null,
          },
          {
            number: 11,
            par: holesDataArr?.filter((holes: any) => holes.number === 11)
              .length
              ? holesDataArr
                ?.filter((holes: any) => holes.number === 11)
                .map((x: any) => x.par)[0]
              : null,
          },
          {
            number: 12,
            par: holesDataArr?.filter((holes: any) => holes.number === 12)
              .length
              ? holesDataArr
                ?.filter((holes: any) => holes.number === 12)
                .map((x: any) => x.par)[0]
              : null,
          },
          {
            number: 13,
            par: holesDataArr?.filter((holes: any) => holes.number === 13)
              .length
              ? holesDataArr
                ?.filter((holes: any) => holes.number === 13)
                .map((x: any) => x.par)[0]
              : null,
          },
          {
            number: 14,
            par: holesDataArr?.filter((holes: any) => holes.number === 14)
              .length
              ? holesDataArr
                ?.filter((holes: any) => holes.number === 14)
                .map((x: any) => x.par)[0]
              : null,
          },
          {
            number: 15,
            par: holesDataArr?.filter((holes: any) => holes.number === 15)
              .length
              ? holesDataArr
                ?.filter((holes: any) => holes.number === 15)
                .map((x: any) => x.par)[0]
              : null,
          },
          {
            number: 16,
            par: holesDataArr?.filter((holes: any) => holes.number === 16)
              .length
              ? holesDataArr
                ?.filter((holes: any) => holes.number === 16)
                .map((x: any) => x.par)[0]
              : null,
          },
          {
            number: 17,
            par: holesDataArr?.filter((holes: any) => holes.number === 17)
              .length
              ? holesDataArr
                ?.filter((holes: any) => holes.number === 17)
                .map((x: any) => x.par)[0]
              : null,
          },
          {
            number: 18,
            par: holesDataArr?.filter((holes: any) => holes.number === 18)
              .length
              ? holesDataArr
                ?.filter((holes: any) => holes.number === 18)
                .map((x: any) => x.par)[0]
              : null,
          },
        ],
      },
      team_members: liveEventState?.team_members
        ? liveEventState.team_members
        : '',
      shot_clock_enabled:
        liveEventState && liveEventState.shot_clock_enabled
          ? liveEventState.shot_clock_enabled
            ? 'Yes'
            : 'No'
          : 'No',
      grace_time_enabled:
        liveEventState && liveEventState.grace_time_enabled
          ? liveEventState.grace_time_enabled
            ? 'Yes'
            : 'No'
          : 'No',
      shot_clock_time: liveEventState?.shot_clock_time
        ? liveEventState.shot_clock_time
        : 1,
      grace_time: liveEventState?.grace_time
        ? liveEventState.grace_time
        : 1,
      event_link: liveEventState?.event_link
        ? liveEventState.event_link
        : '',
      post_scoring_smash_cash: liveEventState?.post_scoring_smash_cash ? liveEventState?.post_scoring_smash_cash : 5,

    },
    enableReinitialize: true,

    onSubmit: () => {

    },
  });

  const {
    errors,
    getFieldProps,
    handleSubmit,
    touched,
    values,
    setFieldValue,
  } = formikMain;

  useEffect(() => {
    //Fetch the event right here and populate the values
    // use current id currentEventId
    dispatch(fetchEvent({ id: currentEventId }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    toast.info(
      <>
        <p>View your event right here</p>
      </>,
      {
        position: 'bottom-right',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
        transition: Slide,
        style: { minWidth: 200 },
      }
    );
  }, []);

  const onEditEvent = (id: any) => {
    //Redirect to edit event for that record
    navigate(`/admin/edit/event/${id}`);
  };

  const handleCopyToClipboard = (text: string) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        message.success('Link copied to clipboard');
      })
      .catch((error) => {
        console.error('Failed to copy to clipboard:', error);
      });
  };

  return (
    <MainLayout>
      <>
        <Row>
          <Col xl={20} sm={24}>
            <Divider orientation="left" orientationMargin="0">
              <Text className="heading">View Event</Text>
            </Divider>
          </Col>
        </Row>

        {reduxStatus !== 'loading' ? (
          <FormikProvider value={formikMain}>
            <form onSubmit={handleSubmit} className="create-event-form">
              <Row>
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <FormikTextField
                    label="EVENT NAME"
                    style={{ marginBottom: 10 }}
                    placeholder="Enter your event name"
                    {...getFieldProps('name')}
                    disabled
                  />
                </Col>
                <Col span={8}></Col>
                <Col span={8}></Col>
              </Row>
              <div className='view-tooltip'>
                <Tooltip placement='bottomLeft' title={<Text style={{ color: '#C3E1FF', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => handleCopyToClipboard(values.event_link)}>{values.event_link}</Text>}><LinkIcon /> <Text style={{ textDecoration: 'underline', color: '#004C98' }}>Copy Event Link</Text></Tooltip>
              </div>
              <FormikRadioInputThreeFields
                label="EVENT LEVEL"
                option1="Non-League"
                option2="League"
                value1="non_league"
                value2="league"
                style={{ marginTop: 10 }}
                disabled
                {...getFieldProps('event_level')}
              />
              {values.event_level === "non_league" &&
                <Row>
                  <Col xl={24} lg={12} sm={12} xs={24}>
                    <FormikRadioInput
                      label="IS PRIVATE EVENT? (PASSWORD PROTECTED)"
                      disabled
                      option1="Yes"
                      option2="No"
                      {...getFieldProps('is_private')}
                    />
                  </Col>
                </Row>}
              {values.is_private === "Yes" &&
                <Row>
                  <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                    <FormikTextField
                      label="PASSWORD"
                      disabled
                      type="Password"
                      placeholder="Enter password"
                      {...getFieldProps('password')}
                    />
                  </Col>
                  <Col span={8}></Col>
                  <Col span={8}></Col>
                </Row>}
              {values.event_level === "league" &&
                <Row >
                  <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                    <FormikTextField
                      label="LEAGUE NAME"
                      disabled
                      {...getFieldProps('league_name')}
                    />
                  </Col>
                </Row>
              }
              <Row>
                <Col xl={18} sm={24}>
                  <Divider
                    orientation="left"
                    orientationMargin="0"
                    style={{ marginBottom: 36 }}
                  >
                    <Text className="points-heading"> Toptracer Setup </Text>
                  </Divider>
                </Col>
              </Row>
              <Row gutter={8}>
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <FormikTextField
                    label="COURSE NAME"
                    disabled
                    {...getFieldProps('course.course_id')}
                  />
                </Col>
              </Row>
              <Row>
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <FormikTextField
                    label="EVENT TYPE"
                    style={{ marginBottom: 36, textTransform: 'capitalize' }}
                    disabled
                    fieldValue="course.event_type"
                    {...getFieldProps('course.event_type')}
                  />
                </Col>
              </Row>
              {(values.course.event_type === 'Strokeplay' ||
                values.course.event_type === 'Scramble') && (
                  <>
                    <Row gutter={8}>
                      <Col xl={4} lg={5} sm={12} xs={24}>
                        <FormikTextField
                          label="TEE BOX"
                          style={{ marginBottom: 36 }}
                          disabled
                          fieldValue="course.tee_box"
                          {...getFieldProps('course.tee_box')}
                        />
                      </Col>
                      <Col xl={4} lg={5} sm={12} xs={24}>
                        <FormikTextField
                          label="NO. OF HOLES"
                          style={{ marginBottom: 36 }}
                          disabled
                          fieldValue="course.holes"
                          {...getFieldProps('course.holes')}

                        />
                      </Col>

                      {(values.course.event_type === 'Strokeplay' ||
                        values.course.event_type === 'Scramble') && (
                          <>
                            {values.course.holes === '18-Holes' && (
                              <Col xl={4} lg={5} md={6} sm={12} xs={24}>
                                <FormikTextField
                                  label="PAR VALUE"
                                  style={{
                                    marginBottom: 36,
                                  }}
                                  disabled
                                  {...getFieldProps('par_value')}
                                />
                              </Col>
                            )}
                            <Col xl={4} lg={5} sm={12} xs={24}>
                              <FormikTextField
                                label={
                                  values.course.event_type === 'Scramble'
                                    ? 'SCRAMBLE EVENT TYPE'
                                    : 'STROKEPLAY EVENT TYPE'
                                }
                                style={{
                                  marginBottom: 36,
                                }}
                                fieldValue="scoring"
                                setFieldValue={setFieldValue}
                                disabled
                                {...getFieldProps('scoring')}
                              />
                            </Col>
                          </>
                        )}
                      {values.course.event_type === 'Scramble' && (
                        <Col xl={4} lg={5} sm={12} xs={24}>
                          <FormikTextField
                            label="SCRAMBLE TEAM MEMBERS"
                            style={{
                              marginBottom: 36,
                            }}
                            fieldValue="team_members"
                            disabled
                            {...getFieldProps('team_members')}
                          />
                        </Col>
                      )}
                      {values.course.event_type === 'Strokeplay' &&
                        values.scoring === 'Net' && (
                          <Col xl={24} lg={12} sm={12} xs={24}>
                            <FormikRadioInput
                              label="FLIGHTS"
                              style={{
                                marginBottom: 36,
                                marginLeft: 6,
                              }}
                              disabled
                              option1="Yes"
                              option2="No"
                              {...getFieldProps('flights')}
                            />
                          </Col>
                        )}
                    </Row>
                    {values.course.event_type === 'Strokeplay' &&
                      values.scoring === 'Net' &&
                      <Row gutter={8}>
                        <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                          <FormikTextField
                            label="FLIGHTS SETUP"
                            disabled
                            {...getFieldProps('flight_distribution')}
                          />
                        </Col>
                      </Row>}
                    <Row>
                      <Col span={24}>
                        {values.course.holes !== '18-Holes' && (
                          <>
                            <Text className="poster-text">
                              SETUP CUSTOM HOLES
                            </Text>
                            <div className="holesCheckbox" style={{ borderColor: "#d9d9d9" }}>
                              <div style={{ textAlign: 'end' }}>
                                PAR: {values.par_value}
                              </div>
                              <div style={{ marginBottom: 10 }}>
                                <Text>FRONT</Text>

                              </div>
                              <div>
                                {[...Array(9)].map((_, index: any) => {
                                  return (
                                    <Checkbox
                                      key={index}
                                      className="holeParValCheckbox"
                                      checked={values.course.event_holes.map((x: any) => x.par)[index] !== null}
                                      disabled
                                    >
                                      {index + 1}
                                    </Checkbox>
                                  );
                                })}
                              </div>
                              <div style={{ marginTop: 10, marginBottom: 10 }}>
                                <Text>BACK</Text>
                              </div>
                              <div>
                                {[...Array(9)].map((_, index: any) => {
                                  return (
                                    <Checkbox
                                      key={index}
                                      className="holeParValCheckbox"
                                      checked={values.course.event_holes.map((x: any) => x.par)[index + 9] !== null}
                                      disabled
                                    >
                                      {index + 10}
                                    </Checkbox>
                                  );
                                })}
                              </div>
                            </div>
                          </>
                        )}
                      </Col>
                    </Row>
                  </>
                )}
              <Row gutter={4}>
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <FormikRangePicker
                    format="dddd, MM/DD/YYYY h:mm:ss a"
                    showTime
                    label="EVENT START - END DATE"
                    style={{ marginBottom: 36 }}
                    setFieldValue={setFieldValue}
                    fieldValue="eventDates"
                    {...getFieldProps('eventDates')}
                    disabled
                  />
                </Col>
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <FormikDatePicker
                    format="dddd, MM/DD/YYYY h:mm:ss a"
                    showTime
                    label="REGISTRATION DEADLINE"
                    style={{ marginBottom: 36 }}
                    fieldValue="registration_deadline"
                    setFieldValue={setFieldValue}
                    {...getFieldProps('registration_deadline')}
                    disabled
                  />
                </Col>
                <Col span={8}></Col>
              </Row>
              <Row>
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <FormikDatePicker
                    format="dddd, MM/DD/YYYY h:mm:ss a"
                    showTime
                    label="SCORE UPLOAD DEADLINE"
                    style={{ marginBottom: 36 }}
                    fieldValue="scores_upload_cutoff_time"
                    setFieldValue={setFieldValue}
                    {...getFieldProps('scores_upload_cutoff_time')}
                    disabled
                  />
                </Col>
                <Col span={8}></Col>
                <Col span={8}></Col>
              </Row>
              <Row>
                <Col xl={24} lg={12} sm={12} xs={24}>
                  <FormikRadioInputThreeFields
                    label="SHOT CLOCK"
                    option1="Enable"
                    option2="Disable"
                    value1="Yes"
                    value2="No"
                    disabled
                    {...getFieldProps("shot_clock_enabled")}
                  />
                </Col>
              </Row>
              {values.shot_clock_enabled === "Yes" &&
                <Row>
                  <Col xl={8}
                    lg={10}
                    md={12}
                    sm={24}
                    xs={24}>
                    <Text style={{ fontSize: 11, letterSpacing: 1 }}>SHOT CLOCK TIME(MINS)</Text>
                    <Input
                      disabled
                      value={values.shot_clock_time}
                      style={{ height: 50, marginBottom: 30 }}
                    />
                  </Col>
                </Row>
              }
              {values.shot_clock_enabled === "Yes" &&
                <Row>
                  <Col xl={24} lg={12} sm={12} xs={24}>
                    <FormikRadioInputThreeFields
                      label="EXTRA TIME/GRACE TIME"
                      option1="Yes"
                      option2="No"
                      value1="Yes"
                      value2="No"
                      disabled
                      {...getFieldProps("grace_time_enabled")}
                    />
                  </Col>
                </Row>
              }
              {values.grace_time_enabled === "Yes" && values.shot_clock_enabled === "Yes" &&
                <Row>
                  <Col xl={8}
                    lg={10}
                    md={12}
                    sm={24}
                    xs={24}>
                    <Text style={{ fontSize: 11, letterSpacing: 1 }}>EXTRA/GRACE TIME(MINS)</Text>
                    <Input
                      disabled
                      value={values.grace_time}
                      style={{ height: 50, marginBottom: 30 }}
                    />
                  </Col>
                </Row>

              }
              {(values.grace_time > values.shot_clock_time) && values.grace_time_enabled === "Yes" &&
                <Text style={{ color: '#004C98', marginBottom: 5 }}>*Entered Extra Time &gt; Shot Clock Time. We recommend Extra Time &lt; Shot Clock Time.</Text>
              }
              <Row>
                <Col
                  xl={8}
                  lg={10}
                  md={12}
                  sm={24}
                  xs={24}
                  style={{ marginBottom: 30 }}
                >
                  <Text className="poster-text">EVENT RULES</Text>
                  <div
                    className="view-rules"
                    dangerouslySetInnerHTML={{ __html: values.rules }}
                  />
                </Col>
              </Row>
              {liveEventState && (
                <>
                  <Text className="poster-text" style={{ display: 'block' }}>
                    EVENT POSTER
                  </Text>
                  <Image
                    style={{
                      width: 80,
                      height: 80,
                      objectFit: 'contain',
                      marginRight: 10,
                      background:
                        liveEventState.event_poster === null
                          ? '#141879'
                          : 'unset',
                    }}
                    src={
                      liveEventState.event_poster === null
                        ? Logo
                        : liveEventState.event_poster
                    }
                  />
                </>
              )}

              {/* Points & awards starts here */}

              <>
                <Row>
                  <Col xl={18} sm={24}>
                    <Divider
                      orientation="left"
                      orientationMargin="0"
                      style={{ marginTop: 50 }}
                    >
                      <Text className="points-heading"> Points & Awards </Text>
                    </Divider>
                  </Col>
                </Row>
                <Row gutter={4}>
                  <Col xl={3} lg={4} sm={6} xs={12}>
                    <FormikTextField
                      label="TOTAL SMASH CASH"
                      style={{ marginBottom: 36 }}
                      autoComplete="off"
                      error={
                        Boolean(errors.smash_points && touched.smash_points) &&
                        errors.smash_points
                      }
                      placeholder="Enter smash cash"
                      {...getFieldProps('smash_points')}
                      disabled
                    />
                  </Col>
                  {(values.event_level === 'non_league') && (values.course.event_type === 'Strokeplay' ||
                    values.course.event_type === 'Scramble') && (
                      <Col xl={3} lg={4} sm={6} xs={12}>
                        <FormikTextField
                          label="TOTAL WORLD POINTS"
                          style={{ marginBottom: 36 }}
                          {...getFieldProps('world_points')}
                          disabled
                        />
                      </Col>
                    )}
                  {values.event_level === 'league' && (
                    <Col xl={3} lg={4} sm={6} xs={12}>
                      <FormikTextField
                        label="TOTAL LEAGUE POINTS"
                        style={{ marginBottom: 36 }}
                        disabled
                        {...getFieldProps('total_league_points')}
                      />
                    </Col>
                  )}
                </Row>
                <Row>
                <Col xl={4} lg={4} sm={6} xs={12}>
                  <FormikTextField
                    label="SMASH CASH FOR POSTING SCORE"
                    style={{ marginBottom: 36 }}                
                    placeholder="Smash Cash For Posting Score"
                    {...getFieldProps('post_scoring_smash_cash')}
                    disabled
                  />
                </Col>
              </Row>
                <Row>
                  <Col xl={16} sm={24}>
                    <Divider orientation="left" orientationMargin="0">
                      <Text
                        className="points-heading"
                        style={{ fontWeight: 'normal', fontSize: 14 }}
                      >
                        Manual Entry
                      </Text>
                    </Divider>
                  </Col>
                </Row>
                {values.course.event_type === 'Strokeplay' && values.scoring === "Net" &&
                  values.flights === 'Yes' &&
                  (<div className="flights-distr-text">Distribution For Strokeplay With Flights</div>
                  )}
                <div>
                  <FieldArray
                    name="rewards"
                    render={(arrayHelpers) => (
                      <div className="rewards-table-wrapper">
                        <div className="table-columns">
                          {values.course.event_type === 'Strokeplay' && values.scoring === "Net" &&
                            values.flights === 'Yes' && (
                              <Text className="column">Flights</Text>
                            )}
                          <Text className="column">Position</Text>
                          {values.scoring !== 'Both (Gross+Net)' && <Text className="column">Smash Cash</Text>}
                          {values.scoring === 'Both (Gross+Net)' && <Text className="column">Smash Cash For Gross</Text>}
                          {(values.event_level === "league" && (values.scoring === 'Gross' || values.scoring === 'Both (Gross+Net)')) && <Text className="column">Gross League Points</Text>}
                          {values.scoring === 'Both (Gross+Net)' && <Text className="column">Smash Cash For Net</Text>}
                          {(values.event_level === "league" && (values.scoring === 'Net' || values.scoring === 'Both (Gross+Net)')) && <Text className="column">{values.flights === "Yes" ? "League Points" : "Net League Points"}</Text>}
                          {(values.event_level === "non_league") && (values.scoring !== 'Both (Gross+Net)') && (values.course.event_type === 'Strokeplay' ||
                            values.course.event_type === 'Scramble') && (
                              <Text className="column">World Points</Text>
                            )}
                          {values.scoring === 'Both (Gross+Net)' && values.event_level === "non_league" && <Text className="column">World Points For Gross</Text>}
                          {values.scoring === 'Both (Gross+Net)' && values.event_level === "non_league" && <Text className="column">World Points For Net</Text>}
                          {values.event_level === "league" && values.course.event_type === "Closest To The Pin" && (
                            <Text className="column">CTTP League Points</Text>

                          )}
                          {values.event_level === "league" && values.course.event_type === "Longest Drive" && (
                            <Text className="column">LD League Points</Text>

                          )}
                        </div>
                        {values.rewards.map((formItem: any, index: any) => (
                          <div key={index} className="rewards-table-row">
                            {values.course.event_type === 'Strokeplay' && values.scoring === "Net" &&
                              values.flights === 'Yes' && (
                                <div
                                  className="input-container view-input-container"
                                  style={{ fontSize: 12 }}
                                >
                                  <Field
                                    {...getFieldProps(
                                      `rewards[${index}].flight_name`
                                    )}

                                    disabled
                                  />
                                </div>
                              )}
                            <div className="input-container view-input-container">
                              <Field
                                disabled
                                {...getFieldProps(`rewards[${index}].position`)}
                              />
                            </div>
                            {values.scoring !== 'Both (Gross+Net)' &&
                              <div className="input-container view-input-container">
                                <Field
                                  {...getFieldProps(
                                    `rewards[${index}].smash_points`
                                  )}
                                  disabled
                                />
                              </div>}
                            {values.scoring === 'Both (Gross+Net)' &&

                              <div className="input-container view-input-container">
                                <Field
                                  {...getFieldProps(
                                    `rewards[${index}].gross_smash_cash`
                                  )}
                                  disabled
                                />

                              </div>}
                            {(values.event_level === "league" && (values.scoring === 'Gross' || values.scoring === 'Both (Gross+Net)')) &&
                              <div className="input-container view-input-container">
                                <Field
                                  {...getFieldProps(
                                    `rewards[${index}].gross_league_points`
                                  )}
                                  disabled
                                />

                              </div>}
                            {values.scoring === 'Both (Gross+Net)' &&
                              <div className="input-container view-input-container">
                                <Field
                                  {...getFieldProps(
                                    `rewards[${index}].net_smash_cash`
                                  )}
                                  disabled
                                />

                              </div>}
                            {(values.event_level === "league" && (values.scoring === 'Net' || values.scoring === 'Both (Gross+Net)')) &&
                              <div className="input-container view-input-container">
                                <Field
                                  {...getFieldProps(
                                    `rewards[${index}].net_league_points`
                                  )}
                                  disabled
                                />

                              </div>}


                            {(values.event_level === "non_league") && (values.scoring !== 'Both (Gross+Net)') && (values.course.event_type === 'Strokeplay' ||
                              values.course.event_type === 'Scramble') && (
                                <div className="input-container view-input-container">
                                  <Field
                                    {...getFieldProps(
                                      `rewards[${index}].world_points`
                                    )}
                                    disabled
                                  />

                                </div>
                              )}
                            {values.scoring === 'Both (Gross+Net)' && values.event_level === "non_league" &&
                              <div className="input-container view-input-container">
                                <Field
                                  {...getFieldProps(
                                    `rewards[${index}].gross_world_points`
                                  )}
                                  disabled
                                />
                              </div>}
                            {values.scoring === 'Both (Gross+Net)' && values.event_level === "non_league" &&
                              <div className="input-container view-input-container">
                                <Field
                                  {...getFieldProps(
                                    `rewards[${index}].net_world_points`
                                  )}
                                  disabled
                                />
                              </div>}
                            {values.event_level === "league" && values.course.event_type === "Closest To The Pin" && (
                              <div className="input-container view-input-container">
                                <Field
                                  {...getFieldProps(
                                    `rewards[${index}].cttp_league_points`
                                  )}
                                  disabled
                                />

                              </div>
                            )}
                            {values.event_level === "league" && values.course.event_type === "Longest Drive" && (
                              <div className="input-container view-input-container">
                                <Field
                                  {...getFieldProps(
                                    `rewards[${index}].ld_league_points`
                                  )}
                                  disabled
                                />

                              </div>
                            )}

                          </div>
                        ))}

                      </div>
                    )}
                  />
                </div>
              </>
              <FormikRadioInput
                label="EVENT ENTRY"
                style={{
                  marginBottom: 36,
                  textTransform: 'capitalize',
                }}
                option1="free"
                option2="paid"
                autoComplete="off"
                error={
                  Boolean(errors.event_entry && touched.event_entry) &&
                  errors.event_entry
                }
                {...getFieldProps('event_entry')}
                disabled
              />

              {/* Payments And Promos starts here */}
              {values.event_entry === 'paid' && (
                <>
                  <Row>
                    <Col xl={18} sm={24}>
                      <Divider orientation="left" orientationMargin="0">
                        <Text className="payments-heading">
                          {' '}
                          Payments & Promos{' '}
                        </Text>
                      </Divider>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl={8} lg={10} md={12} sm={24}>
                      <FormikTextField
                        label="EVENT FEES"
                        style={{ marginBottom: 36 }}
                        autoComplete="off"
                        error={
                          Boolean(errors.event_fees && touched.event_fees) &&
                          errors.event_fees
                        }
                        placeholder="Fee"
                        {...getFieldProps('event_fees')}
                        disabled
                      />
                    </Col>
                  </Row>
                  <FormikRadioInput
                    label="MULTIPLE ENTRIES"
                    style={{ marginBottom: 36 }}
                    option1="Yes"
                    option2="No"
                    autoComplete="off"
                    error={
                      Boolean(
                        errors.multiple_entries && touched.multiple_entries
                      ) && errors.multiple_entries
                    }
                    {...getFieldProps('multiple_entries')}
                    disabled
                  />
                  <FormikRadioInput
                    label="COUPON/PROMO CODES?"
                    style={{ marginBottom: 36 }}
                    option1="Yes"
                    option2="No"
                    autoComplete="off"
                    error={
                      Boolean(
                        errors.has_promocodes && touched.has_promocodes
                      ) && errors.has_promocodes
                    }
                    placeholder="Fee"
                    {...getFieldProps('has_promocodes')}
                    disabled
                  />

                  {values.has_promocodes === 'Yes' && (
                    <div>
                      <FieldArray
                        name="promocodes"
                        render={(arrayHelpers) => (
                          <div className="rewards-table-wrapper">
                            <div className="table-columns">
                              <Text className="column">Promo Code</Text>
                              <Text className="column">Value Off($)</Text>
                            </div>
                            {values.promocodes.map(
                              (formItem: any, index: any) => (
                                <div key={index} className="rewards-table-row">
                                  <div className="input-container view-input-container">
                                    <Field
                                      style={{ textTransform: 'upperCase' }}
                                      disabled
                                      {...getFieldProps(
                                        `promocodes.${index}.name`
                                      )}
                                    />
                                  </div>

                                  <div className="input-container view-input-container">
                                    <Field
                                      disabled
                                      {...getFieldProps(
                                        `promocodes.${index}.value`
                                      )}
                                    />
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        )}
                      />
                    </div>
                  )}
                </>
              )}
              <Space direction="horizontal" style={{ marginTop: 60 }}>
                <Button
                  type="primary"
                  htmlType="submit"
                  className="create-event-btn"
                  onClick={() => {
                    onEditEvent(params.id);
                  }}
                >
                  Edit Event
                </Button>
                <Link to="/admin/events">
                  <Button
                    type="link"
                    size={'middle'}
                    style={{ color: 'gray', marginTop: 20 }}
                  >
                    Cancel
                  </Button>
                </Link>
              </Space>
            </form>
          </FormikProvider>
        ) : (
          <Row>
            <Col
              span={24}
              style={{
                paddingTop: 100,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Spin />
            </Col>
          </Row>
        )}
      </>
    </MainLayout>
  );
};

export default ViewEvent;
