import { FormikRadioInput, FormikTextField } from '@components/inputFeilds/InputFeilds';
import React, { forwardRef, useEffect, } from "react";
import { ReactComponent as DropDownOutlined } from '@assets/icons/dropdown-icon.svg';
import { MainLayout } from '@components/layouts';
import { Button, Col, Divider, Row, Select, Space, Spin, Typography } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch } from "@config/storeConfig";
import { Slide, toast } from "react-toastify";
import '../styles.css';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { fetchTopTracer } from '@slices/topTracersSlice';
import Item from 'antd/lib/list/Item';
import { Option } from 'antd/lib/mentions';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

const { Text } = Typography;

const ViewTopTracer = () => {

  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const params = useParams();
  const currentTopTracerId = params?.id;
  const liveTopTracerState = useSelector((state: any) => state.topTracers.data.data);
  const reduxStatus = useSelector((state: any) => state.topTracers.status);



  const formikMain = useFormik({
    initialValues: {
      name: liveTopTracerState?.name ? liveTopTracerState.name : '',
      website: liveTopTracerState?.website ? liveTopTracerState.website : '',
      address: liveTopTracerState?.address ? liveTopTracerState.address : '',
      state_id: liveTopTracerState?.state_name ? liveTopTracerState.state_name : '',
      phone: liveTopTracerState?.phone ? liveTopTracerState.phone : '',
      device_support: liveTopTracerState?.device_support ? liveTopTracerState.device_support : '',
    },
    onSubmit: () => {

    },
    enableReinitialize: true,
  });

  const {
    errors,
    getFieldProps,
    handleSubmit,
    touched,
    values,
    isSubmitting,
    setSubmitting,
    setFieldValue,
    resetForm,
  } = formikMain;

  const FormikStatesSelect = forwardRef(
    (
      {
        error,
        label,
        name,
        onChange,
        onBlur,
        placeholder,
        value,
        options,
        style,
        setFieldValue,
        ...props
      }: any,
      ref
    ) => (
      <Item
        style={style}
        {...(Boolean(error) && { validateStatus: 'error', help: error })}
      >
        {!!label && <p className="text-field-label">{label}</p>}

        <Select
          className="form-select-field-custom"
          suffixIcon={<DropDownOutlined />}
          placeholder={placeholder}
          ref={ref}
          value={value}
          onChange={(value) => {
            setFieldValue('state_id', value);
          }}
          {...props}
        >
          {options &&
            options.map((state: any, ind: any) => (
              <Option key={ind} value={state.id}>
                {state.name}
              </Option>
            ))}
        </Select>
      </Item>
    )
  );

  useEffect(() => {
    //Fetch the toptracer right here and populate the values
    // use current id 
    dispatch(fetchTopTracer({ id: currentTopTracerId }));
  }, []);

  useEffect(() => {
    toast.info(
      <>
        <p>View your Toptracer right here</p>
      </>,
      {
        position: 'bottom-right',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
        transition: Slide,
        style: { minWidth: 200 },
      }
    );
  }, []);

  const onEditTopTracer = (id: any) => {
    //Redirect to edit toptracer for that record
    navigate(`/admin/edit/top-tracer/${id}`);
  };

  return (
    <MainLayout>
      <div className='community-container'>
        <Row>
          <Col xl={20} sm={24} >
            <Divider orientation="left" orientationMargin="0" >
              <Text className="community-heading">View Toptracer Range</Text>
            </Divider>
          </Col>
        </Row>
        {reduxStatus !== 'loading' ? (
          <FormikProvider value={formikMain}>
            <form onSubmit={handleSubmit} className='create-community-form'>
              <Row>
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <FormikTextField
                    label="TOPTRACER RANGE NAME"
                    disabled
                    className="community-input"
                    autoComplete="off"
                    error={Boolean(errors.name && touched.name) && errors.name}
                    placeholder="Enter Toptracer name"
                    {...getFieldProps('name')}
                  />
                </Col>
                <Col span={8}></Col>
                <Col span={8}></Col>
              </Row>
              <Row>
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <FormikTextField
                    label="ADDRESS"
                    disabled
                    autoComplete="off"
                    type="textarea"
                    error={Boolean(errors.address && touched.address) && errors.address}
                    placeholder="Enter address"
                    {...getFieldProps('address')}
                  />
                </Col>
                <Col span={8}></Col>
                <Col span={8}></Col>
              </Row>
              <Row className="statesDropdown">
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <FormikStatesSelect
                    label="STATE"
                    placeholder="Select a State"
                    disabled
                    autoComplete="off"
                    setFieldValue={setFieldValue}
                    error={
                      Boolean(errors.state_id && touched.state_id) && errors.state_id
                    }
                    {...getFieldProps('state_id')}
                  />
                </Col>
              </Row>
              <Row className='topTracerphoneInput'>
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <Text className='poster-text'>PHONE</Text>
                  <PhoneInput
                    value={values.phone}
                    disabled
                    style={{ color: '#00000040' }}
                    onChange={(inpVal) => setFieldValue('phone', inpVal)} />
                </Col>
                <Col span={8}></Col>
                <Col span={8}></Col>
              </Row>
              <Row>
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <FormikTextField
                    label="WEBSITE"
                    disabled
                    className="community-input"
                    autoComplete="off"
                    error={Boolean(errors.website && touched.website) && errors.website}
                    placeholder="Enter websiite"
                    {...getFieldProps('website')}
                  />
                </Col>
                <Col span={8}></Col>
                <Col span={8}></Col>
              </Row>
              <Row className='viewDeviceSupportRadioBtn'>
                <Col xl={24} lg={12} sm={12} xs={24}>
                  <FormikRadioInput
                    label="DEVICE SUPPORT"
                    style={{
                      marginBottom: 36,
                      marginLeft: 6,
                    }}
                    option1="Mobile"
                    option2="Monitor"
                    option3="Both (Mobile & Monitor)"
                    value3="Both"
                    disabled
                    autoComplete="off"
                    error={
                      Boolean(errors.device_support && touched.device_support) &&
                      errors.device_support
                    }
                    {...getFieldProps('device_support')}
                  />
                </Col>
              </Row>
              <Row>
                <Space direction="horizontal" >
                  <Col>
                    <Button
                      disabled={isSubmitting}
                      loading={isSubmitting}
                      style={{ marginTop: 20 }}
                      type="primary"
                      htmlType="submit"
                      className="create-event-btn"
                      onClick={() => {
                        onEditTopTracer(params.id);
                      }}>
                      Edit Toptracer Range
                    </Button>
                  </Col>
                  <Link to="/admin/top-tracer">
                    <Button
                      type="link"
                      size={"middle"}
                      style={{ color: "gray", marginTop: 36 }}
                    >
                      Cancel
                    </Button>
                  </Link>
                </Space>
              </Row>
            </form>
          </FormikProvider>
        ) : (
          <Row>
            <Col
              span={24}
              style={{
                paddingTop: 100,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Spin />
            </Col>
          </Row>
        )}

      </div>
    </MainLayout>
  );
};

export default ViewTopTracer;
