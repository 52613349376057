import { FormikTextField } from '@components/inputFeilds/InputFeilds';
import { MainLayout } from '@components/layouts';
import { Button, Col, Divider, Row, Spin, Typography } from 'antd';
import { FormikProvider, useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector, } from "react-redux";
import { AppDispatch } from "@config/storeConfig";
import { editSupportCategory, fetchSupportCategory } from '@slices/supportCategorySlice';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { Slide } from 'react-toastify';




const { Text } = Typography;

const EditSupportCategory = () => {
  const params = useParams();
  const liveSupportCategoryState = useSelector((state: any) => state.supportCategory.data.data);
  const reduxStatus = useSelector((state: any) => state.supportCategory.status);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const currentCategoryId = params?.id;

  const emailValidationSchema = yup.string().matches(
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))(,\s*(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})))*$/,
    'Enter a valid email or a list of comma separated emails'
  );

  const formikMain = useFormik({
    initialValues: {
      name: liveSupportCategoryState?.name ? liveSupportCategoryState.name : '',
      recipients: liveSupportCategoryState?.recipients ? liveSupportCategoryState.recipients.toString() : '',
    },
    enableReinitialize: true,
    onSubmit: async (values) => {
      setSubmitting(true);
      const categoriesData = {
        name: values.name,
        recipients: values.recipients,
      };
      await handleEditCategoryFormSubmit(categoriesData);
      setSubmitting(false);
      resetForm();
      navigate("/admin/support-category");
    },
    validationSchema: yup.object({
      name: yup.string().required('*Required Category Name'),
      recipients: emailValidationSchema.required('*Required Email'),
    }),
  });

  const {
    errors,
    getFieldProps,
    handleSubmit,
    touched,
    setSubmitting,
    isSubmitting,
    resetForm,
  } = formikMain;

  useEffect(() => {
    //Fetch the category right here and populate the values
    // use current id 
    window.scrollTo({ top: 0, behavior: "smooth" });
    dispatch(fetchSupportCategory({ id: currentCategoryId }));
  }, [currentCategoryId, dispatch]);

  useEffect(() => {
    toast.info(
      <>
        <p>Edit Support Category here!</p>
      </>,
      {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        closeButton: false,
        transition: Slide,
        style: { minWidth: 400 },
      }
    );
  }, []);

  const handleEditCategoryFormSubmit = async (argo: any) => {
    await dispatch(editSupportCategory({ id: params.id, supportCategoryData: argo }));
  };

  return (
    <MainLayout>
      <div className='community-container'>
        <Row>
          <Col xl={20} sm={24} >
            <Divider orientation="left" orientationMargin="0" >
              <Text className="community-heading">Edit Support Category</Text>
            </Divider>
          </Col>
        </Row>
        {reduxStatus !== 'loading' ? (
          <FormikProvider value={formikMain}>
            <form onSubmit={handleSubmit} className='create-community-form'>
              <Row>
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <FormikTextField
                    label="TYPE/CATEGORY NAME"
                    className="community-input"

                    autoComplete="off"
                    error={Boolean(errors.name && touched.name) && errors.name}
                    placeholder="Enter category name"
                    {...getFieldProps('name')}
                  />
                </Col>
                <Col span={8}></Col>
                <Col span={8}></Col>
              </Row>

              <Row>
                <Col xl={8} lg={10} md={12} sm={24} xs={24}>
                  <FormikTextField
                    label="EMAILS RECEPIENTS OF THIS CATEGORY"
                    className="community-input"

                    autoComplete="off"
                    error={Boolean(errors.recipients && touched.recipients) && errors.recipients}
                    placeholder="Enter recepients"
                    {...getFieldProps('recipients')}
                  />
                </Col>
                <Col span={8}></Col>
                <Col span={8}></Col>
              </Row>
              <Row>
                <Col>
                  <Button disabled={isSubmitting}
                    loading={isSubmitting} style={{ marginTop: 20 }} type="primary" htmlType="submit" className='primary-btn'>
                    Save Changes
                  </Button>
                </Col>
                <Link to="/admin/support-category">
                  <Button
                    type="link"
                    size={"middle"}
                    style={{ color: "gray", marginTop: 36 }}
                  >
                    Cancel
                  </Button>
                </Link>
              </Row>
            </form>
          </FormikProvider>
        ) : (
          <Row>
            <Col
              span={24}
              style={{
                paddingTop: 100,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Spin />
            </Col>
          </Row>)}
      </div>
    </MainLayout>
  );
};

export default EditSupportCategory;
