import { smashersAPI } from '@utils/APIInterceptor';

export async function createCourseApi(courseData: any) {
  const accessToken = localStorage.getItem('accessToken');
  const stringHoles = JSON.stringify(courseData.hole);
  const stringTeeBox = JSON.stringify(courseData.tee_box);
  const stringSupportedEvents = JSON.stringify(courseData.supported_events);
  const stringSupportedHoles = JSON.stringify(courseData.supported_holes);
  const data: any = await smashersAPI(accessToken).post(
    'api/v1/admin/courses',
    {
      ...courseData,
      hole: stringHoles,
      tee_box: stringTeeBox,
      supported_events: stringSupportedEvents,
      supported_holes: stringSupportedHoles,
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function fetchCourseApi(payload: any) {
  const accessToken = localStorage.getItem('accessToken');
  const data: any = await smashersAPI(accessToken).get(
    `api/v1/admin/courses?page=${payload.page}&per_page=${payload.per_page}`
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function getSingleCourse(id: any) {
  const accessToken = localStorage.getItem('accessToken');
  const data: any = await smashersAPI(accessToken).get(
    `api/v1/admin/courses/${id}`
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function deleteCourseApi(id: any) {
  const accessToken = localStorage.getItem('accessToken');
  const data: any = await smashersAPI(accessToken).delete(
    `api/v1/admin/courses/${id}`
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function editCourseApi(id: any) {
  const accessToken = localStorage.getItem('accessToken');
  const data: any = await smashersAPI(accessToken).put(
    `api/v1/admin/courses/${id}`
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function updateCourseApi(payload: any) {
  const accessToken = localStorage.getItem('accessToken');
  const stringHoles = JSON.stringify(payload.courseData.hole);
  const stringTeeBox = JSON.stringify(payload.courseData.tee_box);
  const stringSupportedEvents = JSON.stringify(
    payload.courseData.supported_events
  );
  const stringSupportedHoles = JSON.stringify(
    payload.courseData.supported_holes
  );
  const data: any = await smashersAPI(accessToken).put(
    `api/v1/admin/courses/${payload.id}`,
    {
      ...payload.courseData,
      hole: stringHoles,
      tee_box: stringTeeBox,
      supported_events: stringSupportedEvents,
      supported_holes: stringSupportedHoles,
    },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}
