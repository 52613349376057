// A "slice" is a collection of Redux reducer logic and actions for a single feature in your app,
// typically defined together in a single file. The name comes from splitting up the root Redux
// state object into multiple "slices" of state.

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notification } from "antd";
import { toast } from "react-toastify";
import { history } from "@config/routes";
import {
  createCampaignApi,
  deleteCampaignApi,
  fetchCampaignsApi,
  getSingleCampaign,
  updateCampaignApi,
} from "./API/campaignsApi";

// You need to create a slice first, under the features folder
// that slice should have a createSlice() function and the intial state provided to it
// The slice name is also required, hence give the name: param a proper string

const initialState = {
  status: "idle",
  data: {},
  campaigns: [],
  campaign: {},
};

// Writing a thunk for creating campaign
export const createCampaign = createAsyncThunk(
  "camapigns/create",
  async (data: any, { rejectWithValue }) => {
    const response = await createCampaignApi(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500 || response.data.status === 422) {
      // notify the user error
      notification.error({
        message:
          response.data.data.status === 500
            ? response.data.data.error
            : response.data.status === 422
            ? response.data.data.message
            : null,
      });
      return rejectWithValue(response.data.data.message);
    } else if (response.data) {
      // notify the user he has successfully created the campaign
      toast.success(response.data.data.message, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      history.push("/admin/campaigns");
    }
    return response.data;
  }
);

//fetching campaigns slice
export const fetchCampaigns = createAsyncThunk(
  "camapigns/get",
  async (data: any, thunkAPI) => {
    const response = await fetchCampaignsApi(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user error
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully created the campaign for inrange
      toast.success("List of campaigns fetched successfully", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (response.data.status === 401) {
      notification.error({
        message: `${response.data.statusText} Please Login Again`,
      });
    }
    return response.data;
  }
);

export const fetchCampaign = createAsyncThunk(
  "campaign/get",
  async (data: any, thunkAPI) => {
    const response = await getSingleCampaign(data.id);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user error
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully created the event
      toast.success("Campaign fetched successfully", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

// For updating the campaign
export const updateCampaign = createAsyncThunk(
  "campaign/update",
  async (data: any, { rejectWithValue }) => {
    const response = await updateCampaignApi(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500 || response.data.status === 422) {
      // notify the user error
      toast.error(
        response.data.data.status === 500
          ? response.data.data.error
          : response.data.status === 422
          ? response.data.data.message
          : null,
        {
          position: "top-right",
          autoClose: 1500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return rejectWithValue(response.data.data.message);
    } else if (response.data.data.success) {
      // notify the user he has successfully created the event
      toast.success("Campaign details updated successfully", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

export const deleteCampaign = createAsyncThunk(
  "camapign/delete",
  async (id: any, thunkAPI) => {
    const response = await deleteCampaignApi(id);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully created the event
      toast.success("Campaign deleted successfully", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

const campaignsSlice = createSlice({
  name: "campaigns",
  initialState,
  reducers: {
    resetCampaign: (state) => {
      state.campaign = {};
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCampaign.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCampaigns.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchCampaign.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteCampaign.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateCampaign.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createCampaign.fulfilled, (state, action) => {
        state.status = "idle";
        state.data = action.payload.data;
      })
      .addCase(fetchCampaigns.fulfilled, (state, action) => {
        state.status = "idle";
        state.campaigns = action.payload.data;
      })
      .addCase(fetchCampaign.fulfilled, (state, action) => {
        state.status = "idle";
        state.campaign = action.payload.data;
      })
      .addCase(deleteCampaign.fulfilled, (state, action) => {
        state.status = "idle";
        state.campaigns = action.payload.data;
      })
      .addCase(updateCampaign.fulfilled, (state, action) => {
        state.status = "idle";
        state.campaign = action.payload.data;
      })
      .addCase(createCampaign.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(fetchCampaigns.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(fetchCampaign.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(deleteCampaign.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updateCampaign.rejected, (state) => {
        state.status = "failed";
      });
  },
});

// Here we are exporting the actions that the component/button will need
// eslint-disable-next-line no-empty-pattern
export const { resetCampaign } = campaignsSlice.actions;

// please look carefully at the below line, it returns a reducer object that's why it gets added in the store.
export default campaignsSlice.reducer;
