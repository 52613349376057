import React from "react";
import Routes from "@config/routes";
import { ConfigProvider } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

ConfigProvider.config({
  theme: {
    primaryColor: "#3a57e8",
    errorColor: "#e74c3c",
  },
});

const App = () => {
  return (
    <ConfigProvider>
      <div
        style={{
          display: "flex",
          minHeight: "100vh",
          backgroundColor: "white",
          flex: 1,
        }}
      >
        <Routes />
      </div>
      <ToastContainer  style={{fontFamily: 'Ubuntu'}}/>
    </ConfigProvider>
  );
};

export default App;
