// A "slice" is a collection of Redux reducer logic and actions for a single feature in your app,
// typically defined together in a single file. The name comes from splitting up the root Redux
// state object into multiple "slices" of state.

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { notification } from "antd";
import { toast } from "react-toastify";
import {
  createGiftCardApi,
  deleteGiftCardApi,
  fetchGiftCardApi,
  fetchGiftCardsApi,
  updateGiftCardApi,
} from "./API/giftCardsApi";

// You need to create a slice first, under the features folder
// that slice should have a createSlice() function and the intial state provided to it
// The slice name is also required, hence give the name: param a proper string

const initialState = {
  status: "idle",
  data: {},
  giftCards: [],
};

// Writing a thunk for creating gift cards
export const createGiftCard = createAsyncThunk(
  "giftCard/create",
  async (data: any, thunkAPI) => {
    const response = await createGiftCardApi(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user he has successfully created the gift card
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.status === 422) {
      notification.warning({
        message: response.data.data.message,
        placement: 'top',
        duration: 10,
      });
    } else if (response.data) {
      // notify the user he has successfully created the gift card
      toast.success("Gift Card has been successfully created", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

//fetching gift cards slice
export const fetchGiftCards = createAsyncThunk(
  "giftCards/get",
  async (data: any, thunkAPI) => {
    const response = await fetchGiftCardsApi(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user he has successfully fetched the gift cards
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully fetched the gift cards
      toast.success("List of gift cards fetched successfully", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (response.data.status === 401) {
      notification.error({
        message: `${response.data.statusText} Please Login Again`,
      });
    }
    return response.data;
  }
);

export const fetchGiftCard = createAsyncThunk(
  "giftCard/get",
  async (data: any, thunkAPI) => {
    const response = await fetchGiftCardApi(data.id);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user he has successfully fetched gift card
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully fetched gift card
      toast.success("Gift Card fetched successfully", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

// For updating the gift card
export const updateGiftCard = createAsyncThunk(
  "giftCard/update",
  async (data: any, thunkAPI) => {
    const response = await updateGiftCardApi(data);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user he has successfully updated the gift card
      toast.error(response.data.data.error, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else if (response.data.status === 422) {
      notification.warning({
        message: response.data.data.message,
        placement: 'top',
        duration: 10,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully updated the gift card
      toast.success("Gift Card updated successfully", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

export const deleteGiftCard = createAsyncThunk(
  "giftCard/delete",
  async (id: any, thunkAPI) => {
    const response = await deleteGiftCardApi(id);
    // The value we return becomes the `fulfilled` action payload
    if (response.data.data.status === 500) {
      // notify the user he has successfully deleted the gift card
      notification.error({
        message: response.data.data.error,
      });
    } else if (response.data.data.success) {
      // notify the user he has successfully deleted the gift card
      toast.success("Gift Card deleted successfully", {
        position: "top-right",

        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
    return response.data;
  }
);

const giftCardsSlice = createSlice({
  name: "giftCards",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createGiftCard.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchGiftCards.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchGiftCard.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteGiftCard.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateGiftCard.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createGiftCard.fulfilled, (state, action) => {
        state.status = "idle";
        state.data = action.payload.data;
      })
      .addCase(fetchGiftCards.fulfilled, (state, action) => {
        state.status = "idle";
        state.giftCards = action.payload.data;
      })
      .addCase(fetchGiftCard.fulfilled, (state, action) => {
        state.status = "idle";
        state.data = action.payload.data;
      })
      .addCase(deleteGiftCard.fulfilled, (state, action) => {
        state.status = "idle";
        state.giftCards = action.payload.data;
      })
      .addCase(updateGiftCard.fulfilled, (state, action) => {
        state.status = "idle";
        state.data = action.payload.data;
      })
      .addCase(createGiftCard.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(fetchGiftCards.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(fetchGiftCard.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(deleteGiftCard.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(updateGiftCard.rejected, (state) => {
        state.status = "failed";
      });
  },
});

// Here we are exporting the actions that the component/button will need
// eslint-disable-next-line no-empty-pattern
export const { } = giftCardsSlice.actions;

// please look carefully at the below line, it returns a reducer object that's why it gets added in the store.
export default giftCardsSlice.reducer;
