import { smashersAPI } from '@utils/APIInterceptor';

export async function fetchScoresApi(payload: any) {
  const accessToken = localStorage.getItem('accessToken');
  const data: any = await smashersAPI(accessToken).get(
    `api/v1/admin/scores?page=${payload.page}&per_page=${payload.per_page}&event_ids=${payload.event_ids}&event_types=${payload.event_types}&league_ids=${payload.league_ids}`
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function rejectScoreApi({ id, reason }: any) {
  const accessToken = localStorage.getItem('accessToken');
  const data: any = await smashersAPI(accessToken).post(
    'api/v1/admin/scores/reject',
    {
      id,
      rejection_reason: reason,
    }
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function updateScoresApi({
  id,
  gross_score = 0,
  total_yards = 0,
  feets = 0,
  inches = 0,
}: any) {
  const accessToken = localStorage.getItem('accessToken');

  const data: any = await smashersAPI(accessToken).put(
    `api/v1/admin/scores/${id}`,
    {
      gross_score,
      total_yards,
      feets,
      inches,
    }
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function reApproveScoreApi(id: any) {
  const accessToken = localStorage.getItem('accessToken');
  const data: any = await smashersAPI(accessToken).post(
    'api/v1/admin/scores/reapprove',
    {
      id,
    }
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function approveScoreApi({ id, approval_message }: any) {
  const accessToken = localStorage.getItem('accessToken');
  const data: any = await smashersAPI(accessToken).post(
    'api/v1/admin/scores/approve',
    {
      id,
      approval_message,
    }
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function eventsSearchApi(term?: string) {
  const accessToken = localStorage.getItem('accessToken');
  const data: any = await smashersAPI(accessToken).get(
    `/api/v1/admin/events/search?term=${term}`
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}

export async function leaguesSearchApi(term?: string) {
  const accessToken = localStorage.getItem('accessToken');
  const data: any = await smashersAPI(accessToken).get(
    `/api/v1/admin/leagues/search?term=${term}`
  );
  if (data.error) {
    return {
      data: data,
      error: data.error,
    };
  }
  return {
    data: data,
  };
}
